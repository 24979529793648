import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
   
@Pipe({
 name: 'customTime'
})
export class CustomTimePipe extends 
			DatePipe implements PipeTransform {
 transform(value: any, args?: any): any {
	 if(value != 'We haven’t picked a date yet.'){
		return super.transform(value, "MMMM dd, yyyy"); 
	 }else{
		 return value;
	 }
   
 }
}
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { catchError,map } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as globalConstant from './../global_constant';

@Injectable({
  providedIn: 'root'
})
export class Experience {
	apiDomain								=	globalConstant.DOMAIN;
	getLocationApi							=	globalConstant.GET_LOCATION_API;
	getExperienceContentApi					=	globalConstant.GET_EXPERIENCE_CONTENT_API;
	getLocationContentApi					=	globalConstant.GET_LOCATION_CONTENT_API;
	getLocationRegionApi					=	globalConstant.GET_LOCATION_REGION_API;
	getTopTenCategoryApi					=	globalConstant.GET_TOP_TEN_CATEGORY_API;
	getExperienceByLocationApi				=	globalConstant.GET_EXPERIENCE_BY_LOCATION_API;
	getExperienceByLocationAndCategoryApi	=	globalConstant.GET_EXPERIENCE_BY_LOCATION_AND_CATEGORY_API;
	siteRequestFrom							=	globalConstant.SITE_REQUEST_FROM;
	getExperienceByCategoryApi				=	globalConstant.GET_EXPERIENCE_BY_CATEGORY_API;
	getAutocompleteExpByCategoryApi			=	globalConstant.SEARCH_AUTOCOMPLETE_FOR_EXP_BY_CATEGORY;
	uploadExperienceVideoApi				=	globalConstant.UPLOAD_EXPERIENCE_VIDEO;
	removeExperienceVideoApi				=	globalConstant.REMOVE_EXPERIENCE_VIDEO;
	getCategoryDetailApi        			=	globalConstant.GET_CATEGORY_DETAILS_API;
	getExperienceBySlugApi        			=	globalConstant.GET_EXPERIENCE_BY_SLUG_API;
	getExperienceListDropdownApi        	=	globalConstant.EXPERIENCE_LIST_DROPDOWN_API;
	updateExperienceFieldApi        		=	globalConstant.UPDATE_EXPERIENCE_FIELD_API;
	experienceHomepageByLocationApi    		=	globalConstant.EXPERIENCE_HOMEPAGE_BY_LOCATION_API;
	getExperienceByCategoryPaginationApi	=	globalConstant.GET_EXPERIENCE_BY_CATEGORY_PAGINATION_API;
	searchExperienceAutocompleteApi			=	globalConstant.SEARCH_EXPERIENCE_AUTOCOMPLETE_API;
	getLocationContinentApi							=	globalConstant.GET_LOCATION_CONTINENT_API;
	getExperienceWithMapApi						 = globalConstant.GET_EXPERIENCE_WITH_MAP_API;
	getHomeCircleSectionApi						 = globalConstant.GET_HOME_CIRCLE_SECTION_API;
	searchLocationAutocompleteApi			=	globalConstant.SEARCH_LOCATION_AUTOCOMPLETE_API;
	getHomeExperienceApi                    =  globalConstant.HOME_EXPERIENCE_API;
	getExperienceByHomeLocation				=	globalConstant.GET_SEARCH_AUTOCOMPLETE_FOR_HOME_LOCATION;
	
	constructor(public router: Router,private http: HttpClient) {

	}
	
	eastern_us(){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom
			})
		};	
		return this.http.post<any>(this.apiDomain+this.getLocationApi, {location_for:'experience',type:'location-list'},httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			return err.error;
		}));
	}
	western_us(){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom
			})
		};	
		return this.http.post<any>(this.apiDomain+this.getLocationApi, {location_for:'experience',type:'location-list'},httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			return err.error;
		}));
	}
	
	experiencePageContent(slug_data){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom
			})
		};	
		return this.http.post<any>(this.apiDomain+this.getExperienceContentApi, {slug:slug_data},httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			return err.error;
		}));
	}
	PageContent(slug){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom
			})
		};	
		return this.http.post<any>(this.apiDomain+this.getExperienceContentApi, {slug:slug},httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			return err.error;
		}));
	}
	
	getLocationPageContent(slugName){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom
			})
		};	
		return this.http.post<any>(this.apiDomain+this.getLocationContentApi, {slug:slugName},httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			return err.error;
		}));
	}
	getTopTenCategories(id){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom
			})
		};	
		return this.http.post<any>(this.apiDomain+this.getTopTenCategoryApi, {category_for:'experience',type: 'top-ten-category',location_id:id},httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			return err.error;
		}));
	}
	getExperienceByLocation(slugName,page,searchKeyword,headers){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom,
				'Authorization':headers == "" ?"":'Bearer '+headers
			})
		};	
		return this.http.post<any>(this.apiDomain+this.getExperienceByLocationApi, {location_slug:slugName,page:page,search:searchKeyword},httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			return err.error;
		}));
	}
	getExperienceByLocation1(data,headers){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom,
				'Authorization':headers == "" ?"":'Bearer '+headers
			})
		};	
		return this.http.post<any>(this.apiDomain+this.getExperienceByLocationApi,data,httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			return err.error;
		}));
	}
	getExperienceByLocationAndCategory(location_slug,sub_category_slug){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom
			})
		};
		return this.http.post<any>(this.apiDomain+this.getExperienceByLocationAndCategoryApi, {location_slug:location_slug,sub_category_slug:sub_category_slug},httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			return err.error;
		}));
	}
	getLocationRegion(location_slug,sub_category_slug){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom
			})
		};	
		return this.http.post<any>(this.apiDomain+this.getLocationRegionApi, {location_slug:location_slug,sub_category_slug:sub_category_slug},httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			return err.error;
		}));
	} 
	getExperienceByCategory(data,headers){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom,
				'Authorization':'Bearer '+headers
			})
		};	
		return this.http.post<any>(this.apiDomain+this.getExperienceByCategoryApi,data,httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			return err.error;
		}));
	}
	getExperienceByCategoryPagination(data,headers){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom,
				'Authorization':'Bearer '+headers
			})
		};	
		return this.http.post<any>(this.apiDomain+this.getExperienceByCategoryPaginationApi,data,httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			return err.error;
		}));
	}
	getAutocompleteExperienceByCategory(data,headers){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom,
				'Authorization':'Bearer '+headers
			})
		};	
		return this.http.post<any>(this.apiDomain+this.getAutocompleteExpByCategoryApi,data,httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			return err.error;
		}));
	}

	getAutocompleteExperienceByHomeLocation(data,headers){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom,
				'Authorization':'Bearer '+headers
			})
		};	
		return this.http.post<any>(this.apiDomain+this.getExperienceByHomeLocation,data,httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			return err.error;
		}));
	}
	
	experienceVideoUpload(formData,headers){
		const httpOptions = {
			headers: new HttpHeaders({
			  //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
			  //'Content-Type': 'application/json',
			  'Authorization':'Bearer '+headers,
			  'requestFrom':this.siteRequestFrom,
			})
		  };
		  return this.http.post<any>(this.apiDomain+this.uploadExperienceVideoApi,formData,httpOptions).pipe(map(res=>{
			return res;
		  }),
		  catchError(err => {
			if(err.status == 401)
			{
			  localStorage.removeItem('authUser')
			  localStorage.removeItem('login_token')
			  localStorage.removeItem('profile_img_url')
			  window.location.href = '/login'
			}
			return err.error;
		  }));
	}
	
	experienceYoutubeUpload(formData,headers){ 
		const httpOptions = {
			headers: new HttpHeaders({
			  //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
			  //'Content-Type': 'application/json',
			  'Authorization':'Bearer '+headers,
			  'requestFrom':this.siteRequestFrom,
			})
		  };
		  return this.http.post<any>(this.apiDomain+this.uploadExperienceVideoApi,formData,httpOptions).pipe(map(res=>{
			return res;
		  }),
		  catchError(err => {
			if(err.status == 401)
			{
			  localStorage.removeItem('authUser')
			  localStorage.removeItem('login_token')
			  localStorage.removeItem('profile_img_url')
			  window.location.href = '/login'
			}
			return err.error;
		  }));
	} 
	
	removeExperienceVideo(formData,headers){ 
		const httpOptions = {
			headers: new HttpHeaders({
			  //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
			  //'Content-Type': 'application/json',
			  'Authorization':'Bearer '+headers,
			  'requestFrom':this.siteRequestFrom,
			})
		  };
		  return this.http.post<any>(this.apiDomain+this.removeExperienceVideoApi,formData,httpOptions).pipe(map(res=>{
			return res;
		  }),
		  catchError(err => {
			if(err.status == 401)
			{
			  localStorage.removeItem('authUser')
			  localStorage.removeItem('login_token')
			  localStorage.removeItem('profile_img_url')
			  window.location.href = '/login'
			}
			return err.error;
		  }));
	}	  
	getcategoryDetail(data){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom,
			})
		};	
		return this.http.post<any>(this.apiDomain+this.getCategoryDetailApi,data,httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			return err.error;
		}));
	}
	get_exp_detail(formData,headers){
		const httpOptions = {
			headers: new HttpHeaders({
			  //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
			  'Content-Type': 'application/json',
			  'Authorization':'Bearer '+headers,
			  'requestFrom':this.siteRequestFrom,
			})
		};
		return this.http.post<any>(this.apiDomain+this.getExperienceBySlugApi,formData,httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			if(err.status == 401)
			{
			  localStorage.removeItem('authUser')
			  localStorage.removeItem('login_token')
			  localStorage.removeItem('profile_img_url')
			  window.location.href = '/login'
			}
			return err.error;
	    }));
	}

	get_exp_with_map_detail(formData,headers){
		const httpOptions = {
			headers: new HttpHeaders({
			  //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
			  'Content-Type': 'application/json',
			  'Authorization':'Bearer '+headers,
			  'requestFrom':this.siteRequestFrom,
			})
		};
		return this.http.post<any>(this.apiDomain+this.getExperienceWithMapApi,formData,httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			if(err.status == 401)
			{
			  localStorage.removeItem('authUser')
			  localStorage.removeItem('login_token')
			  localStorage.removeItem('profile_img_url')
			  window.location.href = '/login'
			}
			return err.error;
	    }));
	}
	
	getExperienceDropdown(headers){
        const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            'Content-Type': 'application/json',
            'requestFrom':this.siteRequestFrom,
            'Authorization':'Bearer '+headers,
          })
        };
        return this.http.get<any>(this.apiDomain+this.getExperienceListDropdownApi,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          return err.error;
        }));
    }
	
	updateExperienceField(formData,headers){ 
		const httpOptions = {
			headers: new HttpHeaders({
			  //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
			  'Content-Type': 'application/json',
			  'Authorization':'Bearer '+headers,
			  'requestFrom':this.siteRequestFrom,
			})
		};
		return this.http.post<any>(this.apiDomain+this.updateExperienceFieldApi,formData,httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			if(err.status == 401)
			{
			  localStorage.removeItem('authUser')
			  localStorage.removeItem('login_token')
			  localStorage.removeItem('profile_img_url')
			  window.location.href = '/login'
			}
			return err.error;
	    }));
	}
	
	homepage_exp_by_location(formData,headers){ 
		const httpOptions = {
			headers: new HttpHeaders({
			  //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
			  'Content-Type': 'application/json',
			  'Authorization':headers == "" ?"":'Bearer '+headers,
			  'requestFrom':this.siteRequestFrom,
			})
		};
		return this.http.post<any>(this.apiDomain+this.experienceHomepageByLocationApi,formData,httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			if(err.status == 401)
			{
			  localStorage.removeItem('authUser')
			  localStorage.removeItem('login_token')
			  localStorage.removeItem('profile_img_url')
			  window.location.href = '/login'
			}
			return err.error;
	    }));
	}
	searchExperienceAutocomplete(formData){ 
		const httpOptions = {
			headers: new HttpHeaders({
			  //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
			  'Content-Type': 'application/json',
			  //'Authorization':headers == "" ?"":'Bearer '+headers,
			  'requestFrom':this.siteRequestFrom,
			})
		};
		return this.http.post<any>(this.apiDomain+this.searchExperienceAutocompleteApi,formData,httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			if(err.status == 401)
			{
			  localStorage.removeItem('authUser')
			  localStorage.removeItem('login_token')
			  localStorage.removeItem('profile_img_url')
			  window.location.href = '/login'
			}
			return err.error;
	    }));
	}

	locations(formData){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom
			})
		};	
		return this.http.post<any>(this.apiDomain+this.getLocationContinentApi,formData,httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			return err.error;
		}));
	}


	get_circle_section(){

	/*const httpOptions = {
      headers: new HttpHeaders({
        'requestFrom':this.siteRequestFrom,
        'device' :  this.deviceType
      })
    };*/
    return this.http.get<any>(this.apiDomain+this.getHomeCircleSectionApi).pipe(map(res=>{
      return res;
    }),
    catchError(err => {
      return err.error;
    }));
  }

  searchLocationAutocomplete(formData){ 
		const httpOptions = {
			headers: new HttpHeaders({
			  'Content-Type': 'application/json',
			  'requestFrom':this.siteRequestFrom,
			})
		};
		return this.http.post<any>(this.apiDomain+this.searchLocationAutocompleteApi,formData,httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			if(err.status == 401)
			{
			  localStorage.removeItem('authUser')
			  localStorage.removeItem('login_token')
			  localStorage.removeItem('profile_img_url')
			  window.location.href = '/login'
			}
			return err.error;
	    }));
	}

	getHomeExperiencePagination(formData,headers){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom,
				'Authorization':headers == "" ?"":'Bearer '+headers
			})
		};
		return this.http.post<any>(this.apiDomain+this.getHomeExperienceApi,formData,httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			return err.error;
		}));
	}


}





import { Pipe, PipeTransform } from '@angular/core'; 

@Pipe({
    name:'appendDollar'
})
export class UrlDecode implements PipeTransform {
	transform(value:string): string{

		if(value != null && value != "" && value.length > 0){
			// console.log(value);
			value 	= 	value.replace(/(\r\n|\n|\r)/gm, '');
			//value	=	decodeURIComponent(value.toString());
			value 	=	this.decodeURIComponentSafe(value); 
			value 	= 	value.replace('\r', '');
			value 	= 	value.replace('\n', '');
			value 	= 	value.replace(/\\/g, '');
			return value;
		}else{
			return value; 
		}



		/* if(value != null && value != "" && value.length > 0){
			// console.log(value);
			value 	= 	value.replace(/(\r\n|\n|\r)/gm, '');
			//value	=	decodeURIComponent(value.toString());
			value 	= 	value.replace(/\\/g, '');
			//value 	= 	value.replace(/./g, '');
			value 	=	this.decodeURIComponentSafe(value); 
			return value;
		}else{
			//return value;
		} */
    } 

	rawurldecode (str) {
		return decodeURIComponent((str + '')
		  .replace(/%(?![\da-f]{2})/gi, function () {
			// PHP tolerates poorly formed escape sequences
			return '%25'
		  }))
	  }

	decodeURIComponentSafe(s:any) {
		if (!s) {
			return s;
		}
		try {
			return decodeURIComponent(s);
		}catch(err){
			return decodeURIComponent(s.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25'));
		}
		
	}
}
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { catchError,map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import * as globalConstant from './../global_constant';

@Injectable({
  providedIn: 'root'
})
export class ExperienceShare
 {
  apiDomain	   	    		=	globalConstant.DOMAIN;
  siteRequestFrom			=	globalConstant.SITE_REQUEST_FROM;
  partialOrderShareApi		=	globalConstant.PARTIAL_ORDER_SHARE;
  partialOrderShareEmailApi	=	globalConstant.PARTIAL_ORDER_SHARE_EMAIL;
  expPhotoVideoShareApi		=	globalConstant.EXP_SHARE_PHOTO_VIDEO_API;
  expSendThankYouEmailApi	=	globalConstant.EXP_SEND_THANK_YOU_EMAIL_API;
  uploadThankYouImageApi	=	globalConstant.UPLOAD_THANK_YOU_IMAGE_API;
  thankYouEmailImagesApi	=	globalConstant.THANK_YOU_EMAIL_IMAGES_API;

  constructor(public router: Router,private http: HttpClient) {  

  } 

  partial_purchase_data(formData,headers){
    const httpOptions = {
       headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+headers,
        'requestFrom':this.siteRequestFrom,
      })
    };
    return this.http.post<any>(this.apiDomain+this.partialOrderShareApi,formData,httpOptions).pipe(map(res=>{
      return res;
    }),
    catchError(err => {
      if(err.status == 401)
      {
        localStorage.removeItem('authUser')
        localStorage.removeItem('login_token')
        localStorage.removeItem('profile_img_url')
        window.location.href = '/login'
      }
      return err.error;
    }));
  } 
  partial_purchase_share_email(formData,headers){
    const httpOptions = {
       headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+headers,
        'requestFrom':this.siteRequestFrom,
      })
    };
    return this.http.post<any>(this.apiDomain+this.partialOrderShareEmailApi,formData,httpOptions).pipe(map(res=>{
      return res;
    }),
    catchError(err => {
      if(err.status == 401)
      {
        localStorage.removeItem('authUser')
        localStorage.removeItem('login_token')
        localStorage.removeItem('profile_img_url')
        window.location.href = '/login'
      }
      return err.error;
    }));
  }
  
	photoVideoShareSave(formData,headers){
		const httpOptions = {
		   headers: new HttpHeaders({
			//'Content-Type': 'application/json',
			'Authorization':'Bearer '+headers,
			'requestFrom':this.siteRequestFrom,
		  })
		};
		return this.http.post<any>(this.apiDomain+this.expPhotoVideoShareApi,formData,httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
		if(err.status == 401)
		{
			localStorage.removeItem('authUser')
			localStorage.removeItem('login_token')
			localStorage.removeItem('profile_img_url')
			window.location.href = '/login'
		}
		return err.error;
    }));
  }
  
  
  uploadThankYouImage(formData,headers){
		const httpOptions = {
		   headers: new HttpHeaders({
			//'Content-Type': 'application/json',
			'Authorization':'Bearer '+headers,
			'requestFrom':this.siteRequestFrom,
		  })
		};
		return this.http.post<any>(this.apiDomain+this.uploadThankYouImageApi,formData,httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
		if(err.status == 401)
		{
			localStorage.removeItem('authUser')
			localStorage.removeItem('login_token')
			localStorage.removeItem('profile_img_url')
			window.location.href = '/login'
		}
		return err.error;
    }));
  }
  
  sendThankYouEmail(formData,headers){
    const httpOptions = {
       headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+headers,
        'requestFrom':this.siteRequestFrom,
      })
    };
    return this.http.post<any>(this.apiDomain+this.expSendThankYouEmailApi,formData,httpOptions).pipe(map(res=>{
      return res;
    }),
    catchError(err => {
      if(err.status == 401)
      {
        localStorage.removeItem('authUser')
        localStorage.removeItem('login_token')
        localStorage.removeItem('profile_img_url')
        window.location.href = '/login'
      }
      return err.error;
    }));
  }
  
  sendThankYouEmailImages(formData,headers){
    const httpOptions = {
       headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+headers,
        'requestFrom':this.siteRequestFrom,
      })
    };
    return this.http.post<any>(this.apiDomain+this.thankYouEmailImagesApi,formData,httpOptions).pipe(map(res=>{
      return res;
    }),
    catchError(err => {
      if(err.status == 401)
      {
        localStorage.removeItem('authUser')
        localStorage.removeItem('login_token')
        localStorage.removeItem('profile_img_url')
        window.location.href = '/login'
      }
      return err.error;
    }));
  }
    
}





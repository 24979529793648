import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { catchError,map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import * as globalConstant from './../global_constant';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
	
  apiDomain	   	    			=	globalConstant.DOMAIN;
  updateUserFieldApi	    	=	globalConstant.UPDATE_USER_FIELD_API;
  profileSettingApi				=	globalConstant.PROFILE_SETTING_API;
  siteRequestFrom				=	globalConstant.SITE_REQUEST_FROM;
  getExperiencesApi				=	globalConstant.GET_EXPERIENCES_API;
  getExperiencesDetailApi		=	globalConstant.GET_EXPERIENCES_DETAIL_API;

  updateCharityFieldFromApi		=	globalConstant.UPDATE_CHARITY_FIELD_API;
  galleryApi					=	globalConstant.GALLERY_API;
  removeGalleryApi				=	globalConstant.REMOVEGALLERY_API;
  charityCategoryApi			=	globalConstant.CATEGORIES_API;
  updateGalleryFeaturedApi		=	globalConstant.UPDATE_GALLERY_FEATURED_API;
  updateCharityCategoryApi		=	globalConstant.UPDATE_CHARITY_CATEGORY_API;
  getCouplesApi					=	globalConstant.GET_COUPLES_API;
  uploadCharityVideoApi			=	globalConstant.UPLOAD_CHARITY_VIDEO;
  voucherRedeemDataApi			=	globalConstant.VOUCHER_REDEEM_DATA_API;
  authUserDataApi				=	globalConstant.GET_AUTH_USER_DATA;
  updateHoneymoonSettingApi		=	globalConstant.UPDATE_HONEYMOON_SETTING_API;

  
  constructor(public router: Router,private http: HttpClient) {  

  } 


 	
    // get_user_field(formData){

    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
    //     //'Content-Type': 'application/x-www-form-urlencoded',
    //     'requestFrom':this.siteRequestFrom
    //   })
    //   };
    //     return this.http.post<any>(this.apiDomain+this.updateUserFieldApi,formData,httpOptions).pipe(map(res=>{
    //     return res;
    // }),
    //   catchError(err => {
    //   return err.error;
    //   }));
    // }
		
    
		
    get_profile_setting(headers){
      const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            //'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization':'Bearer '+headers,
            'requestFrom':this.siteRequestFrom,
          })
        };
        return this.http.post<any>(this.apiDomain+this.profileSettingApi,'',httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          if(err.status == 401)
          {
            localStorage.removeItem('authUser')
            localStorage.removeItem('login_token')
            localStorage.removeItem('profile_img_url')
            window.location.href = '/login'
          }
          return err.error;
        }));
      }

    get_experiences(headers, data={}){
      const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            //'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization':'Bearer '+headers,
          })
        };
        return this.http.post<any>(this.apiDomain+this.getExperiencesApi, data, httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          if(err.status == 401)
          {
            /* localStorage.removeItem('authUser')
            localStorage.removeItem('login_token')
            localStorage.removeItem('profile_img_url')
            window.location.href = '/login' */
          }
          return err.error;
        }));
    }

    get_experiences_detail(headers,id){
      const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+headers,
          })
        };
        return this.http.post<any>(this.apiDomain+this.getExperiencesDetailApi,{'experience_id' :id},httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          if(err.status == 401)
          {
            localStorage.removeItem('authUser')
            localStorage.removeItem('login_token')
            localStorage.removeItem('profile_img_url')
            window.location.href = '/login'
          }
          return err.error;
        }));
    }




    update_charity_field(formData,headers){
      const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+headers,
			'requestFrom':this.siteRequestFrom
          })
        };
        return this.http.post<any>(this.apiDomain+this.updateCharityFieldFromApi,formData,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
         if(err.status == 401)
          {
            localStorage.removeItem('authUser')
            localStorage.removeItem('login_token')
            localStorage.removeItem('profile_img_url')
            window.location.href = '/login'
          } 
          return err.error;
        }));
      }
	  charity_gallery(formData,headers){
      const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            //'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization':'Bearer '+headers,
			'requestFrom':this.siteRequestFrom
          })
        };
        return this.http.post<any>(this.apiDomain+this.galleryApi,formData,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
         if(err.status == 401)
          {
            localStorage.removeItem('authUser')
            localStorage.removeItem('login_token')
            localStorage.removeItem('profile_img_url')
            window.location.href = '/login'
          } 
          return err.error;
        }));
      }
	
	  charity_remove_gallery(formData,headers){
      const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+headers,
			'requestFrom':this.siteRequestFrom
          })
        };
        return this.http.post<any>(this.apiDomain+this.removeGalleryApi,formData,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
         if(err.status == 401)
          {
            localStorage.removeItem('authUser')
            localStorage.removeItem('login_token')
            localStorage.removeItem('profile_img_url')
            window.location.href = '/login'
          } 
          return err.error;
        }));
      }
	  charity_category(formData,headers){
      const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+headers,
			'requestFrom':this.siteRequestFrom
          })
        };
        return this.http.post<any>(this.apiDomain+this.charityCategoryApi,formData,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
         if(err.status == 401)
          {
            localStorage.removeItem('authUser')
            localStorage.removeItem('login_token')
            localStorage.removeItem('profile_img_url')
            window.location.href = '/login'
          } 
          return err.error;
        }));
      }
	  charity_gallery_featured(formData,headers){
      const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+headers,
			'requestFrom':this.siteRequestFrom
          })
        };
        return this.http.post<any>(this.apiDomain+this.updateGalleryFeaturedApi,formData,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
         if(err.status == 401)
          {
            localStorage.removeItem('authUser')
            localStorage.removeItem('login_token')
            localStorage.removeItem('profile_img_url')
            window.location.href = '/login'
          } 
          return err.error;
        }));
      }
	  update_charity_category(formData,headers){
      const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+headers,
			'requestFrom':this.siteRequestFrom
          })
        };
        return this.http.post<any>(this.apiDomain+this.updateCharityCategoryApi,formData,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
         if(err.status == 401)
          {
            localStorage.removeItem('authUser')
            localStorage.removeItem('login_token')
            localStorage.removeItem('profile_img_url')
            window.location.href = '/login'
          } 
          return err.error;
        }));
      }
	  
	get_couples(formData,headers){
    const httpOptions = {
        headers: new HttpHeaders({
          //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
          'Content-Type': 'application/json',
          'Authorization':'Bearer '+headers,
          'requestFrom':this.siteRequestFrom,
        })
      };
      return this.http.post<any>(this.apiDomain+this.getCouplesApi,formData,httpOptions).pipe(map(res=>{
        return res;
      }),
      catchError(err => {
        if(err.status == 401)
        {
          localStorage.removeItem('authUser')
          localStorage.removeItem('login_token')
          localStorage.removeItem('profile_img_url')
          window.location.href = '/login'
        }
        return err.error;
      }));
	}
	charity_video_upload(formData,headers){
    const httpOptions = {
        headers: new HttpHeaders({
          //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
          //'Content-Type': 'application/json',
          'Authorization':'Bearer '+headers,
          'requestFrom':this.siteRequestFrom,
        })
      };
      return this.http.post<any>(this.apiDomain+this.uploadCharityVideoApi,formData,httpOptions).pipe(map(res=>{
        return res;
      }),
      catchError(err => {
        if(err.status == 401)
        {
          localStorage.removeItem('authUser')
          localStorage.removeItem('login_token')
          localStorage.removeItem('profile_img_url')
          window.location.href = '/login'
        }
        return err.error;
      }));
	}
	charity_youtube_upload(formData,headers){ 
    const httpOptions = {
        headers: new HttpHeaders({
          //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
          //'Content-Type': 'application/json',
          'Authorization':'Bearer '+headers,
          'requestFrom':this.siteRequestFrom,
        })
      };
      return this.http.post<any>(this.apiDomain+this.uploadCharityVideoApi,formData,httpOptions).pipe(map(res=>{
        return res;
      }),
      catchError(err => {
        if(err.status == 401)
        {
          localStorage.removeItem('authUser')
          localStorage.removeItem('login_token')
          localStorage.removeItem('profile_img_url')
          window.location.href = '/login'
        }
        return err.error;
      }));
	}
	voucher_redeem_data(formData,headers){
    const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization':'Bearer '+headers,
          'requestFrom':this.siteRequestFrom,
        })
      };
      return this.http.post<any>(this.apiDomain+this.voucherRedeemDataApi,formData,httpOptions).pipe(map(res=>{
        return res;
      }),
      catchError(err => {
        if(err.status == 401)
        {
          localStorage.removeItem('authUser')
          localStorage.removeItem('login_token')
          localStorage.removeItem('profile_img_url')
          window.location.href = '/login'
        }
        return err.error;
      }));
	}
	
	get_auth_user_data(formData,authKey){
		const httpOptions = {
			headers: new HttpHeaders({
			  'Content-Type': 'application/json',
			  'Authorization':'Bearer '+authKey,
			  'requestFrom':this.siteRequestFrom,
			})
		  };
      return this.http.post<any>(this.apiDomain+this.authUserDataApi,formData,httpOptions).pipe(map(res=>{
        return res;
      }),
      catchError(err => {
        if(err.status == 401)
        {
          localStorage.removeItem('authUser')
          localStorage.removeItem('login_token')
          localStorage.removeItem('profile_img_url')
          window.location.href = '/login'
        }
        return err.error;
      }));
	}
	
	updateHoneymoonSetting(formData,authKey){
		const httpOptions = {
			headers: new HttpHeaders({
			  'Content-Type': 'application/json',
			  'Authorization':'Bearer '+authKey,
			  'requestFrom':this.siteRequestFrom,
			})
		  };
      return this.http.post<any>(this.apiDomain+this.updateHoneymoonSettingApi,formData,httpOptions).pipe(map(res=>{
        return res;
      }),
      catchError(err => {
        if(err.status == 401)
        {
          localStorage.removeItem('authUser')
          localStorage.removeItem('login_token')
          localStorage.removeItem('profile_img_url')
          window.location.href = '/login'
        }
        return err.error;
      }));
	}
    
}





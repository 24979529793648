import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { catchError,map } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as globalConstant from './../global_constant';

@Injectable({
  providedIn: 'root'
})
export class AddExperience {
	apiDomain								=	globalConstant.DOMAIN;
	getAddExperienceApi						=	globalConstant.GET_ADD_EXPERIENCE_API;
	getAddExperienceCategoryApi				=	globalConstant.GET_ADD_EXPERIENCE_CATEGORY_API;
	getAddExperienceCountryApi				=	globalConstant.GET_ADD_EXPERIENCE_COUNTRY_API;
	getAddExperienceStateApi				=	globalConstant.GET_ADD_EXPERIENCE_STATE_API;
	getAddExperienceCityApi					=	globalConstant.GET_ADD_EXPERIENCE_CITY_API;
	siteRequestFrom							=	globalConstant.SITE_REQUEST_FROM;
	addExperienceRegistryApi				=	globalConstant.ADD_EXPERIENCE_REGISTERY_API;
	getExperienceDetailsApi					=	globalConstant.GET_EXPERIENCE_DETAILS_DATA_API;
	exchangeExpApi							=	globalConstant.EXCHANGE_EXPERIENCE_API;
	redeemExpApi							=	globalConstant.REDEEM_EXPERIENCE_API;
	getExperienceByCountryApi				=	globalConstant.GET_EXPERIENCE_BY_COUNTRY_API;
	updateExperienceCoverImageApi			=	globalConstant.UPDATE_EXPERIENCE_COVER_IMAGE_API;
	getSearchAutoCompleteForLocationApi	    =	globalConstant.GET_SEARCH_AUTOCOMPLETE_FOR_LOCATION;
	getSearchAutoCompleteForAddExperienceApi=	globalConstant.GET_SEARCH_AUTOCOMPLETE_FOR_ADD_EXPERIENCES;
	updateExperienceExclusiveApi			=	globalConstant.UPDATE_EXPERIENCE_EXCLUSIVE;
	getExperienceSuggestionApi				=	globalConstant.GET_EXPERIENCE_SUGGESTION;
	getAddLocationMenuApi				=	globalConstant.GET_ADD_LOCATION_MENU_API;
	
	
	constructor(public router: Router,private http: HttpClient) {

	}
	
	getAddExperienceData(location_travel,location,category_id,price_option,page,headers){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom,
				'Authorization':'Bearer '+headers,
			})
		};
		return this.http.post<any>(this.apiDomain+this.getAddExperienceApi, {location_travel:location_travel,location:location,category_id:category_id,price_option:price_option,page:page},httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			if(err.status == 401)
			{
			  localStorage.removeItem('authUser')
			  localStorage.removeItem('login_token')
			  localStorage.removeItem('profile_img_url')
			  window.location.href = '/login'
			}
			return err.error;
		}));
	}

	getAddExperienceData1(data,headers){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom,
				'Authorization':'Bearer '+headers,
			})
		};
		return this.http.post<any>(this.apiDomain+this.getAddExperienceApi,data,httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			if(err.status == 401)
			{
			  localStorage.removeItem('authUser')
			  localStorage.removeItem('login_token')
			  localStorage.removeItem('profile_img_url')
			  window.location.href = '/login'
			}
			return err.error;
		}));
	}
	getCategoryList(){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom
			})
		};
		return this.http.post<any>(this.apiDomain+this.getAddExperienceCategoryApi, {category_for:'experience',type:'second-level-category'},httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			return err.error;
		}));
	}
	getCountryList(){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom
			})
		};
		return this.http.post<any>(this.apiDomain+this.getAddExperienceCountryApi, {},httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			return err.error;
		}));
	}
	getStateList(country_id){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom
			})
		};
		return this.http.post<any>(this.apiDomain+this.getAddExperienceStateApi, {country_id:country_id},httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			return err.error;
		}));
	}
	getCityList(state_id,headers){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom,
				'Authorization':'Bearer '+headers,
			})
		};
		return this.http.post<any>(this.apiDomain+this.getAddExperienceCityApi, {state_id:state_id},httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			if(err.status == 401)
			{
			  localStorage.removeItem('authUser')
			  localStorage.removeItem('login_token')
			  localStorage.removeItem('profile_img_url')
			  window.location.href = '/login'
			}
			return err.error;
		}));
	}

	addExperienceRegistry(exp_id,type,headers){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom,
				'Authorization':'Bearer '+headers
			})
		};
		return this.http.post<any>(this.apiDomain+this.addExperienceRegistryApi, {experience_id:exp_id,type:type},httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			if(err.status == 401)
			{
			  localStorage.removeItem('authUser')
			  localStorage.removeItem('login_token')
			  localStorage.removeItem('profile_img_url')
			  window.location.href = '/login'
			}
			return err.error;
		}));
	}

	getExperienceDetails(requestBody,headers){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom,
				'Authorization':'Bearer '+headers
			})
		};
		return this.http.post<any>(this.apiDomain+this.getExperienceDetailsApi, requestBody,httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			if(err.status == 401)
			{
			  localStorage.removeItem('authUser')
			  localStorage.removeItem('login_token')
			  localStorage.removeItem('profile_img_url')
			  window.location.href = '/login'
			}
			return err.error;
		}));
	}

	exchangeExp(voucher_code,headers){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom,
				'Authorization':'Bearer '+headers
			})
		};
		return this.http.post<any>(this.apiDomain+this.exchangeExpApi, {voucher_code:voucher_code},httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			if(err.status == 401)
			{
			  localStorage.removeItem('authUser')
			  localStorage.removeItem('login_token')
			  localStorage.removeItem('profile_img_url')
			  window.location.href = '/login'
			}
			return err.error;
		}));
	}

	redeemExp(voucher_code,headers){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom,
				'Authorization':'Bearer '+headers
			})
		};
		return this.http.post<any>(this.apiDomain+this.redeemExpApi, {voucher_code:voucher_code},httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			if(err.status == 401)
			{
			  localStorage.removeItem('authUser')
			  localStorage.removeItem('login_token')
			  localStorage.removeItem('profile_img_url')
			  window.location.href = '/login'
			}
			return err.error;
		}));
	}

	experienceByCountry(page,slugName,headers){
		const httpOptions = { 
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom,
				'Authorization':headers == "" ? "":'Bearer '+headers
			})
		};
		return this.http.post<any>(this.apiDomain+this.getExperienceByCountryApi, {page :page,location_slug:slugName},httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			return err.error;
		}));
	}
	experienceByCountry1(data,headers){
		const httpOptions = { 
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom,
				'Authorization':headers == "" ? "":'Bearer '+headers
			})
		};
		return this.http.post<any>(this.apiDomain+this.getExperienceByCountryApi,data,httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			return err.error;
		}));
	}
	

	updateExperienceCoverImage(headers,data){
		const httpOptions = { 
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom,
				'Authorization':'Bearer '+headers
			})
		};
		return this.http.post<any>(this.apiDomain+this.updateExperienceCoverImageApi, data,httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			if(err.status == 401)
			{
			  localStorage.removeItem('authUser')
			  localStorage.removeItem('login_token')
			  localStorage.removeItem('profile_img_url')
			  window.location.href = '/login'
			}
			return err.error;
		}));
	}
	autocomplete_search(data){
		const httpOptions = { 
			headers: new HttpHeaders({
				//'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom,
				//'Authorization':'Bearer '+headers
			})
		};
		return this.http.post<any>(this.apiDomain+this.getSearchAutoCompleteForLocationApi,data,httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			if(err.status == 401)
			{
			  localStorage.removeItem('authUser')
			  localStorage.removeItem('login_token')
			  localStorage.removeItem('profile_img_url')
			  window.location.href = '/login'
			}
			return err.error;
		}));
	}
	autocomplete_experience_search(data,headers){
		const httpOptions = { 
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom,
				'Authorization':'Bearer '+headers
			})
		};
		return this.http.post<any>(this.apiDomain+this.getSearchAutoCompleteForAddExperienceApi,data,httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			if(err.status == 401)
			{
			  localStorage.removeItem('authUser')
			  localStorage.removeItem('login_token')
			  localStorage.removeItem('profile_img_url')
			  window.location.href = '/login'
			}
			return err.error;
		}));
	}
	
	update_experience_exclusive(data,headers){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom,
				'Authorization':'Bearer '+headers
			})
		};	
		return this.http.post<any>(this.apiDomain+this.updateExperienceExclusiveApi,data,httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			return err.error;
		}));
	}
	
	getExperienceSuggestionData(data,headers){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom,
				'Authorization':'Bearer '+headers
			})
		};	
		return this.http.post<any>(this.apiDomain+this.getExperienceSuggestionApi,data,httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			return err.error;
		}));
	}

	getLocationMenuList(){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom
			})
		};
		return this.http.get<any>(this.apiDomain+this.getAddLocationMenuApi,httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			return err.error;
		}));
	}
	
}





import { Component, OnInit , HostListener, PLATFORM_ID, Inject , OnDestroy} from '@angular/core';
import { NavigationStart, NavigationEnd, Router } from '@angular/router';
import { AuthdataMessageService } from '../../../authdata-message.service';
import * as globalConstant from './../../../global_constant';
import { MainService } from '../../../main.service';
import { Subscription } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
declare var $: any;
declare var window: any;

@Component({
  selector: 'app-individual-subheader',
  templateUrl: './individual-subheader.component.html',
  styleUrls: ['./individual-subheader.component.css']
})
export class IndividualSubheaderComponent implements OnInit , OnDestroy {
  expUplodsUrl		:	string 		=	globalConstant.EXPERIENCE_UPLOADS_URL;	
  public currentURL: string = "";
  public currentURL1: string = "";
  authCheck:boolean=false;
  authUser : any = ''
  getAccountStatusSubscription: Subscription;
  is_cart_fill : boolean = false;
  total_sales_tax : any = 0;
  total_cart_amount : any = 0;
  cart_details  :any = []
  show_card_details :any  = false;
  tokenkey           	:   any = '';
  location_id : any = '';
  wallet_balance :number = 0.00;
  total_notification_counter : any = 0;
  total_friend_counter : any = 0;
  isBrowser: boolean;
  constructor( 
    private router: Router, 
  private messageService: AuthdataMessageService,
  private mainservice: MainService,
  @Inject(PLATFORM_ID) platformId: Object
  ) { 
	this.isBrowser = isPlatformBrowser(platformId);
	if(this.isBrowser){
		window.scrollTo(0,0);
	}
	this.currentURL = this.router.url.split("/")[1];
	this.currentURL1 = this.currentURL.split("?")[0];
	if(this.router.url.split("/")[3]!='' && this.router.url.split("/")[3]!=undefined)
	{
		this.location_id = this.router.url.split("/")[3].split("=")[1]
	}
    this.router.events.subscribe(event => {
		
      if (event instanceof NavigationStart) {
        
      }

      if(event instanceof NavigationEnd) {
        this.currentURL = this.router.url.split("/")[1];
		this.currentURL1 = this.currentURL.split("?")[0];
		if(this.router.url.split("/")[3]!='' && this.router.url.split("/")[3]!=undefined)
		{
			this.location_id = this.router.url.split("/")[3].split("=")[1]
		}

        if(localStorage.getItem('cart_details'))
        {
          this.cart_details = JSON.parse(localStorage.getItem('cart_details'));
          if(this.cart_details.length > 0)
          {
            this.is_cart_fill = true;
          }
          else
          {
            this.is_cart_fill = false;
          }
    
          this.total_sales_tax = 0;
          this.total_cart_amount = 0;
          for(let i = 0 ; i<this.cart_details.length ; i++)
          {
            this.total_sales_tax=this.total_sales_tax+this.cart_details[i].tax
            this.total_cart_amount=this.total_cart_amount+this.cart_details[i].line_total
          }
        }
      }

    });

    if (localStorage.getItem('authUser')) {
      this.authCheck = true;
	  this.tokenkey = localStorage.getItem('login_token'); 
    }
    else
    {
      this.authCheck = false;
    }
	
  this.getAccountStatusSubscription = this.messageService.getAccoutStatus().subscribe(auth_data => {	
   	  if(auth_data!== 'false')
	  {
        this.authUser = auth_data;
      }
    });

  }

  ngOnInit() {
    if (localStorage.getItem('authUser')) {
      this.authCheck = true;
      this.authUser 		= 	JSON.parse(localStorage.getItem('authUser'));
    }
    else
    {
      this.authCheck = false;
    }

    if(localStorage.getItem('cart_details'))
    {
      this.cart_details = JSON.parse(localStorage.getItem('cart_details'));
      if(this.cart_details.length > 0)
      {
        this.is_cart_fill = true;
      }
      else
      {
        this.is_cart_fill = false;
      }

      this.total_sales_tax = 0;
      this.total_cart_amount = 0;
      for(let i = 0 ; i<this.cart_details.length ; i++)
      {
          this.total_sales_tax=this.total_sales_tax+this.cart_details[i].tax
          this.total_cart_amount=this.total_cart_amount+this.cart_details[i].line_total
      }
    }

    this.mainservice.getWalletAmount(this.tokenkey).subscribe(res=>{
      if(res.response == 'success'){
        this.wallet_balance = res.data.wallet_amount;
        this.total_notification_counter = res.data.total_notification
        this.total_friend_counter = res.data.friend_notification
      }else if(res.response == 'error'){
        this.wallet_balance = 0.00;
      }
    });
  }

  @HostListener("window:scroll", []) 
  onWindowScroll() {
    // do some stuff here when the window is scrolled
	if(this.isBrowser){
		const verticalOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

		var navbar = document.getElementById("individual-subheader");

		if (verticalOffset < 145 && navbar !== null && navbar !== undefined) {
			navbar.classList.remove('is-sticky');
		}
		if (verticalOffset > 145 && navbar !== null && navbar !== undefined) {
		  if (!navbar.classList.contains('is-sticky')) {
			navbar.classList.add('is-sticky');
		  }
		}
		if (verticalOffset > 145 * 2 && navbar !== null && navbar !== undefined) {
		  if (!navbar.classList.contains('awake')) {
			navbar.classList.add('awake');
		  }
		}
		if (verticalOffset < 145 * 2 && navbar !== null && navbar !== undefined) {
		  if (navbar.classList.contains('awake')) {
			navbar.classList.remove('awake');
		  }
		}
	}
  }

  ngAfterViewInit() {
	if(this.isBrowser){
		var subMenuBtn = document.getElementById("individual-menu-toggle");
		var weddingHead = document.getElementById("individual-subheader");
		var weddingHeadMenu = document.getElementById("individualNav");
		if(subMenuBtn !== null && subMenuBtn !== undefined)
		{
			subMenuBtn.addEventListener("click", (e) => {
			  //weddingHead.classList.toggle("collapsed");
			  subMenuBtn.classList.toggle("clicked");

			  if(subMenuBtn.classList.contains("clicked")) {
				weddingHeadMenu.hidden = false;
			  }
			  else {
				weddingHeadMenu.hidden = true;
			  }

			});
		}
	}
  }


  checkout()
  {
    this.show_card_details =false
    this.router.navigate(['/checkout'])
  }

  getCartDetail()
  {
    this.show_card_details = !this.show_card_details
    if(this.show_card_details == true)
    {
      this.cart_details = []
      if(localStorage.getItem('cart_details'))
      {
        this.cart_details = JSON.parse(localStorage.getItem('cart_details'));
        if(this.cart_details.length > 0)
        {
          this.is_cart_fill = true;
        }
        else
        {
          this.is_cart_fill = false;
        }
        this.total_sales_tax = 0;
        this.total_cart_amount  = 0;
        for(let i = 0 ; i<this.cart_details.length ; i++)
        {
          this.total_sales_tax=this.total_sales_tax+this.cart_details[i].tax
          this.total_cart_amount=this.total_cart_amount+this.cart_details[i].line_total
        }
      }
    }
  }


  removeItem(product_id)
  {
    for(let i=0;i<this.cart_details.length ; i++)
    {
      if(product_id == this.cart_details[i].product_id)
      {
        this.cart_details.splice(i, 1);
        break;
      }
      
    }

    
    this.total_sales_tax = 0;
    this.total_cart_amount  = 0;
    for(let i = 0 ; i<this.cart_details.length ; i++)
    {
      this.total_sales_tax=this.total_sales_tax+this.cart_details[i].tax
      this.total_cart_amount=this.total_cart_amount+this.cart_details[i].line_total
    }
    if(this.cart_details.length > 0)
    {
      localStorage.setItem('cart_details',JSON.stringify(this.cart_details));
    }
    else
    {
      localStorage.removeItem('cart_details')
    }
  }
  ngOnDestroy() {
	
	if(this.getAccountStatusSubscription!=undefined && this.getAccountStatusSubscription!=null)
	{
		this.getAccountStatusSubscription.unsubscribe();
	}
	
  }

}

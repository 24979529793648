import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationStart, NavigationEnd } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class GuestGuard implements CanActivate {

	currentUrl: string = '';
	isBrowser: boolean;
	constructor(
		private router: Router,
		@Inject(PLATFORM_ID) platformId: Object
	) {
		this.isBrowser = isPlatformBrowser(platformId);
		
		this.router.events.subscribe(event => {
			if(event instanceof NavigationEnd) {
				
				this.currentUrl	=	this.router.url.split("/")[1];
				
			}
		});
	}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		
		if(this.isBrowser) {
			//alert(localStorage.getItem('authUser'));
			if (localStorage.getItem('authUser')) {
				// logged in so return true
				this.router.navigate(['/']);
			}else{
				return true;
			}
		}
		return true;
    }
}

import { Pipe, PipeTransform } from '@angular/core'; 

@Pipe({
    name:'htmlConverter'
})
export class HtmlConverter implements PipeTransform {
	transform(str:string): string{
    const element = document.createElement('div');
    if(str && typeof str === 'string') {
      // strip script/html tags
      str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
      str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
	    str = str.replace(/\\/g, '');
      element.innerHTML = str;
      str = element.textContent;
      element.textContent = '';
    }
    return str;
  }
}
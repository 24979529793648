import { Component, OnInit, HostListener ,PLATFORM_ID, Inject, OnDestroy } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NavigationStart, NavigationEnd, Router } from '@angular/router';
declare var $: any;
declare var window: any;

@Component({
  selector: 'app-veboticketing-subheader',
  templateUrl: './veboticketing-subheader.component.html',
  styleUrls: ['./veboticketing-subheader.component.css']
})
export class VeboticketingSubheaderComponent implements OnInit {
  public currentURL: string = "";
  authUser : any;
  isBrowser : boolean;

  constructor(
    private router: Router, 
	@Inject(PLATFORM_ID) platformId: Object
  ) { 
	  this.isBrowser = isPlatformBrowser(platformId);
	  if(this.isBrowser){
		window.scroll(0,0);
	  }
    if (localStorage.getItem('authUser')) {
      this.authUser 		= 	JSON.parse(localStorage.getItem('authUser'));
		}
	this.currentURL = this.router.url.split("/")[1]; 
    this.router.events.subscribe(event => {

      if (event instanceof NavigationStart) {
        this. onWindowScroll();
      }

      if(event instanceof NavigationEnd) {
        this.currentURL = this.router.url.split("/")[1];
       
      }

    });
  }


  ngOnInit() {
  }

  @HostListener("window:scroll", []) 
  onWindowScroll() {
    // do some stuff here when the window is scrolled
	if(this.isBrowser){
		const verticalOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

		var navbar = document.getElementById("veboticket-subhead");
		if(navbar !== null && navbar !== undefined)
		{
		  if (verticalOffset < 145) {
			navbar.classList.remove('is-sticky');
		  }
		  if (verticalOffset > 145) {
			if (!navbar.classList.contains('is-sticky')) {
			  navbar.classList.add('is-sticky');
			}
		  }
		  if (verticalOffset > 145 * 2) {
			if (!navbar.classList.contains('awake')) {
			  navbar.classList.add('awake');
			}
		  }
		  if (verticalOffset < 145 * 2) {
			if (navbar.classList.contains('awake')) {
			  navbar.classList.remove('awake');
			}
		  }
		}
	}

  }

  ngAfterViewInit() {
	if(this.isBrowser){
		var subMenuBtn = document.getElementById("veboticket-menu-toggle");
		var weddingHead = document.getElementById("veboticket-subhead");
		var weddingHeadMenu = document.getElementById("veboticketNav");

		subMenuBtn.addEventListener("click", (e) => {
		  //weddingHead.classList.toggle("collapsed");
		  subMenuBtn.classList.toggle("clicked");

		  if(subMenuBtn.classList.contains("clicked")) {
			weddingHeadMenu.hidden = false;
		  }
		  else {
			weddingHeadMenu.hidden = true;
		  }

		});
	}

  }

}

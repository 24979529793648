import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { catchError,map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import * as globalConstant from './../global_constant';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
	apiDomain	   			=	globalConstant.DOMAIN;
	siteRequestFrom			=	globalConstant.SITE_REQUEST_FROM;
	homeRegionApi			=	globalConstant.HOME_REGION_LIST_API;
	getCountryApi			=	globalConstant.GET_ADD_EXPERIENCE_COUNTRY_API;
	getStateApi				=	globalConstant.GET_ADD_EXPERIENCE_STATE_API;
	getCityApi				=	globalConstant.GET_ADD_EXPERIENCE_CITY_API;
	getLocationRowApi		=	globalConstant.GET_LOCATION_ROW_API;
	locationAutocompleteApi	=	globalConstant.SEARCH_LOCATION_AUTOCOMPLETE_API;

  
  constructor(public router: Router,private http: HttpClient) {  

  } 

  homeRegion(headers){
      const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+headers,
			      'requestFrom':this.siteRequestFrom
          })
        };
        return this.http.post<any>(this.apiDomain+this.homeRegionApi,{},httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
         if(err.status == 401)
          {
            localStorage.removeItem('authUser')
            localStorage.removeItem('login_token')
            localStorage.removeItem('profile_img_url')
            window.location.href = '/login'
          } 
          return err.error;
        }));
  }
  
  
  
	getCountryList(){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom
			})
		};
		return this.http.post<any>(this.apiDomain+this.getCountryApi, {},httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			return err.error;
		}));
	}
	getStateList(country_id){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom
			})
		};
		return this.http.post<any>(this.apiDomain+this.getStateApi, {country_id:country_id},httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			return err.error;
		}));
	}
	getCityList(state_id,headers=''){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom,
				'Authorization':'Bearer '+headers,
			})
		};
		return this.http.post<any>(this.apiDomain+this.getCityApi, {state_id:state_id},httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			if(err.status == 401)
			{
			  localStorage.removeItem('authUser')
			  localStorage.removeItem('login_token')
			  localStorage.removeItem('profile_img_url')
			  window.location.href = '/login'
			}
			return err.error;
		}));
	}
	getLocationBySlug(formData, headers=''){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'requestFrom':this.siteRequestFrom,
				'Authorization':'Bearer '+headers,
			})
		};
		return this.http.post<any>(this.apiDomain+this.getLocationRowApi, formData,httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			/* if(err.status == 401)
			{
			  localStorage.removeItem('authUser')
			  localStorage.removeItem('login_token')
			  localStorage.removeItem('profile_img_url')
			  window.location.href = '/login'
			} */
			return err.error;
		}));
	}
	
	locationAutocomplete(formData){
      const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
			 'requestFrom':this.siteRequestFrom
          })
        };
        return this.http.post<any>(this.apiDomain+this.locationAutocompleteApi,formData,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
			return err.error;
        }));
	}
	 
}





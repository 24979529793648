import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { catchError,map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import * as globalConstant from './../global_constant';

@Injectable({
  providedIn: 'root'
})
export class ExperienceBookingService {
	apiDomain				=	globalConstant.DOMAIN;
	siteRequestFrom			=	globalConstant.SITE_REQUEST_FROM;
	requestToBookingApi		=	globalConstant.REQUEST_EXPERIENCE_BOOKING_API;
	expBookingApi			=	globalConstant.EXPERIENCE_BOOKINGS_API;
	expBookingDataApi		=	globalConstant.EXPERIENCE_BOOKINGS_DATA_API;
	acceptRejectBookingApi	=	globalConstant.ACCEPT_REJECT_BOOKING_API;
	expBookingRequestApi	=	globalConstant.EXPERIENCE_BOOKINGS_REQUESTS_API;
	cancelBookingApi		=	globalConstant.CANCEL_BOOKING_API;
	expSearchBookingApi		=	globalConstant.EXPERIENCE_SEARCH_BOOKINGS_API;
	expSearchBookingOutsideInstantApi		=	globalConstant.EXPERIENCE_SEARCH_BOOKING_OUTSIDE_INSTANT_API;
  
  
	constructor(public router: Router,private http: HttpClient) {  

	} 

	requestForBooking(formData, headers){

		const httpOptions = {
		  headers: new HttpHeaders({
			//'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
			'Content-Type': 'application/json',
			'requestFrom':this.siteRequestFrom,
			'Authorization':headers == "" ?"":'Bearer '+headers
		  })
		}; 
		return this.http.post<any>(this.apiDomain+this.requestToBookingApi,formData,httpOptions).pipe(map(res=>{
		  return res;
		}),
		catchError(err => {
		  return err.error;
		}));
	}
	
	
	cancelBooking(formData, headers){

		const httpOptions = {
		  headers: new HttpHeaders({
			//'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
			'Content-Type': 'application/json',
			'requestFrom':this.siteRequestFrom,
			'Authorization':headers == "" ?"":'Bearer '+headers
		  })
		}; 
		return this.http.post<any>(this.apiDomain+this.cancelBookingApi,formData,httpOptions).pipe(map(res=>{
		  return res;
		}),
		catchError(err => {
		  return err.error;
		}));
	}
	
	expBooking(form_data,headers){

		const httpOptions = {
		  headers: new HttpHeaders({
			//'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
			'Content-Type': 'application/json',
			'requestFrom':this.siteRequestFrom,
			'Authorization':headers == "" ?"":'Bearer '+headers
		  })
		}; 
		return this.http.post<any>(this.apiDomain+this.expBookingApi,form_data,httpOptions).pipe(map(res=>{
		  return res;
		}),
		catchError(err => {
		  return err.error;
		}));
	}
	expBookingRequest(form_data,headers){

		const httpOptions = {
		  headers: new HttpHeaders({
			//'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
			'Content-Type': 'application/json',
			'requestFrom':this.siteRequestFrom,
			'Authorization':headers == "" ?"":'Bearer '+headers
		  })
		}; 
		return this.http.post<any>(this.apiDomain+this.expBookingRequestApi,form_data,httpOptions).pipe(map(res=>{
		  return res;
		}),
		catchError(err => {
		  return err.error;
		}));
	}
	expBookingData(form_data,headers){

		const httpOptions = {
		  headers: new HttpHeaders({
			//'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
			'Content-Type': 'application/json',
			'requestFrom':this.siteRequestFrom,
			'Authorization':headers == "" ?"":'Bearer '+headers
		  })
		}; 
		return this.http.post<any>(this.apiDomain+this.expBookingDataApi,form_data,httpOptions).pipe(map(res=>{
		  return res;
		}),
		catchError(err => {
		  return err.error;
		}));
	}
	acceptRejectBooking(form_data,headers){
		const httpOptions = {
		  headers: new HttpHeaders({
			//'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
			'Content-Type': 'application/json',
			'requestFrom':this.siteRequestFrom,
			'Authorization':headers == "" ?"":'Bearer '+headers
		  })
		}; 
		return this.http.post<any>(this.apiDomain+this.acceptRejectBookingApi,form_data,httpOptions).pipe(map(res=>{
		  return res;
		}),
		catchError(err => {
		  return err.error;
		}));
	}
	expSearchBooking(form_data,headers){
		const httpOptions = {
		  headers: new HttpHeaders({
			//'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
			'Content-Type': 'application/json',
			'requestFrom':this.siteRequestFrom,
			'Authorization':headers == "" ?"":'Bearer '+headers
		  })
		}; 
		return this.http.post<any>(this.apiDomain+this.expSearchBookingApi,form_data,httpOptions).pipe(map(res=>{
		  return res;
		}),
		catchError(err => {
		  return err.error;
		}));
	}
	expSearchBookingOutsideInstant(form_data,headers){
		const httpOptions = {
		  headers: new HttpHeaders({
			//'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
			'Content-Type': 'application/json',
			'requestFrom':this.siteRequestFrom,
			'Authorization':headers == "" ?"":'Bearer '+headers
		  })
		}; 
		return this.http.post<any>(this.apiDomain+this.expSearchBookingOutsideInstantApi,form_data,httpOptions).pipe(map(res=>{
		  return res;
		}),
		catchError(err => {
		  return err.error;
		}));
	}
  
}





import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class ExpGuestGuard implements CanActivate {

    constructor(private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        //console.log(state.url.split('/')[2]);
        let url =state.url;

        if(localStorage.getItem('exp-provider-add'))
        {
            var addExp = JSON.parse(localStorage.getItem("exp-provider-add"));

            if(addExp.length == 1)
            {
                if(url == '/experience-provider-add' || url == '/experience-provider-add/step-2')
                {
                    return true;
                }
                else
                {
                    localStorage.removeItem('exp-provider-add')
                    this.router.navigate(['/'])
                }
            }
            else if(addExp.length == 2)
            {
                if(url == '/experience-provider-add' || url == '/experience-provider-add/step-2' || url == '/experience-provider-add/step-3')
                {
                    return true;
                }
                else
                {
                    localStorage.removeItem('exp-provider-add')
                    this.router.navigate(['/'])
                }
            }
            else if(addExp.length == 3)
            {
                if(url == '/experience-provider-add' || url == '/experience-provider-add/step-2' || url == '/experience-provider-add/step-3' || url == '/experience-provider-add/step-4')
                {
                    return true;
                }
                else
                {
                    localStorage.removeItem('exp-provider-add')
                    this.router.navigate(['/'])
                }
            }
            else if(addExp.length == 4)
            {
                if(url == '/experience-provider-add' || url == '/experience-provider-add/step-2' || url == '/experience-provider-add/step-3' || url == '/experience-provider-add/step-4' || url == '/experience-provider-add/step-5')
                {
                    return true;
                }
                else
                {
                    localStorage.removeItem('exp-provider-add')
                    this.router.navigate(['/'])
                }
            }
            else if(addExp.length == 5)
            {
                if(url == '/experience-provider-add' || url == '/experience-provider-add/step-2' || url == '/experience-provider-add/step-3' || url == '/experience-provider-add/step-4' || url == '/experience-provider-add/step-5'|| url == '/experience-provider-add/step-6')
                {
                    return true;
                }
                else
                {
                    localStorage.removeItem('exp-provider-add')
                    this.router.navigate(['/'])
                }
            }
            else if(addExp.length == 6)
            {
                if(url == '/experience-provider-add' || url == '/experience-provider-add/step-2' || url == '/experience-provider-add/step-3' || url == '/experience-provider-add/step-4' || url == '/experience-provider-add/step-5'|| url == '/experience-provider-add/step-6'|| url == '/experience-provider-add/step-7')
                {
                    return true;
                }
                else
                {
                    localStorage.removeItem('exp-provider-add')
                    this.router.navigate(['/'])
                }
            }
            else if(addExp.length == 7)
            {
                if(url == '/experience-provider-add' || url == '/experience-provider-add/step-2' || url == '/experience-provider-add/step-3' || url == '/experience-provider-add/step-4' || url == '/experience-provider-add/step-5'|| url == '/experience-provider-add/step-6'|| url == '/experience-provider-add/step-7'|| url == '/experience-provider-add/step-8')
                {
                    return true;
                }
                else
                {
                    localStorage.removeItem('exp-provider-add')
                    this.router.navigate(['/'])
                }
            }
            /*else if(addExp.length == 8)
            {
                if(url == '/experience-provider-add' || url == '/experience-provider-add/step-2' || url == '/experience-provider-add/step-3' || url == '/experience-provider-add/step-4' || url == '/experience-provider-add/step-5'|| url == '/experience-provider-add/step-6'|| url == '/experience-provider-add/step-7'|| url == '/experience-provider-add/step-8'|| url == '/experience-provider-add/step-9'|| url == '/experience-provider-add/step-10')
                {
                    return true;
                }
                else
                {
                    localStorage.removeItem('exp-provider-add')
                    this.router.navigate(['/'])
                }
            }*/
            else if(addExp.length == 8)
            {
                if(url == '/experience-provider-add' || url == '/experience-provider-add/step-2' || url == '/experience-provider-add/step-3' || url == '/experience-provider-add/step-4' || url == '/experience-provider-add/step-5'|| url == '/experience-provider-add/step-6'|| url == '/experience-provider-add/step-7'|| url == '/experience-provider-add/step-8'|| url == '/experience-provider-add/step-9'|| url == '/experience-provider-add/step-10'|| url == '/experience-provider-add/step-11' || url == '/experience-provider-add/step-12')
                {
                    
                    return true;
                    
                }
                else
                {
                    localStorage.removeItem('exp-provider-add')
                    this.router.navigate(['/'])
                }
            }
             else if(addExp.length == 9)
            {
                if(url == '/experience-provider-add' || url == '/experience-provider-add/step-2' || url == '/experience-provider-add/step-3' || url == '/experience-provider-add/step-4' || url == '/experience-provider-add/step-5'|| url == '/experience-provider-add/step-6'|| url == '/experience-provider-add/step-7'|| url == '/experience-provider-add/step-8'|| url == '/experience-provider-add/step-9'|| url == '/experience-provider-add/step-10'|| url == '/experience-provider-add/step-11' || url == '/experience-provider-add/step-12')
                {
                    
                    return true;
                    
                }
                else
                {
                    localStorage.removeItem('exp-provider-add')
                    this.router.navigate(['/'])
                }
            }
            else
            {
                localStorage.removeItem('exp-provider-add')
                this.router.navigate(['/'])
            }
            //return true;
        }
        else
        {
            if(url == '/experience-provider-add/step-2' || url == '/experience-provider-add/step-3' || url == '/experience-provider-add/step-4' || url == '/experience-provider-add/step-5'|| url == '/experience-provider-add/step-6'|| url == '/experience-provider-add/step-7'|| url == '/experience-provider-add/step-8'|| url == '/experience-provider-add/step-9'|| url == '/experience-provider-add/step-10'|| url == '/experience-provider-add/step-11' )
            {                
                this.router.navigate(['/'])   
            }
            
            return true;
        }
        
    }
}

import { Component, OnInit, HostListener, Renderer2, ElementRef, TemplateRef, ViewChild, PLATFORM_ID, Inject, OnDestroy } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalDirective } from 'ngx-bootstrap/modal/ngx-bootstrap-modal';
import { Router, NavigationEnd, ActivatedRoute, NavigationStart } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import * as Croppie from 'croppie';
import { CroppieDirective } from 'angular-croppie-module';
declare var $: any;
import { MessageService } from '../../message.service';
import { Experience } from 'src/app/services/experience.service';
import { environment } from '../../../environments/environment';
import { AddExperience } from 'src/app/services/AddExperience.service';
import { Subject, Subscription, Observable, Observer, fromEvent, merge } from 'rxjs';
import { CartService } from 'src/app/services/cart.service';
import { ExperienceDetailsService } from 'src/app/services/experience-details.service';
import { FormBuilder, FormGroup, Form, Validators, FormArray } from '@angular/forms';
import { Dimensions, ImageCroppedEvent, ImageTransform } from '../../image-cropper/interfaces/index';
import { DatePipe, isPlatformBrowser } from '@angular/common';
import { ExperienceBookingService } from '../../services/ExperienceBooking.service';
import { UserProfileService } from '../../services/Userprofile.service';
import { MainService } from 'src/app/main.service';
import { ExperienceShare } from '../../services/experienceShare.service';
import { RegisterService } from 'src/app/services/Register.service';
import * as globalConstant from '../../global_constant';
import { FacebookAlbumService } from 'src/app/services/facebook-album.service';
import { ExperienceProviderAvailablityService } from 'src/app/services/ExperienceProviderAvailablityService.service';
import html2canvas from 'html2canvas';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';
import { Title, DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { resizeCanvas } from './../../image-cropper/utils/resize.utils';
import { ProfileService } from '../../profile-image.service';
import { AuthdataMessageService } from '../../authdata-message.service';
import { map } from 'rxjs/operators';
import { FindCouple } from 'src/app/services/findcouple.service';
import { LocationService } from 'src/app/services/location.service';
import { FbService } from 'src/app/services/Fb.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
//import { CountdownModule, CountdownComponent } from 'ngx-countdown';
import { FullCalendarComponent,CalendarOptions, } from '@fullcalendar/angular';
import { SwiperComponent } from "swiper/angular";
import { ExperienceProviderPromotionalService } from 'src/app/services/ExperienceProviderPromotionalService.service';
import { VisitorsService } from 'src/app/services/Visitors.service';
import SwiperCore, { FreeMode, Navigation, Thumbs } from "swiper";
SwiperCore.use([FreeMode, Navigation, Thumbs]);

//console.log("SwiperCore", SwiperCore);

declare const fbq;
declare const ga;
declare var pintrk: any;
declare var window: any;

@Component({
	selector: 'vebo-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit, OnDestroy {

	@ViewChild('directive', { static: true })
	public croppieDirective: CroppieDirective;

	@ViewChild('directive1', { static: true })
	public croppieThankYouImageDirective: CroppieDirective;

	@ViewChild('directive2', { static: true })
	public croppieShareImageDirective: CroppieDirective;

	@ViewChild('buymodal', { static: false }) public buymodal: ModalDirective;
	@ViewChild('sharePhotoElement', { static: false }) sharePhotoElement: ElementRef;
	//@ViewChild('countDownEle', { static: false }) public countDownEle: ElementRef;
	//@ViewChild('cd', { static: false }) private countdown: CountdownComponent;


	// @ViewChild('directive2',{static : true})
	@ViewChild('thankyouModal', { static: false }) public thankyouModal: ModalDirective;
	@ViewChild('addPhotoVideoModal', { static: false }) public addPhotoVideoModal: ModalDirective;
	@ViewChild('experienceDetailsPopup', { static: false }) public experienceDetailsPopup: ModalDirective;
	@ViewChild('photoShareCroppieModal', { static: false }) public photoShareCroppieModal: ModalDirective;
	@ViewChild('thankYouCroppieModal', { static: false }) public thankYouCroppieModal: ModalDirective;
	//@ViewChild('upload_profile',{static:false}) public upload_profile:ModalDirective;
	@ViewChild('upload_profile', { static: false }) public upload_profile: ModalDirective;
	@ViewChild('large', { static: false }) public large: ModalDirective;
	@ViewChild('upload_cover_profile_image', { static: false }) public upload_cover_profile_image: ModalDirective;
	@ViewChild('largecoverimage', { static: false }) public largecoverimage: ModalDirective;
	@ViewChild('fbAlbumPopup', { static: false }) public fbAlbumPopup: ModalDirective;
	@ViewChild('addCartJoinUsPopup', { static: false }) public addCartJoinUsPopup: ModalDirective;
	@ViewChild('partialPurchasePopup', { static: false }) public partialPurchasePopup: ModalDirective;
	@ViewChild('congratulationPopup', { static: false }) public congratulationPopup: ModalDirective;
	@ViewChild('timerCouplePopup', { static: false }) public timerCouplePopup: ModalDirective;
	@ViewChild('videoPopupModal', { static: false }) public videoPopupModal: ModalDirective;
	@ViewChild("toggleButton", { static: false }) toggleButton: ElementRef;
	@ViewChild("menu", { static: false }) menu: ElementRef;
	@ViewChild('review_accordion_element') review_accordion_element: ElementRef<HTMLElement>;
	@ViewChild('videoElement', { static: false }) videoElement: ElementRef;
	imgFrmeUrlCover: any = '';

	removeImg: any = '';
	swiperCore: any = SwiperCore;
	isBrowser: boolean;
	authCheck: boolean = false;
	authUser: any;
	additionalImagesJoinUs: any = []
	expIncludedArr: any = []
	expNotIncludedArr: any = []
	expAvailabilityArr: any = []
	expAvailabilityMonth: any = []
	expAvailabilityDay: any;
	staticFrames: any = []
	countryList: any = []
	blockBookingDates: any = []
	enableBookingDates: any = []
	profile_img_url = '';
	profile_image = '';
	experienceEasternData = [];
	WEDDING_COUPLE_ROLE_ID = `${environment.WEDDING_COUPLE_ROLE_ID}`;
	EXPERIENCE_PROVIDER_ROLE_ID = `${environment.EXPERIENCE_PROVIDER_ROLE_ID}`;
	CHARITY_USER_ROLE_ID = `${environment.CHARITY_USER_ROLE_ID}`;
	INDIVIDUAL_USER_ROLE_ID = `${environment.INDIVIDUAL_USER_ROLE_ID}`;
	BBB_USER_ROLE_ID = `${environment.BBB_USER_ROLE_ID}`;
	BBB_BABY_USER_ROLE_ID = `${environment.BBB_BABY_USER_ROLE_ID}`;
	CUSTOMER_SUPPORT_ROLE_ID = `${environment.CUSTOMER_SUPPORT_ROLE_ID}`;
	ALTERNATE_EXPERIENCE_PROVIDER_ROLE_ID = `${environment.ALTERNATE_EXPERIENCE_PROVIDER_ROLE_ID}`;
	REGISTERFINDER_ROLE_ID = `${environment.REGISTERFINDER_ROLE_ID}`;
	KNOT_USER_ROLE_ID = `${environment.KNOT_USER_ROLE_ID}`;
	JOY_USER_ROLE_ID = `${environment.JOY_USER_ROLE_ID}`;
	cart_details: any = []
	thankYouUploadedImages: any = []
	is_cart_fill: boolean = false;
	close_card: boolean = false;
	albumStep1: boolean = false;
	albumStep2: boolean = false;
	total_sales_tax: any = 0;
	total_cart_amount: any = 0;
	show_card_details: any = false;
	currentURL: any = ''
	currentURL1: any = ''
	cartDetails: any[] = [];
	expWhatsIncluded: any[] = [];
	expWhatsNotIncluded: any[] = [];
	subscription: Subscription;
	experienceDetailSubscription: Subscription;
	FbAlbumSubscription: Subscription;
	modalPopChangeSubscription: Subscription;
	addExpCountSubscription: Subscription;
	walletAmountSubscription: Subscription;
	authDataMessageSubscription: Subscription;
	coupleTimerMessageSubscription: Subscription;
	authDataMessageProfilePicSubscription: Subscription;
	getCartPaymentMsgSubscription: Subscription;
	getCartMessageSubscription: Subscription;
	getLogoutMessageSubscription: Subscription;
	getPhotoVideoSubscription: Subscription;
	getGiftCertificateSubscription: Subscription;
	showDetailsTab: boolean = false;
	joinUsFormSubmitEnable: boolean = false;
	showExchangeTab: boolean = false;
	showGiftedByTab: boolean = false;
	showPhotosVideosTab: boolean = false;
	showSummeryTab: boolean = false;
	showHowToRedeemTab: boolean = false;
	showPartialPurchTab: boolean = false;
	showReviewTab: boolean = false;
	showJoinUsTab: boolean = false;
	showRemoveRegBtn: boolean = false;
	showAddRegBtn: boolean = false;
	showBuyYourSelfBtn: boolean = false;
	showBuyAsGiftBtn: boolean = false;
	showCloseBtn: boolean = false;
	showReviewExpBtn: boolean = false;
	showAddPhotoVideoBtn: boolean = false;
	showSendThankYouBtn: boolean = false;
	showAddPhotoDeviceBtn: boolean = false;
	showAddVideoDeviceBtn: boolean = false;
	showRedeemScheduleBtn: boolean = false;
	showExchangeBtn: boolean = false;
	showViewPrintBtn: boolean = false;
	showScheduleExpBtn: boolean = false;
	showViewAvaibalityExpBtn: boolean = false;
	newBookingRequestBtn: boolean = false;
	showRedeemNowBtn: boolean = false;
	showExcConfirmBtn: boolean = false;
	showExcCancelBtn: boolean = false;
	showCompletePurchBtn: boolean = false;
	showContinueShopBtn: boolean = false;
	showCheckoutBtn: boolean = false;
	showBackBtn: boolean = false;
	showEwalletBalance: boolean = false;
	priceBlock: boolean = true;
	imageSaveMsgShow: boolean = false;
	exchangeBlock: boolean = false;
	exchangedBlock: boolean = false;
	redeemScheduleBlock: boolean = false;
	onlineScheduleBlock: boolean = false;
	onlineBookingMsgBlock: boolean = false;
	onlineBookingRejectBlock: boolean = false;
	reviewRatingBlock: boolean = false;
	redeemedBlock: boolean = false;
	exhangePrice: number = 0;
	showConfirm: boolean = false;
	showCancel: boolean = false;
	shareProcess: boolean = false;
	guestViewPopup: boolean = false;
	showSeasonHeadline: boolean = false;
	group_pricing_exp: boolean;
	tokenkey: any = '';
	isActiveTab: string = 'summary';
	fbImageType: string = '';
	timerString: string = '';
	experienceDetails: any = [];
	additionalImages: any = [];
	showExperienceDetailsPopup: boolean = false;
	OnlineSchedulingForm: FormGroup;
	minDate: Date;
	bookingDate: string = '';
	bookingStatus: string = '';
	bookingMessage: string = '';
	bookingShortMessage: string = '';
	cropyUserType: any = '';
	showRegChkmark: boolean = false;
	is_guest_view: boolean = false;
	resultOurRegistry: any = [];
	purchaseExpID: any;
	purchaseExpSlug: any;
	purchaseExpTitle: any;
	purchaseExpImage: any;
	purchaseExpType: any;
	purchaseExpPrice: any;
	purchaseExpQuantity: any;
	purchaseExpVerID: any;
	purchaseExpTax: any;
	thankYouStep1: boolean = false;
	thankYouStep2: boolean = false;
	thankYouStep3: boolean = false;
	thankYouStep4: boolean = false;
	thankYouStep5: boolean = false;
	photoVideoStep1: boolean = false;
	ShareVideoStep1: boolean = false;
	photoVideoStep2: boolean = false;
	photoVideoStep3: boolean = false;
	isLoading: boolean = false;
	showAddExpBtns: boolean = false;
	firstNavigationTimer: boolean = false;
	couple_name: string = '';
	transform: ImageTransform = {};
	frameImages: any = []
	images: any = []
	wallet_balance: any = "0.00";
	thankYouImages: any = []
	cover_image_url = '';
	thankyouImage = '';
	experience_video_url: SafeResourceUrl;
	ThankyouProcessForm: FormGroup;
	thank_you_file_path: any = '';
	thank_you_file_url: any = '';
	thankYouEmailReceipents = '';
	shareImages: any = []
	choosedSharePhotoUrl: any = '';
	choosedFacebookPhotoUrl: any = '';
	choosedCoverPhotoUrl: any = '';
	slot: any = '';
	showAddJoinUsBtn: boolean = false;
	cartGiftBoxes: any = [];
	facebookAlbums: any = [];
	choosedFacebookAlbums: any = [];
	show_close_button = false;
	fileFormat: any = "";
	yesterday: any = '';
	isLogin: boolean = false;
	addedtocart: boolean = false;
	cart_items: any = ""
	joinUsStep1: boolean = true;
	joinUsStep2: boolean = false;
	joinUsStep3: boolean = false;
	joinUsStep4: boolean = false;
	joinUsExpDetails: any = [];
	joinusNamesDetails: FormArray;
	joinUsCartForm: FormGroup;
	joinUsSubmitted: boolean = false;
	disableCartBtn: boolean = true;
	selectedUnit: any = 0;
	webNavigation: number = 0;
	timerIntervalFunc: any;
	thankYouImageId: any = 0;
	user_data: any = [];
	username: string = '';
	joinUsUnits: any = [];
	purchaseUnit: any = 0;
	remainingUnits: number = 0
	units: any = [];
	guest_user_data: any = {};
	other_page_data = '';
	showRedeemNowConfirm: boolean = false;
	showRedeemNowCancel: boolean = false;
	no_slot_avail_message: any = '';
	end_navigate_stop = false;
	Loading: any = false;
	pop_first_content: any = true;
	pop_second_content: any = false;
	search_string: any = '';
	couple_image_url: any = '';
	show_couple: any = false;
	no_couple_data_found: any = false;
	pop_third_content: any = false;
	data1: any = '';
	selected_couple: any = '';
	amount: number = 0;
	modelChanged: Subject<string> = new Subject<string>();
	amount_required_error: boolean = false;
	page: number = 1;
	certificate_data: any = '';
	timerEndStr: any = '';
	hide_back_button: any = true;
	gift_certificate_couple_full_name: any = "";
	show_congratulation: boolean;
	backfromRedemmeScheduleBtn: any = false;
	names_arr: any = globalConstant.INVAILD_NAME_STR;

	thankyou_message_is_blank = false;
	thankYouMessage_error = true;

	locationList: any = [];
	locationAutocompleteList: any = [];
	autocomplete_location_search: string = '';

	enable_rfp_229: any = globalConstant.ENABLE_RFP_229;
	people_data: any = [];
	selected_people: number = 0;
	maximumDate: any = '';
	//fareharbor_price:number = 0;
	//fareharbor_price_with_tax:number = 0;
	thumbsSwiper: any;
	show_instant_booking: boolean = false;

	booking_price: number = 0;
	booking_price_with_tax: number = 0;
	seasonLabel:boolean = false;
	instant_book_request:number=0;

	inputJSON = {
		"endtime": "2017-04-13 23:00:00",
		"starttime": "2017-04-13 01:00:00"
	};
	scrollTop() {
		if (this.isBrowser) {
			document.body.scrollTop = document.documentElement.scrollTop = 0;
		}
	}
	clickCart: any = "";

	congratulationPopupConfig = {
		backdrop: false,
		ignoreBackdropClick: true,
	};
	/* timerConfig = {
	   format: 'HH:mm:ss',
	   leftTime: 123,
	   stopTime: 123,
	}; */


	owladdPhoto: OwlOptions = {
		items: 4,
		dots: false,
		nav: true,
		autoplay: false,
		loop: false,
		margin: 5,
		rewind: true,
		//goToFirst: true,
		navText: ["<span class='arrowLeft'></span>",
			"<span class='arrowRight'></span>"],
		// responsive: {
		// 	0: {
		// 		items: 2
		// 	},
		// 	576: {
		// 		items: 3
		// 	},
		// 	992: {
		// 		items: 4
		// 	}
		// },

		smartSpeed: 500,
	}
	owlExpDetailJoin: OwlOptions = {
		loop: false,
		mouseDrag: false,
		autoplay: true,
		touchDrag: true,
		pullDrag: false,
		dots: false,
		autoWidth: true,
		navSpeed: 700,
		navText: ['1', '2'],
		nav: false,
		items: 1,
		rewind: true,
		margin: 20

	}


	public croppieShareOptions: Croppie.CroppieOptions = {
		boundary: { width: 500, height: 250 },
		viewport: { width: 500, height: 250 },
		showZoomer: true,
		enableExif: true,
		//enableResize: true,
		enableOrientation: true,
		//enforceBoundary: true
	};
	public croppieOptions: Croppie.CroppieOptions = {
		boundary: { width: 300, height: 300 },
		viewport: { width: 128, height: 128 },
		showZoomer: true,
		enableExif: true,
		//enableResize: true,
		enableOrientation: true,
		//enforceBoundary: true
	};
	public croppieprofile: Croppie.CroppieOptions = {
		boundary: { width: 300, height: 300 },
		viewport: { width: 250, height: 250, type: 'square' },
		showZoomer: true,
		enableOrientation: true,
		//enforceBoundary: false
	};
	shareType: string = '';
	share_video_path: any;
	choosedShareVideoUrl: any = '';
	PhotoVideoShareForm: FormGroup;
	imageSaveMsg: string = '';
	uploadedPhotoVideoUrl: string = '';
	photoVideoSaveStatus: boolean = false;
	shareFacebookStatus: boolean = false;
	completeSharingProcess: boolean = false;
	showUploadProfileText: boolean = false;
	showCountDownText: boolean = false;
	showCountDownTextPopup: boolean = false;
	timerEndDate: any = "";
	timerStartDate: any = "";
	is_profile_edit: boolean = false;
	is_cover_edit: boolean = false
	img_type = 'profile_image_crop';
	coverimages: any = []
	croppedImage: any = '';
	crop_image_type: string = 'profile_image';
	imageChangedEvent: any = '';
	is_cover_image_edit: any = 0;
	containWithinAspectRatio = false;
	showCoverImageCropper = false;
	canvasRotation = 0;
	rotation = 0;
	scale = 1;
	coupleRoleID = globalConstant.WEDDING_COUPLE_ROLE_ID
	facebookSocialLink = globalConstant.FACEBOOK_SOCIAL_LINK
	linkedinSocialLink = globalConstant.LINKEDIN_SOCIAL_LINK
	pinterestSocialLink = globalConstant.PINTEREST_SOCIAL_LINK
	instagramSocialLink = globalConstant.INSTAGRAM_SOCIAL_LINK
	androidAppDownloadLink = globalConstant.ANDROID_APP_DOWNLOAD_LINK
	iphoneAppDownloadLink = globalConstant.IOS_APP_DOWNLOAD_LINK
	mobileDeviceName: string = '';
	enable_rfp_226 = globalConstant.ENABLE_RFP_226
	requestedDate: any = '';
	submitted = false;
	request_to_book = true;
	show_fareharbor = false;
	fareharborForm: FormGroup;
	fare_harbor_slot_data: any;
	booking_slot_data: any;
	fare_harbor_custom_data: any;
	index: any = '';
	dynamic_option_array: any = [];
	todayDate: any = "";
	lastDate: any = "";
	counter = 0;
	categoryList_for_gift_ideas: any = '';
	categoryList_for_experiece: any = '';
	exp_video: any;

	@ViewChild('suggestedExperiencePopup', { static: false }) public suggestedExperiencePopup: ModalDirective;
	avail_child_exp = false;
	modalRef: BsModalRef;
	main_exp_id: any = '';
	type_identity = '';
	resultExp: any = '';
	suggestionData: any = [];
	data_for_dropdown_in_popup: any = '';
	experienceGroupPricingSubscription: Subscription;
	pricing_popup: boolean;
	date_value: any = '';
	totalAdded: any = 0;
	totalJoinusPrice: number = 0
	totalJoinExp: number = 0
	joinUsSelectedUnit = 0;
	sales_tax = 0;
	joinUsName = ""
	totalAmount: any = 0;
	isMenuOpen = false;
	public text: String;
	autocompleteExpResults: any = [];
	show_user_name_input: boolean = false;
	hotel_data: any = [];
	peek_unit_id: string = "";
	peek_availability_id: string = "";
	name_error: boolean = false;
	is_mobile_view: boolean = false;
	showPromoCalendar:boolean = false;
	wedding_date : string = "";
	requires_visitors_details:any = [];

	calendarOptions: CalendarOptions = {
		initialView: 'dayGridMonth',
    	selectable: true,
		unselectAuto:false,
		weekends: true,
		headerToolbar: {
			left: 'prev',
			center: 'title',
			right: 'next'
		},
		events: [],
		//selectAllow:this.handelSelectClick.bind(this),
		dayCellClassNames:this.handleBgDateClick.bind(this),
		dateClick: this.handleDateClick.bind(this)
	};



	@HostListener('document:click', ['$event'])
	clickout(event) {
		if (this.eRef.nativeElement.contains(event.target)) {
			//alert("clicked inside");
		} else {
			if (this.isBrowser) {
				var element = document.querySelector("body");
				var htmlelement = document.querySelector("html")
				if (element) {
					element.classList.remove('overflow');
				}
				if (htmlelement) {
					htmlelement.classList.remove('overflow');
				}
				//alert("clicked outside");
			}
		}
	}


	constructor(
		private router: Router,
		private toastr: ToastrManager,
		private experience: Experience,
		private messageService: MessageService,
		private addexperience: AddExperience,
		private cartService: CartService,
		private experienceBookingService: ExperienceBookingService,
		private formBuilder: FormBuilder,
		private ExpDetailService: ExperienceDetailsService,
		private mainService: MainService,
		private userProfileService: UserProfileService,
		public datepipe: DatePipe,
		private registerService: RegisterService,
		private experienceShareService: ExperienceShare,
		private FacebookAlbum: FacebookAlbumService,
		private fbService: FbService,
		private experienceProviderAvailablityService: ExperienceProviderAvailablityService,
		public sanitizer: DomSanitizer,
		private modalService: BsModalService,
		private profileService: ProfileService,
		private authdataMessageService: AuthdataMessageService,
		private eRef: ElementRef,
		public findCoupleService: FindCouple,
		public locationService: LocationService,
		private visitorService: VisitorsService,
		private renderer: Renderer2,
		private PromotionalService: ExperienceProviderPromotionalService,
		@Inject(PLATFORM_ID) platformId: Object
	) {
		this.isBrowser = isPlatformBrowser(platformId);
		if (this.isBrowser) {
			window.scrollTo(0, 0);
		}

		if (window.innerWidth < 768) {
			this.is_mobile_view = true;
		} else {
			this.is_mobile_view = false;
		}

		this.getMobileDeviceName();

		//console.log("swiperCore", this.swiperCore);

		if (localStorage.getItem('authUser')) {

			if (localStorage.getItem('login_token')) {
				this.tokenkey = localStorage.getItem('login_token');

				this.getUserAuthDataApi();	// calls function for get on time data from api by user token.

				//this.initializeAuthUserData(); 	// calls function for initialize auth  
			}
		}

		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				if (this.router.url.split("/")[1]) {
					if (this.router.url.split("/")[2]) {
						if (this.router.url.split("/")[3]) {
							this.currentURL1 = this.router.url.split("/")[3];
						} else {
							this.currentURL1 = this.router.url.split("/")[2];
						}
					} else {
						this.currentURL1 = this.router.url.split("/")[1];
					}
				} else {
					this.currentURL1 = "";
				}
			}
		});


		this.subscription = this.cartService.getMessage().subscribe(message => {
			if (message || localStorage.getItem('gift_box_details')) {
				if (message) {
					this.cart_details = JSON.parse(message);
				}
				if (localStorage.getItem('gift_box_details')) {
					this.cartGiftBoxes = JSON.parse(localStorage.getItem('gift_box_details'));
				}
				this.show_card_details = true;
				this.is_cart_fill = true;
			} else {
				this.cart_details = [];
				this.cartGiftBoxes = [];
				this.show_card_details = false;
				this.is_cart_fill = false;
			}

			this.total_sales_tax = 0;
			this.total_cart_amount = 0;
			if (this.cart_details != undefined && this.cart_details != null && this.cart_details != '') {
				for (let i = 0; i < this.cart_details.length; i++) {
					this.total_sales_tax = Number(this.total_sales_tax) + Number(this.cart_details[i].tax);
					this.total_cart_amount = this.total_cart_amount + Number((this.cart_details[i].line_total * this.cart_details[i].qty))
				}
			}
			if (this.cartGiftBoxes != undefined && this.cartGiftBoxes != null && this.cartGiftBoxes != '') {
				for (let i = 0; i < this.cartGiftBoxes.length; i++) {
					this.total_sales_tax = Number(this.total_sales_tax) + Number(this.cartGiftBoxes[i].tax)
					this.total_cart_amount = this.total_cart_amount + Number((this.cartGiftBoxes[i].line_total * this.cartGiftBoxes[i].qty))
				}
			}
			this.total_cart_amount = (this.total_cart_amount + this.total_sales_tax);

		});


		this.experienceDetailSubscription = this.ExpDetailService.getMessage().subscribe(message => {
			//console.log(message);
			if (message) {
				this.showPromoCalendar = false;
				this.showRedeemNowBtn = false;
				this.showRedeemScheduleBtn = false;
				this.backfromRedemmeScheduleBtn = false;
				this.newBookingRequestBtn = false;
				if (this.currentURL != 'registries') {
					this.getWalletAmount();
				}

				this.show_close_button = false;
				this.requestedDate = '';
				this.experienceDetails = [];
				this.booking_slot_data = [];
				this.experienceDetails = message;

				this.requires_visitors_details = [];

				this.blockBookingDates = [];
				this.enableBookingDates = [];
				if (this.isBrowser) {
					if (document.getElementById("main-video11")) {
						var video;
						setTimeout(() => {
							if (document.getElementById('main-video11')) {
								video = document.getElementById('main-video11')

								video.play();
							}
						}, 10)
					}
				}
				this.exp_video = this.experienceDetails.cover_video2;
				this.showRedeemNowConfirm = false;
				this.showRedeemNowCancel = false;
				if (this.experienceDetails.other_page_data) {
					this.other_page_data = this.experienceDetails.other_page_data;

				} else {
					this.other_page_data = '';
				}
				if (this.experienceDetails.experience_status_msg == 'EXCHANGED') {
					this.showEwalletBalance = true;
					this.exchangedBlock = false;
				}
				if (this.experienceDetails.availability != "") {
					this.expAvailabilityArr = JSON.parse(this.experienceDetails.availability);
				}
				if (this.experienceDetails.experience_months_availability != "") {
					this.expAvailabilityMonth = this.experienceDetails.experience_months_availability;
				}
				if (this.experienceDetails.experience_days_availability != "") {
					this.expAvailabilityDay = this.experienceDetails.experience_days_availability;
				}
				if (this.experienceDetails.whats_included) {
					this.expIncludedArr = JSON.parse(this.experienceDetails.whats_included);
				}
				if (this.experienceDetails.whats_not_included) {
					this.expNotIncludedArr = JSON.parse(this.experienceDetails.whats_not_included);
				}

				this.additionalImages = this.experienceDetails.additional_images;
				this.main_exp_id = message.id;
				this.data_for_dropdown_in_popup = message;
				this.form_data();
				if (typeof this.authUser != "undefined" && typeof this.authUser != null && typeof this.authUser.email != "undefined" && typeof this.authUser.email != null) {
					if (this.OnlineSchedulingForm != null && this.OnlineSchedulingForm != undefined && this.OnlineSchedulingForm.get('couple_email') != null && this.OnlineSchedulingForm.get('couple_email') != undefined)
						this.OnlineSchedulingForm.get('couple_email').setValue(this.authUser.email);
				}

				if (typeof this.authUser != "undefined" && typeof this.authUser != null && typeof this.authUser.name != "undefined" && typeof this.authUser.name != null) {

					if (this.OnlineSchedulingForm != null && this.OnlineSchedulingForm != undefined && this.OnlineSchedulingForm.get('name') != null && this.OnlineSchedulingForm.get('name') != undefined && this.authUser.name != null && this.authUser.name != "") {
						let name_str = this.authUser.name.toLowerCase();
						let invaild_str = this.names_arr.filter(function (txt) {
							return name_str.includes(txt);
						});
						if (invaild_str == "") {
							this.OnlineSchedulingForm.get('name').setValue(this.authUser.name);
							this.show_user_name_input = false;
						} else {
							this.show_user_name_input = true;
						}
					} else {
						this.show_user_name_input = true;
					}
				} else {
					this.show_user_name_input = true;
				}


				if (this.experienceDetails.experience_booking_type > 1) {
					if (this.experienceDetails.available_dates_object && this.experienceDetails.available_dates_object.length > 0) {
						for(const availDate of this.experienceDetails.available_dates_object){
							let aDate    =   new Date(Date.parse(availDate));
							this.enableBookingDates.push(this.convertDateToUTC(aDate));
						}
						this.blockBookingDates	=	[];
					}
					if(this.experienceDetails.available_dates.length > 0){
						let aDate         =   new Date(Date.parse(this.experienceDetails.available_dates_object[this.experienceDetails.available_dates_object.length-1]));
						this.maximumDate  =   this.convertDateToUTC(aDate);
					}else{
						let maxDate     = new Date();
						let limitMonth  = 12;
						if(this.experienceDetails.limit_3_month_calendar == 1 && this.instant_book_request == 1){
						  limitMonth  = 3;
						}
						maxDate.setMonth(maxDate.getMonth() + limitMonth);
						this.maximumDate  =   maxDate;
					}

					// if no availability available then display booking request process.
					if(this.enableBookingDates.length == 0){
						this.seasonLabel = true;

						this.instant_book_request = 2; // request to book process if enable dates are not getting from the api.
						
						if(this.experienceDetails.is_seasonal == 1){

							let maxDate     = new Date();
							let limitMonth  = 12;
							if(this.experienceDetails.limit_3_month_calendar == 1 && this.instant_book_request == 1){
								limitMonth  = 3;
							}
							maxDate.setMonth(maxDate.getMonth() + limitMonth);
							this.maximumDate  =   maxDate;

							let season_start 		= this.experienceDetails.season_start;
							let season_end 	 		= this.experienceDetails.season_end;
							let currentYear  		= new Date().getFullYear();
							let season_start_date 	= this.parseDateToObject(currentYear+"-"+season_start);
							let season_end_date 	= this.parseDateToObject(currentYear+"-"+season_end);
	
							if(new Date().getTime() >= season_start_date.getTime() && new Date().getTime() >= season_end_date.getTime()){
								currentYear += 1;
								season_start_date 	= this.parseDateToObject(currentYear+"-"+season_start);
								season_end_date 	= this.parseDateToObject(currentYear+"-"+season_end);
							}
							
							let index = 0;
							let startDate = this.parseDateToObject(currentYear+"-"+season_start);
	
							while (index < 1) {
								this.enableBookingDates.push(startDate);
								season_start_date.setDate(season_start_date.getDate() + 1);
	
								startDate = new Date(season_start_date.valueOf());
								
								if(season_end_date.getTime() <= season_start_date.getTime()){
									this.enableBookingDates.push(season_end_date);
									index = 1;
								}
							}
						}
					
					}else{
						this.getPromotionalDetails();
						this.instant_book_request = 1; // book now process.
					}

				}else{
					this.instant_book_request	=	2;
					if(this.experienceDetails.block_dates && this.experienceDetails.block_dates.length > 0) {
						for(const blockDate of this.experienceDetails.block_dates){
							let bDate    =   new Date(Date.parse(blockDate));
							this.blockBookingDates.push(this.convertDateToUTC(bDate));
						}
						this.enableBookingDates	=	[];
					}

					if(this.experienceDetails.is_seasonal == 1){
						let maxDate     = new Date();
						let limitMonth  = 12;
						if(this.experienceDetails.limit_3_month_calendar == 1){
							limitMonth  = 3;
						}
						maxDate.setMonth(maxDate.getMonth() + limitMonth);
						this.maximumDate  =   maxDate;

						let season_start 		= this.experienceDetails.season_start;
						let season_end 	 		= this.experienceDetails.season_end;
						let currentYear  		= new Date().getFullYear();
						let season_start_date 	= this.parseDateToObject(currentYear+"-"+season_start);
						let season_end_date 	= this.parseDateToObject(currentYear+"-"+season_end);

						if(new Date().getTime() >= season_start_date.getTime() && new Date().getTime() >= season_end_date.getTime()){
							currentYear += 1;
							season_start_date 	= this.parseDateToObject(currentYear+"-"+season_start);
							season_end_date 	= this.parseDateToObject(currentYear+"-"+season_end);
						}
						
						let index = 0;
						let startDate = this.parseDateToObject(currentYear+"-"+season_start);

						while (index < 1) {
							this.enableBookingDates.push(startDate);
							season_start_date.setDate(season_start_date.getDate() + 1);

							startDate = new Date(season_start_date.valueOf());
							
							if(season_end_date.getTime() <= season_start_date.getTime()){
								this.enableBookingDates.push(season_end_date);
								index = 1;
							}
						}
					}
				}

				

				if (this.isBrowser) {
					var elems = document.querySelectorAll(".exp_tabs.active");
					if (elems.length > 0) {
						for (var i = 0; i < elems.length; i++) {
							elems[i].classList.remove('active');
							elems[i].classList.remove('show');
						}
					}
				}

				if (typeof this.experienceDetails.guest_registry != "undefined" && this.experienceDetails.guest_registry == 1) {

					this.guest_user_data = this.experienceDetails.guest_data;

					this.is_guest_view = true;

					if (this.experienceDetails.experience_type == 3) {
						this.showGiftedByTab = true;
						this.showPhotosVideosTab = true;
						this.showDetailsTab = true;

						this.showReviewTab = false;
						this.showJoinUsTab = false;
					} else {
						this.showDetailsTab = true;
						this.showSummeryTab = true;
						this.showReviewTab = true;
						this.showJoinUsTab = true;

						this.showGiftedByTab = false;
						this.showPhotosVideosTab = false;
					}
					this.priceBlock = true;
					this.guestViewPopup = true;
					this.pricing_popup = false;

				} else if (this.experienceDetails.pricing_option == 1) {
					this.pricing_popup = true;
				} else if (typeof this.experienceDetails.add_experience != "undefined" && this.experienceDetails.add_experience == 1) {

					this.pricing_popup = false;

					this.showDetailsTab = true;
					this.showSummeryTab = true;
					this.showReviewTab = true;
					this.showJoinUsTab = false;
					this.priceBlock = false;
					this.guestViewPopup = false;
					this.showExchangeTab = false;
					this.showGiftedByTab = false;
					this.showPhotosVideosTab = false;
					this.showHowToRedeemTab = false;
					this.showPartialPurchTab = false;
					/* SHOW/HIDE BUTTON */
					if (this.experienceDetails.is_registry_status == 0 || this.experienceDetails.is_registry_status == 0) {
						this.showRemoveRegBtn = false;
						this.showAddRegBtn = true;
						this.showAddJoinUsBtn = true;
					}
					if (this.experienceDetails.is_registry_status == 1) {
						this.showRemoveRegBtn = true;
						this.showAddRegBtn = false;
						this.showAddJoinUsBtn = false;
					}
					this.showBuyYourSelfBtn = true;
					this.showBuyAsGiftBtn = true;
					this.showCloseBtn = false;
					this.showReviewExpBtn = false;
					this.showAddPhotoVideoBtn = false;
					this.showSendThankYouBtn = false;
					this.showAddPhotoDeviceBtn = false;
					this.showAddVideoDeviceBtn = false;
					this.showRedeemScheduleBtn = false;
					this.showExchangeBtn = false;
					this.showViewPrintBtn = false;
					this.showScheduleExpBtn = false;
					this.showRedeemNowBtn = false;
					this.showExcConfirmBtn = false;
					this.showExcCancelBtn = false;
					this.showCompletePurchBtn = false;
					this.showBackBtn = false;
					this.showCheckoutBtn = false;
					this.showContinueShopBtn = false;
					this.showEwalletBalance = true;

					this.priceBlock = true;
					this.exchangeBlock = false;
					this.redeemScheduleBlock = false;
					this.onlineScheduleBlock = false;
					this.onlineBookingMsgBlock = false;
					this.onlineBookingRejectBlock = false;
					this.reviewRatingBlock = true;
					this.exchangedBlock = false;
					this.redeemedBlock = false;
					this.show_fareharbor = false;
					this.isActiveTab = 'summary';
					this.show_instant_booking = false;

				} else {
					this.pricing_popup = false;
					this.guestViewPopup = false;
					//this.resultOurRegistry = this.experienceDetails.resultOurRegistry;

					this.experience_video_url = this.sanitizer.bypassSecurityTrustResourceUrl(this.experienceDetails.video_update);
					if (this.experienceDetails.experience_status == 0) {
						/* SHOW/HIDE TABS */
						this.showDetailsTab = true;
						this.showExchangeTab = false;
						this.showGiftedByTab = false;
						this.showPhotosVideosTab = false;
						this.showSummeryTab = true;
						this.showHowToRedeemTab = false;
						this.showPartialPurchTab = false;
						this.showReviewTab = true;
						this.showJoinUsTab = false;

						/* SHOW/HIDE BUTTON */
						this.showRemoveRegBtn = false;
						this.showAddRegBtn = true;
						this.showAddJoinUsBtn = false;
						this.showBuyYourSelfBtn = false;
						this.showBuyAsGiftBtn = false;
						this.showCloseBtn = false;
						this.showReviewExpBtn = false;
						this.showAddPhotoVideoBtn = false;
						this.showSendThankYouBtn = false;
						this.showAddPhotoDeviceBtn = false;
						this.showAddVideoDeviceBtn = false;
						this.showRedeemScheduleBtn = false;
						this.showExchangeBtn = false;
						this.showViewPrintBtn = false;
						this.showScheduleExpBtn = false;
						this.showRedeemNowBtn = false;
						this.showExcConfirmBtn = false;
						this.showExcCancelBtn = false;
						this.showCompletePurchBtn = false;
						this.showBackBtn = false;
						this.showCheckoutBtn = false;
						this.showContinueShopBtn = false;
						this.showEwalletBalance = false;

						this.priceBlock = true;
						this.exchangeBlock = false;
						this.redeemScheduleBlock = false;
						this.onlineScheduleBlock = false;
						this.onlineBookingMsgBlock = false;
						this.onlineBookingRejectBlock = false;
						this.reviewRatingBlock = false;
						this.exchangedBlock = false;
						this.redeemedBlock = false;
						this.show_fareharbor = false;
						this.isActiveTab = 'summary';
						this.show_instant_booking = false;

					} else if (this.experienceDetails.experience_status == 1) {
						/* SHOW/HIDE TABS */
						this.showDetailsTab = true;
						this.showExchangeTab = false;
						this.showGiftedByTab = false;
						this.showPhotosVideosTab = false;
						this.showSummeryTab = true;
						this.showHowToRedeemTab = false;
						this.showPartialPurchTab = false;
						this.showReviewTab = true;
						this.showJoinUsTab = true;

						/* SHOW/HIDE BUTTON */
						this.showRemoveRegBtn = true;
						this.showAddRegBtn = false;
						this.showAddJoinUsBtn = false;
						this.showBuyYourSelfBtn = true;
						this.showBuyAsGiftBtn = true;
						this.showCloseBtn = false;
						this.showReviewExpBtn = false;
						this.showAddPhotoVideoBtn = false;
						this.showSendThankYouBtn = false;
						this.showAddPhotoDeviceBtn = false;
						this.showAddVideoDeviceBtn = false;
						this.showRedeemScheduleBtn = false;
						this.showExchangeBtn = false;
						this.showViewPrintBtn = false;
						this.showScheduleExpBtn = false;
						this.showRedeemNowBtn = false;
						this.showExcConfirmBtn = false;
						this.showExcCancelBtn = false;
						this.showCompletePurchBtn = false;
						this.showBackBtn = false;
						this.showCheckoutBtn = false;
						this.showContinueShopBtn = false;
						this.showEwalletBalance = true;
						this.showSeasonHeadline = true;

						this.priceBlock = true;
						this.exchangeBlock = false;
						this.redeemScheduleBlock = false;
						this.onlineScheduleBlock = false;
						this.onlineBookingMsgBlock = false;
						this.onlineBookingRejectBlock = false;
						this.reviewRatingBlock = true;
						this.exchangedBlock = false;
						this.redeemedBlock = false;
						this.show_fareharbor = false;
						this.isActiveTab = 'summary';
						this.show_instant_booking = false;
					} else if (this.experienceDetails.experience_status == 2) {

						// SHOW/HIDE TABS //
						this.showDetailsTab = true;
						this.showExchangeTab = false;
						this.showGiftedByTab = true;
						this.showPhotosVideosTab = false;
						this.showSummeryTab = true;
						this.showHowToRedeemTab = true;
						this.showPartialPurchTab = false;
						this.showReviewTab = false;
						this.showJoinUsTab = false;
						this.redeemedBlock = false;

						// SHOW/HIDE BUTTON //
						this.showRemoveRegBtn = false;
						this.showAddRegBtn = false;
						this.showAddJoinUsBtn = false;
						this.showBuyYourSelfBtn = false;
						this.showBuyAsGiftBtn = false;
						this.showCloseBtn = false;
						this.showReviewExpBtn = false;
						this.show_instant_booking = false;
						this.onlineScheduleBlock = false;
						this.showViewPrintBtn = true;
						this.showRedeemScheduleBtn = true;
						this.backfromRedemmeScheduleBtn = false;
						this.priceBlock = true;
						this.exchangeBlock = false;
						this.showSeasonHeadline = true;

						if (this.experienceDetails.experience_type == 3) {
							this.showAddPhotoVideoBtn = true;
							this.showSendThankYouBtn = true;

							this.showGiftedByTab = true;
							this.showPhotosVideosTab = true;
							this.showSummeryTab = false;
							this.showHowToRedeemTab = false;
							this.showExchangeBtn = false;
						} else {
							this.showAddPhotoVideoBtn = false;
							this.showSendThankYouBtn = false;
						}


						if (this.experienceDetails.experience_type == 1) {
							this.showExchangeBtn = true;
						}
						this.showRedeemNowConfirm = false;
						this.showRedeemNowCancel = false;
						this.showAddPhotoDeviceBtn = false;
						this.showAddVideoDeviceBtn = false;
						this.showScheduleExpBtn = false;
						this.showRedeemNowBtn = false;
						this.showExcConfirmBtn = false;
						this.showExcCancelBtn = false;
						this.showCompletePurchBtn = false;
						this.showBackBtn = false;
						this.showCheckoutBtn = false;
						this.showContinueShopBtn = false;
						this.showEwalletBalance = false;

						this.redeemScheduleBlock = false;
						this.onlineBookingMsgBlock = false;
						this.onlineBookingRejectBlock = false;
						this.reviewRatingBlock = false;
						this.exchangedBlock = false;
						this.redeemedBlock = false;

						this.showViewAvaibalityExpBtn = false;
						this.showScheduleExpBtn = false;
						this.isActiveTab = 'how_to_redeem';

						if (this.experienceDetails.booking_request == 1) {
							this.redeemScheduleInfo();
						} else if (this.experienceDetails.exchange_request == 1) {
							this.addExchangeInfo();
						} else if (this.experienceDetails.redeem_request == 1) {
							this.redeem_confirmation();
						}

					} else if (this.experienceDetails.experience_status == 3) {
						// SHOW/HIDE TABS //
						this.showDetailsTab = true;
						this.showExchangeTab = true;
						this.showGiftedByTab = true;
						this.showPhotosVideosTab = false;
						this.showSummeryTab = false;
						this.showHowToRedeemTab = false;
						this.showPartialPurchTab = false;
						this.showReviewTab = false;
						this.showJoinUsTab = false;

						// SHOW/HIDE BUTTON //
						this.showRemoveRegBtn = false;
						this.showAddRegBtn = false;
						this.showAddJoinUsBtn = false;
						this.showBuyYourSelfBtn = true;
						this.showBuyAsGiftBtn = true;
						this.showCloseBtn = true;
						this.showReviewExpBtn = false;
						this.showAddPhotoVideoBtn = false;
						this.showSendThankYouBtn = false;
						this.showAddPhotoDeviceBtn = false;
						this.showAddVideoDeviceBtn = false;
						this.showRedeemScheduleBtn = false;
						this.showExchangeBtn = false;
						this.showViewPrintBtn = false;
						this.showScheduleExpBtn = false;
						this.showRedeemNowBtn = false;
						this.showExcConfirmBtn = false;
						this.showExcCancelBtn = false;
						this.showCompletePurchBtn = false;
						this.showBackBtn = false;
						this.showCheckoutBtn = false;
						this.showContinueShopBtn = false;
						this.showEwalletBalance = true;

						this.priceBlock = true;
						this.exchangeBlock = false;
						this.redeemScheduleBlock = false;
						this.onlineScheduleBlock = false;
						this.onlineBookingMsgBlock = false;
						this.onlineBookingRejectBlock = false;
						this.reviewRatingBlock = false;
						this.exchangedBlock = false;
						this.redeemedBlock = false;
						this.show_fareharbor = false;
						this.isActiveTab = 'exchange';
						this.showViewAvaibalityExpBtn = false;
						this.showScheduleExpBtn = false;
						this.show_instant_booking = false;

					} else if (this.experienceDetails.experience_status == 4) {


						this.showDetailsTab = true;
						this.showExchangeTab = false;
						this.showGiftedByTab = true;
						this.showPhotosVideosTab = false;
						this.showSummeryTab = false;
						this.showHowToRedeemTab = false;
						this.showPartialPurchTab = true;
						this.showReviewTab = false;
						this.showJoinUsTab = false;

						/* SHOW/HIDE BUTTON */
						if (this.experienceDetails.partial_remaining_qty > 0) {
							this.showCompletePurchBtn = true;
						} else {
							this.showCompletePurchBtn = false;
						}

						this.showAddRegBtn = false;
						this.showAddJoinUsBtn = false;
						this.showRemoveRegBtn = false;
						this.showBuyYourSelfBtn = false;
						this.showBuyAsGiftBtn = false;
						this.showCloseBtn = false;
						this.showReviewExpBtn = false;
						this.showAddPhotoVideoBtn = false;
						this.showSendThankYouBtn = false;
						this.showAddPhotoDeviceBtn = false;
						this.showAddVideoDeviceBtn = false;
						this.showRedeemScheduleBtn = false;
						this.showExchangeBtn = true;
						this.showViewPrintBtn = false;
						this.showScheduleExpBtn = false;
						this.showRedeemNowBtn = false;
						this.showExcConfirmBtn = false;
						this.showExcCancelBtn = false;
						this.showBackBtn = false;
						this.showCheckoutBtn = false;
						this.showContinueShopBtn = false;
						this.showViewAvaibalityExpBtn = false;
						this.showScheduleExpBtn = false;
						this.redeemScheduleBlock = false;
						this.onlineScheduleBlock = false;
						this.onlineBookingMsgBlock = false;
						this.onlineBookingRejectBlock = false;

						if (this.experienceDetails.exchange_request == 1) {
							this.addExchangeInfo();
						}


					} else if (this.experienceDetails.experience_status == 5) {
						/* SHOW/HIDE TABS */
						this.showDetailsTab = true;
						this.showExchangeTab = false;
						this.showGiftedByTab = true;
						this.showPhotosVideosTab = true;
						this.showSummeryTab = false;
						this.showHowToRedeemTab = false;
						this.showPartialPurchTab = false;
						this.showReviewTab = false;
						this.showJoinUsTab = false;

						/* SHOW/HIDE BUTTON */
						this.showRemoveRegBtn = false;
						this.showAddJoinUsBtn = false;
						this.showAddRegBtn = false;
						this.showBuyYourSelfBtn = false;
						this.showBuyAsGiftBtn = false;
						this.showCloseBtn = false;
						this.showReviewExpBtn = true;
						this.showAddPhotoVideoBtn = true;

						if (this.experienceDetails.require_thank_you_email == 1) {
							this.showSendThankYouBtn = true;
						} else {
							this.showSendThankYouBtn = false;
						}

						this.showAddPhotoDeviceBtn = false;
						this.showAddVideoDeviceBtn = false;
						this.showRedeemScheduleBtn = false;
						this.showExchangeBtn = false;
						this.showViewPrintBtn = false;
						this.showScheduleExpBtn = false;
						this.showRedeemNowBtn = false;
						this.showExcConfirmBtn = false;
						this.showExcCancelBtn = false;
						this.showCompletePurchBtn = false;
						this.showBackBtn = false;
						this.showCheckoutBtn = false;
						this.showContinueShopBtn = false;
						this.showEwalletBalance = false;

						this.priceBlock = true;
						this.exchangeBlock = false;
						this.redeemScheduleBlock = false;
						this.onlineScheduleBlock = false;
						this.onlineBookingMsgBlock = false;
						this.onlineBookingRejectBlock = false;
						this.reviewRatingBlock = false;
						this.redeemedBlock = true;
						this.exchangedBlock = false;
						this.show_fareharbor = false;
						this.isActiveTab = 'detail';
						this.showViewAvaibalityExpBtn = false;
						this.showScheduleExpBtn = false;
						this.show_instant_booking = false;
					} else if (this.experienceDetails.experience_status == 8) {
						/* SHOW/HIDE TABS */
						this.showDetailsTab = true;
						this.showExchangeTab = false;
						this.showGiftedByTab = true;
						this.showPhotosVideosTab = false;
						this.showSummeryTab = true;
						this.showHowToRedeemTab = false;
						this.showPartialPurchTab = false;
						this.showReviewTab = false;
						this.showJoinUsTab = false;

						/* SHOW/HIDE BUTTON */
						this.showRemoveRegBtn = false;
						this.showAddRegBtn = false;
						this.showAddJoinUsBtn = false;
						this.showBuyYourSelfBtn = false;
						this.showBuyAsGiftBtn = false;
						this.showCloseBtn = false;
						this.showReviewExpBtn = false;
						this.showAddPhotoVideoBtn = false;
						this.showSendThankYouBtn = false;
						this.showAddPhotoDeviceBtn = false;
						this.showAddVideoDeviceBtn = false;
						this.showRedeemScheduleBtn = false;
						this.showExchangeBtn = false;
						this.showViewPrintBtn = false;
						this.showScheduleExpBtn = false;
						this.showRedeemNowBtn = false;
						this.showExcConfirmBtn = false;
						this.showExcCancelBtn = false;
						this.showCompletePurchBtn = false;
						this.showBackBtn = false;
						this.showCheckoutBtn = false;
						this.showContinueShopBtn = false;
						this.showEwalletBalance = false;

						this.priceBlock = true;
						this.exchangeBlock = false;
						this.redeemScheduleBlock = false;
						this.onlineScheduleBlock = false;
						this.onlineBookingMsgBlock = true;
						this.onlineBookingRejectBlock = false;
						this.reviewRatingBlock = false;
						this.redeemedBlock = false;
						this.exchangedBlock = false;
						this.show_fareharbor = false;
						this.isActiveTab = 'detail';
						this.showViewAvaibalityExpBtn = false;
						this.showScheduleExpBtn = false;
						this.show_instant_booking = false;
					} else if (this.experienceDetails.experience_status == 9) {
						/* SHOW/HIDE TABS */
						this.showDetailsTab = true;
						this.showExchangeTab = false;
						this.showGiftedByTab = true;
						this.showPhotosVideosTab = false;
						this.showSummeryTab = true;
						this.showHowToRedeemTab = false;
						this.showPartialPurchTab = false;
						this.showReviewTab = false;
						this.showJoinUsTab = false;

						/* SHOW/HIDE BUTTON */
						this.showRemoveRegBtn = false;
						this.showAddRegBtn = false;
						this.showAddJoinUsBtn = false;
						this.showBuyYourSelfBtn = false;
						this.showBuyAsGiftBtn = false;
						this.showCloseBtn = false;
						this.showReviewExpBtn = false;
						this.showAddPhotoVideoBtn = false;
						this.showSendThankYouBtn = false;
						this.showAddPhotoDeviceBtn = false;
						this.showAddVideoDeviceBtn = false;
						this.showRedeemScheduleBtn = false;
						this.showExchangeBtn = false;
						this.showViewPrintBtn = false;
						this.showScheduleExpBtn = false;
						this.showRedeemNowBtn = false;
						this.showExcConfirmBtn = false;
						this.showExcCancelBtn = false;
						this.showCompletePurchBtn = false;
						this.showBackBtn = false;
						this.showCheckoutBtn = false;
						this.showContinueShopBtn = false;
						this.showEwalletBalance = false;

						this.priceBlock = true;
						this.exchangeBlock = false;
						this.redeemScheduleBlock = false;
						this.onlineScheduleBlock = false;
						this.onlineBookingMsgBlock = true;
						this.onlineBookingRejectBlock = false;
						this.reviewRatingBlock = false;
						this.redeemedBlock = false;
						this.exchangedBlock = false;
						this.show_fareharbor = false;
						this.isActiveTab = 'detail';
						this.showViewAvaibalityExpBtn = false;
						this.showScheduleExpBtn = false;
						this.show_instant_booking = false;
					} else if (this.experienceDetails.experience_status == 10) {
						if (this.experienceDetails.instant_book != 1) {
							/* SHOW/HIDE TABS */
							this.showDetailsTab = true;
							this.showExchangeTab = false;
							this.showGiftedByTab = true;
							this.showPhotosVideosTab = true;
							this.showSummeryTab = false;
							this.showHowToRedeemTab = false;
							this.showPartialPurchTab = false;
							this.showReviewTab = false;
							this.showJoinUsTab = false;

							/* SHOW/HIDE BUTTON */
							//this.showRedeemNowBtn = true;
							//provider_booking_type
							if (this.experienceDetails.experience_booking_type > 0) {
								this.newBookingRequestBtn = true;
								this.showRedeemNowBtn = false;
							} else {
								this.newBookingRequestBtn = false;
								this.showRedeemNowBtn = true;
							}

							this.showExchangeBtn = true;
							this.showRemoveRegBtn = false;
							this.showAddRegBtn = false;
							this.showAddJoinUsBtn = false;
							this.showBuyYourSelfBtn = false;
							this.showBuyAsGiftBtn = false;
							this.showCloseBtn = false;
							this.showReviewExpBtn = false;
							this.showAddPhotoVideoBtn = false;
							this.showSendThankYouBtn = false;
							this.showAddPhotoDeviceBtn = false;
							this.showAddVideoDeviceBtn = false;
							this.showViewPrintBtn = false;
							this.showScheduleExpBtn = false;

							this.showExcConfirmBtn = false;
							this.showExcCancelBtn = false;
							this.showCompletePurchBtn = false;
							this.showBackBtn = false;
							this.showCheckoutBtn = false;
							this.showContinueShopBtn = false;
							this.showEwalletBalance = false;

							this.priceBlock = true;
							this.exchangeBlock = false;
							this.redeemScheduleBlock = false;
							this.onlineScheduleBlock = false;
							this.onlineBookingMsgBlock = true;
							this.onlineBookingRejectBlock = true;
							this.reviewRatingBlock = false;
							this.redeemedBlock = false;
							this.exchangedBlock = false;
							this.show_fareharbor = false;
							this.isActiveTab = 'detail';
							this.showViewAvaibalityExpBtn = false;
							this.showScheduleExpBtn = false;
							this.show_instant_booking = false;
						} else {
                            //provider_booking_type
							if (this.experienceDetails.experience_booking_type > 0) {
								/* SHOW/HIDE TABS */
								this.showDetailsTab = true;
								this.showExchangeTab = false;
								this.showGiftedByTab = true;
								this.showPhotosVideosTab = false;
								this.showSummeryTab = true;
								this.showHowToRedeemTab = true;
								this.showPartialPurchTab = false;
								this.showReviewTab = false;
								this.showJoinUsTab = false;
								this.redeemedBlock = false;

								/* SHOW/HIDE BUTTON */
								this.showRemoveRegBtn = false;
								this.showAddRegBtn = false;
								this.showAddJoinUsBtn = false;
								this.showBuyYourSelfBtn = false;
								this.showBuyAsGiftBtn = false;
								this.showCloseBtn = false;
								this.showReviewExpBtn = false;
                                //provider_booking_type
								if (this.experienceDetails.experience_booking_type == 1) {
									this.request_to_book = true;
								} else {
									this.request_to_book = false;
								}

								if (this.experienceDetails.experience_type == 3) {
									this.showAddPhotoVideoBtn = true;
									this.showSendThankYouBtn = true;

									this.showGiftedByTab = true;
									this.showPhotosVideosTab = true;
									this.showSummeryTab = false;
									this.showHowToRedeemTab = false;
									this.showExchangeBtn = false;
								} else {
									this.showAddPhotoVideoBtn = false;
									this.showSendThankYouBtn = false;
									this.showExchangeBtn = false;
								}

								this.showAddPhotoDeviceBtn = false;
								this.showAddVideoDeviceBtn = false;
								this.showRedeemScheduleBtn = false;
								this.showViewPrintBtn = false;
								this.showScheduleExpBtn = false;
								this.showRedeemNowBtn = false;
								this.showExcConfirmBtn = false;
								this.showExcCancelBtn = false;
								this.showCompletePurchBtn = false;
								this.showBackBtn = false;
								this.showCheckoutBtn = false;
								this.showContinueShopBtn = false;
								this.showEwalletBalance = false;

								this.priceBlock = false;
								this.exchangeBlock = false;
								this.redeemScheduleBlock = false;
								this.onlineBookingMsgBlock = false;
								this.onlineBookingRejectBlock = false;
								this.reviewRatingBlock = false;
								this.exchangedBlock = false;
								this.redeemedBlock = false;
								this.show_fareharbor = false;
								this.showViewAvaibalityExpBtn = false;
								this.showScheduleExpBtn = false;
								this.show_instant_booking = false;
								this.backfromRedemmeScheduleBtn = true;
								this.onlineScheduleBlock = true;
								this.showSeasonHeadline = true;
								this.isActiveTab = 'how_to_redeem';
							} else {
								this.showDetailsTab = true;
								this.showExchangeTab = false;
								this.showGiftedByTab = true;
								this.showPhotosVideosTab = false;
								this.showSummeryTab = true;
								this.showHowToRedeemTab = true;
								this.showPartialPurchTab = false;
								this.showReviewTab = false;
								this.showJoinUsTab = false;
								this.redeemedBlock = false;

								/* SHOW/HIDE BUTTON */
								this.showRemoveRegBtn = false;
								this.showAddRegBtn = false;
								this.showAddJoinUsBtn = false;
								this.showBuyYourSelfBtn = false;
								this.showBuyAsGiftBtn = false;
								this.showCloseBtn = false;
								this.showReviewExpBtn = false;

								//alert(this.experienceDetails.experience_type);

								this.showAddPhotoVideoBtn = false;
								this.showSendThankYouBtn = false;
								this.showExchangeBtn = false;
								this.showAddPhotoDeviceBtn = false;
								this.showAddVideoDeviceBtn = false;
								this.showRedeemScheduleBtn = false;
								this.showViewPrintBtn = false;
								this.showScheduleExpBtn = false;
								this.showRedeemNowBtn = false;
								this.showExcConfirmBtn = false;
								this.showExcCancelBtn = false;
								this.showCompletePurchBtn = false;
								this.showBackBtn = false;
								this.showCheckoutBtn = false;
								this.showContinueShopBtn = false;
								this.showEwalletBalance = false;

								this.priceBlock = true;
								this.exchangeBlock = false;
								this.redeemScheduleBlock = false;
								this.onlineScheduleBlock = false;
								this.onlineBookingMsgBlock = false;
								this.onlineBookingRejectBlock = false;
								this.reviewRatingBlock = false;
								this.exchangedBlock = false;
								this.redeemedBlock = false;
								this.show_fareharbor = false;
								this.isActiveTab = 'how_to_redeem';
								this.showViewAvaibalityExpBtn = false;
								this.showScheduleExpBtn = false;
								this.show_instant_booking = false;
								this.showRedeemNowConfirm = true;
								this.showRedeemNowCancel = true;
							}

						}
					}/* else if(this.experienceDetails.experience_status == 11){
						
						this.showDetailsTab		    =	true;
						this.showExchangeTab	    =	false;
						this.showGiftedByTab	    =	true;
						this.showPhotosVideosTab    =	true;
						this.showSummeryTab 	    =	false;
						this.showHowToRedeemTab	    =	false;
						this.showPartialPurchTab    =	false;
						this.showReviewTab		    =	false;
						this.showJoinUsTab		    =	false;
						
					   
						this.showRemoveRegBtn		=	false;
						this.showAddRegBtn			=	false;
						this.showBuyYourSelfBtn		=	false;
						this.showBuyAsGiftBtn		=	false;
						this.showCloseBtn			=	false;
						this.showReviewExpBtn		=	false;
						this.showAddPhotoVideoBtn	=	false;
						this.showSendThankYouBtn	=	false;
						this.showAddPhotoDeviceBtn	=	false;
						this.showAddVideoDeviceBtn	=	false;
						this.showRedeemScheduleBtn	=	false;
						this.showExchangeBtn		=	false;
						this.showViewPrintBtn		=	false;
						this.showScheduleExpBtn		=	false;
						this.showRedeemNowBtn		=	false;
						this.showExcConfirmBtn		=	false;
						this.showExcCancelBtn		=	false;
						this.showCompletePurchBtn	=	false;
						this.showBackBtn			=	false;
						this.showCheckoutBtn		=	false;
						this.showContinueShopBtn	=	false;
						this.showEwalletBalance		=	false;
			
						this.priceBlock				=	false;
						this.exchangeBlock			=	false;
						this.redeemScheduleBlock	=	false;
						this.onlineScheduleBlock	=	false;
						this.onlineBookingMsgBlock	=	false;
						this.onlineBookingRejectBlock	=	false;
						this.reviewRatingBlock		=	false;
						this.redeemedBlock			=	false;
						this.exchangedBlock			=	false;
						this.isActiveTab			=	'detail';
					} */

				}

				this.experienceDetailsPopup.show();
				setTimeout(() => {
					window.dispatchEvent(new Event('resize'));

					if (this.videoElement) {
						this.videoElement.nativeElement.load(); // play video if exists.
					}

					if (this.isBrowser) {
						document.querySelector('#experienceDetailsPopup .modal-content').scrollTo(0, 0);
					}

				}, 500);

			} else {
				this.experienceDetails = [];
				this.additionalImages = [];
				this.booking_slot_data = [];
				this.requires_visitors_details = [];

			}

		});
		this.end_navigate_stop = true

		this.authDataMessageSubscription = this.authdataMessageService.getAuthUserDataMessage().subscribe(message => {
			this.getUserAuthDataApi(); // initialize auth user data.
		});

		this.getLocationMenuList();

		this.getCountryList()
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				//console.log("navi 222")
				if (this.isBrowser) {
					this.onWindowScroll();
				}
				if (this.isBrowser) {
					document.querySelector(".burger").classList.remove("clicked");
					document.querySelector("body").classList.remove("overflow");
					document.querySelector("html").classList.remove("overflow");
					document.querySelector(".body-content-wrapper").classList.remove("show-menu");
					document.querySelector(".mobile-collapsable").classList.remove("menu-show");
					document.querySelector(".caret-arrow").classList.remove("show_submenu");
					document.getElementById("app-overlay").hidden = true;

					ga('send', 'pageview');
					fbq('track', 'PageView');
					pintrk('page');
				}
				//alert('Navigation End');
				if (localStorage.getItem('authUser')) {

					if (localStorage.getItem('login_token')) {
						this.tokenkey = localStorage.getItem('login_token');
						this.authUser = localStorage.getItem('authUser');

						this.getUserAuthDataApi(true);	// calls function for get on time data from api by user token.

						//this.initializeAuthUserData(true); 	// calls function for initialize auth  
					}
				}
			}

			if (localStorage.getItem('cart_details') || localStorage.getItem('gift_box_details')) {
				this.cart_details = JSON.parse(localStorage.getItem('cart_details'));
				this.cartGiftBoxes = JSON.parse(localStorage.getItem('gift_box_details'));
				if ((this.cart_details && this.cart_details.length > 0) || (this.cartGiftBoxes && this.cartGiftBoxes.length > 0)) {
					this.is_cart_fill = true;
				} else {
					this.is_cart_fill = false;
				}
				this.total_sales_tax = 0;
				this.total_cart_amount = 0;
				if (this.cart_details != undefined && this.cart_details != null && this.cart_details != '') {
					for (let i = 0; i < this.cart_details.length; i++) {
						this.total_sales_tax = Number(this.total_sales_tax) + Number(this.cart_details[i].tax)
						this.total_cart_amount = this.total_cart_amount + Number((this.cart_details[i].line_total * this.cart_details[i].qty))

					}
				}
				if (this.cartGiftBoxes != undefined && this.cartGiftBoxes != null && this.cartGiftBoxes != '') {
					for (let i = 0; i < this.cartGiftBoxes.length; i++) {
						this.total_sales_tax = Number(this.total_sales_tax) + Number(this.cartGiftBoxes[i].tax)
						this.total_cart_amount = this.total_cart_amount + Number(this.cartGiftBoxes[i].line_total * this.cartGiftBoxes[i].qty);
					}
				}
				this.total_cart_amount = (this.total_cart_amount + this.total_sales_tax).toFixed(2);
			}
			this.show_card_details = false;
			this.currentURL = this.router.url.split("/")[1];


		});


		this.authDataMessageProfilePicSubscription = this.authdataMessageService.getProfilePicMessage().subscribe(message => {
			if (message !== 'false') {
				this.profile_img_url = message;
				this.showUploadProfileText = false;
			}
		});


		this.walletAmountSubscription = this.messageService.getAmountMessage().subscribe(message => {
			if (message !== 'false') {
				this.wallet_balance = parseFloat(message).toFixed(2);
			}
		});

		this.addExpCountSubscription = this.messageService.getTotalMessage().subscribe(message => {
			//console.log('Mahi', message);
			if (message !== 'false') {
				/** If Gift voucher amount added then show congratulation Popup work starts **/
				if (message == 'show_first_time_popup') {
					this.show_congratulation = false;
					if (this.authUser.gift_voucher_received == 0) {
						this.congratulationPopup.show();
					}
					localStorage.removeItem('show_first_time_reg_popup');
				} else {
					this.totalAdded = message.total_exp_count;
					//alert(this.totalAdded);
					//alert(message.gift_add);
					if (typeof message.gift_add != "undefined" && message.gift_add == 1) {

						this.showCountDownText = false;
						this.show_congratulation = true;

						this.congratulationPopup.show();
						this.authUser.gift_voucher_received = 1;

						var updateAuthUser = JSON.parse(localStorage.getItem("authUser"));
						updateAuthUser.gift_voucher_received = 1;
						localStorage.setItem("authUser", JSON.stringify(updateAuthUser));

					}
				}
				/** If Gift voucher amount added then show congratulation Popup work ends **/
			}
		});

		this.getCartPaymentMsgSubscription = this.messageService.getPaymentMessage().subscribe(message => {
			if (message !== 'false') {
				if (message == '1')
					this.cart_details = JSON.parse(localStorage.getItem('cart_detail'))
			}
			if (localStorage.getItem('gift_box_details')) {
				this.cartGiftBoxes = JSON.parse(localStorage.getItem('gift_box_details'));
			}
		});


		this.getCartMessageSubscription = this.messageService.getCartMessage().subscribe(message => {
			if (message !== 'false') {
				this.cart_details = JSON.parse(message)
			}
			if (localStorage.getItem('gift_box_details')) {
				this.cartGiftBoxes = JSON.parse(localStorage.getItem('gift_box_details'));
			}
		});
		this.getLogoutMessageSubscription = this.messageService.getLogoutMessage().subscribe(message => {
			if (message !== 'false') {
				if (message == "1") {
					this.isLogin = true;
				}
				else {
					this.isLogin = false;
				}
			}
		});
		const current = new Date();
		this.todayDate = {
			year: current.getFullYear(),
			month: current.getMonth() + 1,
			day: current.getDate()
		};
		this.yesterday = new Date(current);

		this.getPhotoVideoSubscription = this.messageService.getPhotoVideo().subscribe(message => {
			if (message !== 'false') {
				this.addPhotoVideoFromDevice(message);
			}
		});

		this.getGiftCertificateSubscription = this.messageService.getGiftCertificate().subscribe(message => {
			if (message !== 'false') {
				this.certificate_data = message;
				this.buymodal.show();
			}
		});

		this.modalPopChangeSubscription = this.modelChanged.pipe(debounceTime(1000)).subscribe(searchText => {
			this.search_string = searchText;
			this.search_string = this.search_string.target.value;
			this.couple_searching(this.search_string);
		});

		//if(this.showCountDownText && this.coupleRoleID == this.authUser.role_id && this.authUser && this.authUser.gift_voucher_received == 0){
		let current_time_params = {};
		this.mainService.getCurrentTime(current_time_params).subscribe(res => {
			this.timerStartDate = res.date_time;
		});
		//}

		// FbAlbumSubscription
		this.FbAlbumSubscription = this.messageService.getPhotoUpdateProcess().subscribe(message => {
			this.uploadFromFacebook(message);
		});



	}

	@HostListener('window:popstate', ['$event'])
	onPopState(event) {
		this.experieneDetailPopupClose();
	}

	getUserAuthDataApi(navigation = false) {
		const $this = this;
		let requestBody = {};
		if (this.tokenkey != '') {
			this.userProfileService.get_auth_user_data(requestBody, this.tokenkey).subscribe(res => {
				if (res.response == "success") {
					if (res.data.gift_voucher_received == 1) {
						this.showCountDownText = false;
					}
					localStorage.setItem('base_img_url', res.base_url);
					localStorage.setItem('authUser', JSON.stringify(res.data));
					this.initializeAuthUserData();
				} else {
					this.logout();
				}
			});
		}
	}

	initializeAuthUserData(fromNavigation = false) {
		//alert('Navigating');
		if (localStorage.getItem('authUser')) {
			this.authUser = JSON.parse(localStorage.getItem('authUser'));
			this.authCheck = true;
			this.isLogin = true;
			this.showCountDownText = false;
			this.getWalletAmount();

			this.couple_name = this.authUser.first_name + ' & ' + this.authUser.partner_first_name;
			this.tokenkey = localStorage.getItem('login_token');

			if (this.authUser.profile_image !== '' && this.authUser.profile_image !== null && this.authUser.profile_image !== undefined) {
				this.profile_img_url = localStorage.getItem('base_img_url') + this.authUser.profile_image;
				this.showUploadProfileText = false;
			} else {
				this.showUploadProfileText = true;
				this.profile_img_url = 'assets/images/option_img.png';

			}

			if (this.authUser.cover_image !== '' && this.authUser.cover_image !== null && this.authUser.cover_image !== undefined) {
				this.cover_image_url = localStorage.getItem('base_img_url') + this.authUser.cover_image;
			} else {
				this.cover_image_url = 'assets/images/cover_image.jpg';
			}

			if ([1, 4, 5].includes(this.authUser.role_id)) {
				this.showAddExpBtns = true;
			}

			/** Timer Process if user is couple **/
			if (this.authUser.role_id == 1) {
				if (this.authUser.gift_voucher_received == 1) {
					this.showCountDownText = false;
				} else {
					//console.log('Going to show timer');
					this.showCountDownTimer();
				}
			}
		} else {
			this.profile_img_url = 'assets/images/option_img.png';
			this.cover_image_url = 'assets/images/cover_image.jpg';
			this.showCountDownText = false;
			this.timerEndDate = "";
			this.isLogin = false;
			this.authCheck = false;
			this.showUploadProfileText = false;
		}

	}

	formatDate(date) {
		var d = new Date(date);
		var year = "" + d.getFullYear();
		var month = "" + (d.getMonth() + 1); if (month.length == 1) { month = "0" + month; }
		var day = "" + d.getDate(); if (day.length == 1) { day = "0" + day; }
		var hour = "" + d.getHours(); if (hour.length == 1) { hour = "0" + hour; }
		var minute = "" + d.getMinutes(); if (minute.length == 1) { minute = "0" + minute; }
		var second = "" + d.getSeconds(); if (second.length == 1) { second = "0" + second; }

		return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
	}

	ngOnDestroy() {
		if (this.subscription != undefined && this.subscription != null) {
			this.subscription.unsubscribe();
		}
		if (this.experienceDetailSubscription != undefined && this.experienceDetailSubscription != null) {
			this.experienceDetailSubscription.unsubscribe();
		}
		if (this.FbAlbumSubscription != undefined && this.FbAlbumSubscription != null) {
			this.FbAlbumSubscription.unsubscribe();
		}
		if (this.modalPopChangeSubscription != undefined && this.modalPopChangeSubscription != null) {
			this.modalPopChangeSubscription.unsubscribe();
		}
		if (this.addExpCountSubscription != undefined && this.addExpCountSubscription != null) {
			this.addExpCountSubscription.unsubscribe();
		}
		if (this.walletAmountSubscription != undefined && this.walletAmountSubscription != null) {
			this.walletAmountSubscription.unsubscribe();
		}
		if (this.authDataMessageProfilePicSubscription != undefined && this.authDataMessageProfilePicSubscription != null) {
			this.authDataMessageProfilePicSubscription.unsubscribe();
		}
		if (this.authDataMessageSubscription != undefined && this.authDataMessageSubscription != null) {
			this.authDataMessageSubscription.unsubscribe();
		}
		if (this.getCartPaymentMsgSubscription != undefined && this.getCartPaymentMsgSubscription != null) {
			this.getCartPaymentMsgSubscription.unsubscribe();
		}
		if (this.getCartMessageSubscription != undefined && this.getCartMessageSubscription != null) {
			this.getCartMessageSubscription.unsubscribe();
		}
		if (this.getLogoutMessageSubscription != undefined && this.getLogoutMessageSubscription != null) {
			this.getLogoutMessageSubscription.unsubscribe();
		}
		if (this.getPhotoVideoSubscription != undefined && this.getPhotoVideoSubscription != null) {
			this.getPhotoVideoSubscription.unsubscribe();
		}
		if (this.getGiftCertificateSubscription != undefined && this.getGiftCertificateSubscription != null) {
			this.getGiftCertificateSubscription.unsubscribe();
		}

	}

	ngAfterViewInit() {
		// this.congratulationPopup.show();

		if (this.isBrowser) {

			document.querySelector(".burger").addEventListener("click", (e) => {
				menuToggle();
			});

			document.querySelector(".overlay").addEventListener("click", (e) => {
				menuToggle();
			});




			function menuToggle() {
				document.querySelector(".burger").classList.toggle("clicked");
				document.querySelector("body").classList.toggle("overflow");
				document.querySelector("html").classList.toggle("overflow");
				document.getElementById("header").classList.toggle("stickyScrolHead");
				document.querySelector(".body-content-wrapper").classList.toggle("show-menu");
				document.querySelector(".mobile-collapsable").classList.toggle("menu-show");
				document.querySelector(".caret-arrow").classList.remove("show_submenu");

				document.getElementById("app-overlay").hidden = true;
				if (document.querySelector("body").classList.contains("overflow")) {
					document.getElementById("app-overlay").hidden = false;
				}
				if (document.getElementById("header").classList.contains("is-sticky")) {
					document.getElementById("header").classList.add("awake");
				}



			}

			var caretArrow = document.querySelector(".caret-arrow");

			caretArrow.addEventListener("click", (e) => {
				var target = getTargetElement(e);
				document.querySelector(".caret-arrow").classList.toggle("show_submenu");
			});

			function getTargetElement(e) {

				e = e || window.event;
				return e.target || e.srcElement;

			}
			// var closeTimeBtn = document.querySelector(".popover-close");
			// closeTimeBtn.addEventListener("click", (e) => {
			// 	document.querySelector(".countheadHover").classList.toggle("hideCountHover");
			// 	console.log("hides")
			// })

		}


	}


	@HostListener("window:scroll", [])
	onWindowScroll() {
		if (this.isBrowser) {
			// do some stuff here when the window is scrolled
			const verticalOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

			var navbar = document.getElementById("header");
			var HeadH = document.getElementById("header").clientHeight + 50;
			var scrollShow = document.getElementById("scroll_top");

			if (verticalOffset < HeadH) {
				document.getElementById("vebo_body").style.paddingTop = "0";
				navbar.classList.remove('is-sticky');
			}
			if (verticalOffset > HeadH) {
				if (!navbar.classList.contains("awake")) {
					document.getElementById("vebo_body").style.paddingTop = (HeadH - 45) + "px";
					navbar.classList.add('is-sticky');
				}
			}
			if (verticalOffset > HeadH * 2.012) {
				if (!navbar.classList.contains("awake")) {
					navbar.classList.add('awake');
				}
				scrollShow.classList.add('showScroll');
			}
			if (verticalOffset < HeadH * 2.012) {
				if (navbar.classList.contains("awake")) {
					navbar.classList.remove('awake');
				}
				scrollShow.classList.remove('showScroll');
			}
		}
	}

	navbarHeight() {
		if (this.isBrowser) {
			if (window.innerWidth <= 991) {
				var HeadNav = document.getElementById("mobile-collapsable");
				HeadNav.style.height = window.innerHeight + "px";

				var elems = document.querySelectorAll(".dropdown-menu.collapse");
				[].forEach.call(elems, function (el) {
					el.classList.remove("dropdown-menu");
				});
			}
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.navbarHeight();
		if (event.target.innerWidth < 768) {
			this.is_mobile_view = true;
		} else {
			this.is_mobile_view = false;
		}
	}

	ngOnInit() {
		/* if(localStorage.getItem('authUser')){
			this.authUser = JSON.parse(localStorage.getItem('authUser'));
			console.log(this.authUser);
			this.showCountDownTimer();
		} */
		this.navbarHeight();

		//this.experienceDetailsPopup.hide();


		this.experience.western_us().subscribe(res => {
			if (res.response == 'success') {
				this.experienceEasternData = res.data;
				this.messageService.sendlocationMessage(this.experienceEasternData)
			}
		});

		this.ThankyouProcessForm = this.formBuilder.group({
			additional_emails: [''],
			thanku_text: [''],
			thank_you_email: ['']
		});

		this.PhotoVideoShareForm = this.formBuilder.group({
			description: [''],
			hash_tag: [''],
			image_data: [''],
		});

		/* this.OnlineSchedulingForm = this.formBuilder.group({
			requested_date: [''],
			couple_email: [''],		 
			couple_message: [''],
		}); */
		this.form_data();
		let category_slug_for_gift_ideas = { 'category_for': 'experience', 'type': 'gift-ideas' };
		this.mainService.get_categories(category_slug_for_gift_ideas).subscribe(res => {
			this.categoryList_for_gift_ideas = res.data;
		});
		let category_slug_for_experience = { 'category_for': 'experience', 'type': 'higher-level-category' };
		this.mainService.get_categories(category_slug_for_experience).subscribe(res => {
			this.categoryList_for_experiece = res.data;

		});
		this.joinUsCartForm = this.formBuilder.group({
			joinusNamesDetails: this.formBuilder.array([]),
		});

	}
	get formCtrl() {
		return this.OnlineSchedulingForm.controls;
	}

	form_data() {
		if (this.experienceDetails.provider_require_phone == 0) {
			this.OnlineSchedulingForm = this.formBuilder.group({
				requested_date: [''],
				name: ['', [Validators.required]],
				phone: [''],
				couple_email: [''],
				couple_message: [''],
				booking_type: [''],
				display_book_number: [''],
				variant_id: [''],
				peek_unit_id: [''],
				book_number: [''],
				people_count: [''],
				product_code: [''],
				time_options: [''],
				price: [''],
				seat_label: [''],
				serialize_data: [''],
				hotel_pk: ['0'],
				hornblower_category: [''],
			});
		}
		else {
			this.OnlineSchedulingForm = this.formBuilder.group({
				requested_date: [''],
				name: ['', [Validators.required]],
				phone: ['', [Validators.required]],
				couple_email: [''],
				couple_message: [''],
				booking_type: [''],
				display_book_number: [''],
				variant_id: [''],
				book_number: [''],
				peek_unit_id: [''],
				people_count: [''],
				product_code: [''],
				time_options: [''],
				price: [''],
				seat_label: [''],
				serialize_data: [''],
				hotel_pk: ['0'],
				hornblower_category: [''],
			});
		}
	}

	owlExpDetail: OwlOptions = {
		loop: false,
		mouseDrag: false,
		autoplay: true,
		touchDrag: true,
		pullDrag: false,
		dots: false,
		autoWidth: true,
		navSpeed: 700,
		navText: ['1', '2'],
		nav: false,
		items: 1,
		rewind: true,
		margin: 30

	}

	toggleMenu() {
		this.isMenuOpen = !this.isMenuOpen;
	}



	logout() {
		this.isLoading = true;
		localStorage.removeItem('authUser');
		localStorage.removeItem('remember_me');
		localStorage.removeItem('profile_img_url')
		localStorage.removeItem('login_token')
		localStorage.removeItem('cart_details');
		localStorage.removeItem('gift_box_details');

		this.cart_details = [];
		this.cartGiftBoxes = [];
		this.show_card_details = false;
		this.is_cart_fill = false;

		this.authUser = "";
		// localStorage.removeItem('accepted')
		this.authCheck = false;
		this.isLogin = false;
		this.showCountDownText = false;
		setTimeout(() => {
			this.isLoading = false;
			this.showCountDownText = false;
			//window.location.href = '/login'
			this.router.navigate(['/login']);
		}, 1000);
	}

	locationLowercase(state) {
		return '/location/' + state.toLowerCase()
	}

	getCountryList() {
		this.addexperience.getCountryList().subscribe(res => {
			this.countryList = res.data;
		});
	}

	getLocationMenuList() {
		this.addexperience.getLocationMenuList().subscribe(res => {
			this.locationList = res.data;
			this.locationAutocompleteList = res.locations;
		});
	}

	removeItem(product_id, index) {

		var childRemove = false;

		if (this.cart_details[index]) {

			if (this.cart_details[index].parent_id != "undefined" && this.cart_details[index].parent_id > 0) {
				childRemove = true;
			}
			this.cart_details.splice(index, 1);

		}

		/** Remove JoinUs items if main item is removed from cart work starts **/
		if (this.cart_details.length > 0) {
			if (!childRemove) {

				for (let j = 0; j < this.cart_details.length; j++) {

					if (this.cart_details[j] && this.cart_details[j] != "undefined" && typeof this.cart_details[j].parent_id != "undefined" && this.cart_details[j].parent_id == product_id) {
						//this.cart_details.splice(j, 1);
						delete this.cart_details[j];
					}
				}

				this.cart_details = this.cart_details.filter(function (element) {
					return element != "undefined";
				});
			}
		}
		/** Remove JoinUs items if main item is removed from cart work ends **/

		if (this.cart_details.length > 0) {
			localStorage.setItem('cart_details', JSON.stringify(this.cart_details));
		} else {
			localStorage.removeItem('cart_details')
		}

		this.calculateCartValues(); // calls function for update cart values.

		if (this.isBrowser) {
			var element = document.querySelector("body");
			var htmlelement = document.querySelector("html")
			if (element) {
				element.classList.add('overflow');
			}
			if (htmlelement) {
				htmlelement.classList.add('overflow');
			}
		}

	}

	calculateCartValues() {
		this.total_sales_tax = 0;
		this.total_cart_amount = 0;
		for (let i = 0; i < this.cart_details.length; i++) {
			this.total_sales_tax = Number(this.total_sales_tax) + Number(this.cart_details[i].tax)
			this.total_cart_amount = this.total_cart_amount + Number(this.cart_details[i].line_total * this.cart_details[i].qty);
		}
		if (this.cartGiftBoxes != null) {
			for (let i = 0; i < this.cartGiftBoxes.length; i++) {
				this.total_sales_tax = Number(this.total_sales_tax) + Number(this.cartGiftBoxes[i].tax)
				this.total_cart_amount = this.total_cart_amount + Number(this.cartGiftBoxes[i].line_total * this.cartGiftBoxes[i].qty);
			}
		}
		this.total_cart_amount = (this.total_cart_amount + this.total_sales_tax).toFixed(2);
	}

	removeGiftItem(product_id) {
		for (let i = 0; i < this.cartGiftBoxes.length; i++) {
			if (product_id == this.cartGiftBoxes[i].product_id) {
				this.cartGiftBoxes.splice(i, 1);
				break;
			}
		}
		if (this.cartGiftBoxes.length > 0) {
			localStorage.setItem('gift_box_details', JSON.stringify(this.cartGiftBoxes));
		} else {
			localStorage.removeItem('gift_box_details')
		}
		this.total_sales_tax = 0;
		this.total_cart_amount = 0;
		if (this.cart_details != null) {
			for (let i = 0; i < this.cart_details.length; i++) {
				this.total_sales_tax = Number(this.total_sales_tax) + Number(this.cart_details[i].tax)
				this.total_cart_amount = this.total_cart_amount + Number(this.cart_details[i].line_total * this.cart_details[i].qty);
			}
		}
		for (let i = 0; i < this.cartGiftBoxes.length; i++) {
			this.total_sales_tax = Number(this.total_sales_tax) + Number(this.cartGiftBoxes[i].tax)
			this.total_cart_amount = this.total_cart_amount + Number(this.cartGiftBoxes[i].line_total * this.cartGiftBoxes[i].qty);
		}
		this.total_cart_amount = (this.total_cart_amount + this.total_sales_tax).toFixed(2);
	}

	getCartDetail() {
		this.toggleMenu()

		this.show_card_details = !this.show_card_details;
		if (this.show_card_details == true) {

			if (this.isBrowser) {
				var element = document.querySelector("body");
				var htmlelement = document.querySelector("html")
				if (element) {
					element.classList.add('overflow');
				}
				if (htmlelement) {
					htmlelement.classList.add('overflow');
				}
			}

			this.cart_details = [];
			this.cartGiftBoxes = [];
			if (localStorage.getItem('cart_details') || localStorage.getItem('gift_box_details')) {
				this.cart_details = JSON.parse(localStorage.getItem('cart_details'));
				this.cartGiftBoxes = JSON.parse(localStorage.getItem('gift_box_details'));
				if ((this.cart_details && this.cart_details.length > 0) || (this.cart_details && this.cartGiftBoxes.length > 0)) {
					this.is_cart_fill = true;
				} else {
					this.is_cart_fill = false;
				}
				this.total_sales_tax = 0;
				this.total_cart_amount = 0;
				if (this.cart_details != undefined && this.cart_details != null && this.cart_details != '') {
					for (let i = 0; i < this.cart_details.length; i++) {
						this.total_sales_tax = Number(this.total_sales_tax) + Number(this.cart_details[i].tax)
						this.total_cart_amount = this.total_cart_amount + Number(this.cart_details[i].line_total * this.cart_details[i].qty);
					}
				}
				if (this.cartGiftBoxes != undefined && this.cartGiftBoxes != null && this.cartGiftBoxes != '') {
					for (let i = 0; i < this.cartGiftBoxes.length; i++) {
						this.total_sales_tax = Number(this.total_sales_tax) + Number(this.cartGiftBoxes[i].tax)
						this.total_cart_amount = this.total_cart_amount + Number(this.cartGiftBoxes[i].line_total * this.cartGiftBoxes[i].qty);
					}
				}
				this.total_cart_amount = (this.total_cart_amount + this.total_sales_tax).toFixed(2);

			}

			/** Work for cart not opening in mobile screen starts **/
			if (this.isBrowser) {
				setTimeout(function () {
					var popupWrapElement = document.querySelector(".cartpopup-wrap");
					if (popupWrapElement) {
						popupWrapElement.classList.add('active');
					}
				}, 100);
			}

			/** Work for cart not opening in mobile screen starts **/

		}
	}
	get voucherF() { return this.joinUsCartForm.controls; }

	showAddCartJoinUsPopup(experience) {
		this.totalJoinusPrice = 0;
		this.joinUsStep1 = true;
		this.joinUsStep2 = false;
		this.joinUsStep3 = false;
		this.joinUsStep4 = false;
		if (experience.added_registry_type == 'experiences_join_us') {
			var requestbody = { 'experience_id': experience.id, 'request_page': this.other_page_data };
			this.addexperience.getExperienceDetails(requestbody, this.tokenkey).subscribe(res => {
				if (res.response == 'success') {
					this.joinUsExpDetails = res.data;
					this.additionalImagesJoinUs = this.joinUsExpDetails.additional_images;
				} else if (res.response == 'error') {
					// this.toastr.errorToastr(res.message, 'Error!'); 
				}
				this.isLoading = false;
			});
			var cartitems: any = [];
			if (localStorage.getItem('cart_details')) {
				cartitems = JSON.parse(localStorage.getItem('cart_details'));
			}
			let hasPresentOn = cartitems.find(
				function (el) {
					if (el.product_id === experience.id) {
						return true;
					} else {
						return false;
					}
				}
			);
			if (hasPresentOn) {
				alert("Already added in your cart")
				return;
			}
			this.addCartJoinUsPopup.show();
			//this.experienceDataPopup.hide();			
		}
	}
	openAddtocartJoinFun() {
		this.joinUsUnits = [];
		var unitLength = 4;
		var counter = 1;
		for (var j = 1; j <= unitLength; j++) {
			this.joinUsUnits.push({ 'name': counter });
			counter++;
		}
		this.joinUsStep1 = false;
		this.joinUsStep2 = true;
		this.joinUsStep3 = false;
		this.joinUsStep4 = false;
	}
	onSubmitJoinUsCart(purchase_from = "") {
		this.isLoading = true;
		this.joinUsSubmitted = true;

		if (this.joinUsCartForm.invalid) {
			this.isLoading = false;
			return;
		}
		var cartitems: any = [];
		if (localStorage.getItem('cart_details')) {
			cartitems = JSON.parse(localStorage.getItem('cart_details'));
		}
		const $this = this
		let hasPresentOn = cartitems.find(
			function (el) {
				if (el.product_id === $this.joinUsExpDetails.id) {
					return true;
				} else {
					return false;
				}
			}
		);
		if (hasPresentOn) {
			this.addCartJoinUsPopup.hide()
			alert("Already added in your cart")
			return;
		}

		this.openDialog(this.joinUsExpDetails.id, this.joinUsExpDetails.slug, this.joinUsExpDetails.title, 1, this.joinUsExpDetails.image_url, this.joinUsExpDetails.regular_price, 1, this.joinUsExpDetails.register_for_couple_veriation_id, 'full-purchase', this.joinUsExpDetails.sales_tax, 0, 0, 0, purchase_from);
		var formData = this.joinUsCartForm.get('joinusNamesDetails').value;

		for (var i = 0; i < formData.length; i++) {

			this.addCartJoinUs(this.joinUsExpDetails.id, this.joinUsExpDetails.slug, this.joinUsExpDetails.title, 1, this.joinUsExpDetails.image_url, this.joinUsExpDetails.additional_person_price, 1, this.joinUsExpDetails.register_for_couple_veriation_id, 'join-us', this.joinUsExpDetails.sales_tax, formData[i].name, purchase_from);

			this.totalJoinusPrice = Number(this.joinUsExpDetails.additional_person_price) + Number(this.totalJoinusPrice);
		}

		this.totalJoinExp = Number(this.totalJoinusPrice) + Number(this.joinUsExpDetails.regular_price);
		this.isLoading = false;

		this.cartService.sendMessage(localStorage.getItem('cart_details'));
		// this.addCartJoinUsPopup.hide();
	}

	checkJoinUsFormValidation() {
		var totalInput = this.joinUsCartForm.get('joinusNamesDetails').value.length;
		var counter = 0;
		for (var item of this.joinUsCartForm.get('joinusNamesDetails').value) {
			if (item.name.trim().length > 0) {
				counter++;
			}
		}

		if (counter >= totalInput) {
			this.joinUsFormSubmitEnable = true;
		}
	}

	purchaseUnits(event, unit, partial_price, className) {

		if (className == 'remaingPartialPurchaseUnits') {
			this.totalAmount = (unit * partial_price).toFixed(2);
			this.purchaseUnit = unit;
			this.disableCartBtn = false;
			this.selectedUnit = unit;  // don't forget to update the model here
		}

	}

	joinUsUnitChange(event, unit) {
		this.joinUsSelectedUnit = unit;
		this.disableCartBtn = false;
		this.selectedUnit = unit;  // don't forget to update the model here
	}

	joinUsStep2Click() {

		this.joinUsCartForm = this.formBuilder.group({
			joinusNamesDetails: this.formBuilder.array([]),
		});

		var vcontrol = <FormArray>this.joinUsCartForm.controls['joinusNamesDetails'];
		for (let i = 0; i < this.joinUsSelectedUnit; i++) {
			let form = this.formBuilder.group({
				name: ["", Validators.required]
			});
			vcontrol.push(form);
		}

		this.joinUsStep1 = false;
		this.joinUsStep2 = false;
		this.joinUsStep4 = false;
		this.joinUsStep3 = true;

	}

	addCartJoinUs(id, slug, title, qty, image, price, is_register_for_couple, register_for_couple_veriation_id, type, sales_tax, name, purchase_from = "") {
		if (is_register_for_couple == 1) {

			var taxValue = ((price * qty) * sales_tax / 100);

			this.purchaseExpID = id;
			this.purchaseExpSlug = slug;
			this.purchaseExpTitle = title;
			this.purchaseExpQuantity = qty;
			this.purchaseExpImage = image;
			this.purchaseExpPrice = price;
			this.purchaseExpType = type;
			this.purchaseExpVerID = register_for_couple_veriation_id;
			this.sales_tax = taxValue;
			this.purchaseExpVerID = register_for_couple_veriation_id;
			this.joinUsName = name;
			let pusheditems = {};
			var cartitems: any = [];
			if (localStorage.getItem('cart_details')) {
				cartitems = JSON.parse(localStorage.getItem('cart_details'));
			}

			if (localStorage.getItem('registry_referrals')) {
				pusheditems['registry_referrals'] = localStorage.getItem('registry_referrals');
				if (localStorage.getItem('rcid')) {
					pusheditems['rcid'] = localStorage.getItem('rcid');
				}
			}
			pusheditems['voucher_name'] = this.joinUsName
			pusheditems['qty'] = this.purchaseExpQuantity;
			pusheditems['variation_id'] = this.purchaseExpVerID;
			pusheditems['tax'] = this.sales_tax;
			pusheditems['option'] = type;
			pusheditems['product_id'] = this.purchaseExpID;
			pusheditems['title'] = this.purchaseExpTitle;
			pusheditems['slug'] = this.purchaseExpSlug;
			pusheditems['image'] = this.purchaseExpImage;
			pusheditems['type'] = this.purchaseExpType;
			pusheditems['line_total'] = this.purchaseExpPrice;
			pusheditems['is_added_registries'] = 0;
			pusheditems['keep_it_secrate']	= 0;
			pusheditems['join_us_name'] = this.joinUsName;
			pusheditems['purchased_from'] = purchase_from;
			pusheditems['parent_id'] = this.purchaseExpID;

			if (this.is_guest_view && this.guest_user_data) {
				pusheditems['user_id'] = this.guest_user_data.id;
				pusheditems['purchase_type'] = 'find_registry';
				pusheditems['is_added_registries'] = 1;
				pusheditems['keep_it_secrate']	= 	(new Date(this.user_data.wedding_date).getTime() > new Date().getTime()) ? 1 : 0;

			} else if (this.authUser) {
				pusheditems['user_id'] = this.authUser.id;
				pusheditems['is_added_registries'] = 0;
				pusheditems['keep_it_secrate']	= 	(new Date(this.user_data.wedding_date).getTime() > new Date().getTime()) ? 1 : 0;
			}

			pusheditems['purchased_from'] = purchase_from;
			this.partialPurchasePopup.hide();
			cartitems.push(pusheditems);
			if (this.joinUsStep4 == false) {
				this.cartService.sendMessage(JSON.stringify(cartitems));
			}

			localStorage.setItem('cart_details', JSON.stringify(cartitems));
			localStorage.setItem('purchase_for_user', this.username);
		}
	}
	onAddcartCheckout(purchase_from = "") {
		this.joinUsStep1 = false;
		this.joinUsStep2 = false;
		this.joinUsStep3 = false;
		this.joinUsStep4 = true
		this.onSubmitJoinUsCart(purchase_from);
	}
	requestForBooking() {
		this.submitted = true;
		this.name_error = false;
		//return;
		let name_str = this.OnlineSchedulingForm.get('name').value.toLowerCase();
		let invaild_str = this.names_arr.filter(function (txt) {
			return name_str.includes(txt);
		});
		if(this.instant_book_request == 1 || this.instant_book_request == 2){
			if (this.OnlineSchedulingForm.invalid) {
				return;
			} else if (invaild_str != "") {
				this.name_error = true;
				//alert("Please enter vaild name.");
				return;
			}else if(!this.requestedDate){
				if(!this.requestedDate) {
					alert("Please select a valid date.");
				}
				return;
			}
			if(this.instant_book_request == 1){
				if (this.slot == '') {
					if (this.no_slot_avail_message == '') {
						alert("Please select any time slot.");
					} else {
						alert(this.no_slot_avail_message);
					}
					return;
				} else if (this.OnlineSchedulingForm.get('hotel_pk').value == 0 && this.OnlineSchedulingForm.get('booking_type').value == 2 && this.hotel_data.length > 0) {
					alert("Please select hotel.");
					return;
				}

				if(this.OnlineSchedulingForm.get('booking_type').value == 5 && this.requires_visitors_details && this.requires_visitors_details.length > 0){
					let isErr = false;
					for (let index = 0; index < this.requires_visitors_details.length; index++) {
					  const visitorD = this.requires_visitors_details[index];
					  if(visitorD.value.trim() == ""){
						isErr = true;
						visitorD.err = 'This field is required.';
					  }else{
						visitorD.err = null;
					  }
					}
			
					if(isErr){
					  return;
					}
				}

			}else{
				// request to book process
			}
		}else{
			// invalid request.
			alert("Invalid Request");
			return;
		}
			
		
		this.isLoading = true;
		this.OnlineSchedulingForm.get('serialize_data').setValue(this.dynamic_option_array);
		if (this.requestedDate) {

			if (!this.show_instant_booking) {
				this.slot = "";
			}

			//let requested_Date 	=	this.datepipe.transform(this.requestedDate, 'yyyy-MM-dd');  

			var requestbody 					= 	this.OnlineSchedulingForm.value;
			requestbody.experience_id 			= 	this.experienceDetails.id;
			requestbody.requested_date 			= 	this.requestedDate;
			requestbody.slot 					= 	this.slot;
			requestbody.people_count 			= 	this.selected_people;
			requestbody.instant_book_request 	= 	this.instant_book_request;
			requestbody.voucher_code 			= 	this.experienceDetails.voucher_code;
			requestbody.visitor_data 			= 	this.requires_visitors_details;
           
			this.experienceBookingService.requestForBooking(requestbody, this.tokenkey).subscribe(res => {
				if (res.response == 'success') {
					this.backfromRedemmeScheduleBtn = false;
					this.bookingDate = res.data.bookingDate;
					this.bookingStatus = res.data.bookingStatus;
					this.bookingMessage = res.data.message;
					this.bookingShortMessage = res.data.shortMessage;

					this.experienceDetails.booking_uuid = res.data.booking_uuid;
					this.experienceDetails.booking_short_message = res.data.shortMessage;
					this.experienceDetails.booking_message = res.data.message;
					this.experienceDetails.booking_slot = this.slot;
					this.experienceDetails.experience_status_msg = res.data.bookingStatus;
					this.experienceDetails.experience_status = res.data.experience_status;
					this.experienceDetails.bookingDate = res.data.bookingDate;
					this.experienceDetails.promo_refund = res.data.promo_refund;

					//this.experienceDetails.voucher_code	=	"";
					if (typeof this.authUser != "undefined" && typeof this.authUser != null && typeof this.authUser.name != "undefined" && typeof this.authUser.name != null && this.show_user_name_input) {
						this.authUser.name = this.OnlineSchedulingForm.get('name').value;
					}

					this.onlineBookingRejectBlock = false;
					this.onlineScheduleBlock = false;
					this.onlineBookingMsgBlock = true;
					this.priceBlock = true;
					this.isLoading = false;
					this.submitted = false;

					this.messageService.sendExpStatusChangedToMainComponent({ 'exp_id': this.experienceDetails.id, 'experience_status': this.experienceDetails.experience_status, 'experience_status_msg': this.experienceDetails.experience_status_msg, 'exp_data': this.experienceDetails }); // send data to add Experience component.

					if (this.isBrowser) {
						var element = document.querySelector("body")
						var htmlelement = document.querySelector("html")
						if (element)
							element.classList.remove('overflow');
						if (htmlelement) {
							htmlelement.classList.remove('overflow');
						}
					}
					this.requestedDate = '';

				} else if (res.response == 'error') {
					if (this.isBrowser) {
						var element = document.querySelector("body")
						var htmlelement = document.querySelector("html")
						if (element)
							element.classList.remove('overflow');
						if (htmlelement) {
							htmlelement.classList.remove('overflow');
						}
					}

					this.experieneDetailPopupClose();
					this.isLoading = false;
					this.submitted = false;
					//alert(res.message);
					this.toastr.errorToastr(res.message, 'Error!');

				}
			})
		} else {
			alert("Please select the date first.");
			this.isLoading = false;
		}
	}


	cancelBooking() {
		this.submitted = true;
		this.isLoading = true;
		var requestbody = { 'experience_id': this.experienceDetails.id, 'booking_uuid': this.experienceDetails.booking_uuid, 'booking_type': this.experienceDetails.booking_type, 'voucher_code': this.experienceDetails.voucher_code };
		this.experienceBookingService.cancelBooking(requestbody, this.tokenkey).subscribe(res => {
			if (res.response == 'success') {
				if (this.experienceDetails.experience_booking_type > 0) {
					this.newBookingRequestBtn = true;
				} else {
					this.showRedeemNowBtn = true;
				}
				this.showExchangeBtn = true;
				this.onlineBookingRejectBlock = false;
				this.onlineBookingMsgBlock = false;
				this.onlineScheduleBlock = false;
				this.isLoading = false;


				this.experienceDetails.experience_status_msg = res.data.experience_status_msg;
				this.experienceDetails.experience_status = res.data.experience_status;


				this.messageService.sendExpStatusChangedToMainComponent({ 'exp_id': this.experienceDetails.id, 'experience_status': this.experienceDetails.experience_status, 'experience_status_msg': this.experienceDetails.experience_status_msg, 'exp_data': this.experienceDetails }); // send data to add Experience component.

			} else if (res.response == 'error') {
				this.experieneDetailPopupClose();
				this.isLoading = false;
				this.toastr.errorToastr(res.message, 'Error!');
			}
		});
	}

	/* SHOW/HIDE AVAILABILITY SECTIONS */
	viewScheduleExpAvailabilityInfo() {
		this.isLoading = true;
		/* SHOW/HIDE BUTTON */
		this.backfromRedemmeScheduleBtn = true;
		this.newBookingRequestBtn = false;
		this.showExchangeBtn = false;
		this.showAddRegBtn = false;
		this.showRemoveRegBtn = false;
		this.showBuyYourSelfBtn = false;
		this.showBuyAsGiftBtn = false;
		this.showCloseBtn = false;
		this.showReviewExpBtn = false;
		this.showAddPhotoVideoBtn = false;
		this.showSendThankYouBtn = false;
		this.showAddPhotoDeviceBtn = false;
		this.showAddVideoDeviceBtn = false;
		this.showRedeemScheduleBtn = false;
		this.showExchangeBtn = false;
		this.showViewPrintBtn = false;
		this.onlineScheduleBlock = true;
		this.showScheduleExpBtn = false;
		this.showViewAvaibalityExpBtn = false;
		this.showRedeemNowBtn = false;
		this.showExcConfirmBtn = false;
		this.showExcCancelBtn = false;
		this.showCompletePurchBtn = false;
		this.showBackBtn = false;
		this.showCheckoutBtn = false;
		this.showContinueShopBtn = false;

		this.priceBlock = false;
		this.exchangeBlock = false;
		this.redeemScheduleBlock = false;
		this.onlineBookingRejectBlock = false;
		this.onlineBookingMsgBlock = false;

		this.isActiveTab = 'how_to_redeem';
		if (this.experienceDetails.allow_fareharbor == 1) {
			this.request_to_book = false;
		}
		else {
			this.request_to_book = true;
		}
		this.isLoading = false;
	}

	/* FUNCTION TO ADD/REMOVE TO REGISTRY */
	addToRegistry(exp_id, type, is_suggestion = 0, registry_for = "") {
		/* this.isLoading = true;
		this.showRegChkmark = true;
		for (let i = 0; i < this.resultOurRegistry.length; i++) {
			if (this.resultOurRegistry[i].id == exp_id) {
				this.resultOurRegistry[i].is_show_ckbx = 1;
				break;
			}
		} */
		this.isLoading = true;
		this.addexperience.addExperienceRegistry(exp_id, type, this.tokenkey).subscribe(res => {
			if (res.response == 'success') {
				this.messageService.sendTotalMessage(res.data);
				if (Object.keys(this.experienceDetails).length > 0) {
					if (this.experienceDetails.id == exp_id) {
						this.experienceDetails.experience_status = res.data.is_registry_status;
						this.experienceDetails.experience_status_msg = res.data.experience_status_msg;
						this.experienceDetails.add_reg_msg_class = res.data.add_reg_msg_class;

						if (this.experienceDetails.experience_status == 1) {
							this.showRemoveRegBtn = true;
							this.showAddJoinUsBtn = false;
							this.showAddRegBtn = false;
						} else if (this.experienceDetails.experience_status == 0) {
							this.showRemoveRegBtn = false;
							if (this.experienceDetails.additional_person_price > 0) {
								this.showAddJoinUsBtn = true;
							} else {
								this.showAddJoinUsBtn = false;

							}
							this.showAddRegBtn = true;
						}
					}
				}

				this.messageService.sendExpStatusChangedToMainComponent({ 'exp_id': exp_id, 'type': type, 'is_suggestion': is_suggestion, 'registry_for': registry_for, 'is_registry_status': res.data.is_registry_status, 'experience_status': res.data.is_registry_status, 'experience_status_msg': res.data.experience_status_msg, 'add_reg_msg_class': res.data.add_reg_msg_class, 'added_registry_type': res.data.added_registry_type, 'exp_data': this.experienceDetails }); // send data to add Experience component.
				this.isLoading = false;

				this.experieneDetailPopupClose();

				if (this.isBrowser) {
					var element = document.querySelector("body");
					var htmlelement = document.querySelector("html")
					if (element) {
						element.classList.remove('overflow');
					}
					if (htmlelement) {
						htmlelement.classList.remove('overflow');
					}
				}

			} else if (res.response == 'error') {
				this.isLoading = false;
			}
			this.isLoading = false;
		});
	}

	/* SHOW/HIDE REDEEM/SCHEDULE EXPERIENCE SECTIONS */
	redeemScheduleInfo() {
		this.isLoading = true;
		this.showDetailsTab = true;
		this.showExchangeTab = false;
		this.showGiftedByTab = true;
		this.showPhotosVideosTab = false;
		this.showSummeryTab = true;
		this.showHowToRedeemTab = true;
		this.showPartialPurchTab = false;
		this.showReviewTab = false;
		this.showJoinUsTab = false;
		/* SHOW/HIDE BUTTON */
		this.showAddRegBtn = false;
		this.showRemoveRegBtn = false;
		this.showBuyYourSelfBtn = false;
		this.showBuyAsGiftBtn = false;
		this.showCloseBtn = false;
		this.showReviewExpBtn = false;
		this.showAddPhotoVideoBtn = false;
		this.showSendThankYouBtn = false;
		this.showAddPhotoDeviceBtn = false;
		this.showAddVideoDeviceBtn = false;
		this.showRedeemScheduleBtn = false;
		this.showExchangeBtn = false;
		this.showViewPrintBtn = false;
		this.showSeasonHeadline = false;

		/** If Online Scheduling available then show the availability block Starts **/
		if (this.experienceDetails.experience_booking_type > 0) {
			this.showViewAvaibalityExpBtn = false;
			this.onlineScheduleBlock = true;
			//this.showViewAvaibalityExpBtn = true;
			this.showScheduleExpBtn = false;
			this.showRedeemNowBtn = false;
			if (this.experienceDetails.experience_booking_type == 1) {
				this.request_to_book = true;
			}
			else {
				this.request_to_book = false;
			}

		} else {
			this.showRedeemNowBtn = true;
			this.showScheduleExpBtn = true;
			this.showViewAvaibalityExpBtn = false;
		}
		/** If Online Scheduling available then show the availability block Ends **/

		this.showExcConfirmBtn = false;
		this.showExcCancelBtn = false;
		this.showCompletePurchBtn = false;
		this.showBackBtn = false;
		this.showCheckoutBtn = false;
		this.showContinueShopBtn = false;
		this.backfromRedemmeScheduleBtn = true;

		this.priceBlock = false;
		this.exchangeBlock = false;
		this.redeemScheduleBlock = false;
		this.onlineBookingMsgBlock = false;
		this.isActiveTab = 'how_to_redeem';
		this.isLoading = false;


	}

	backfromRedemmeSchedule() {
		this.showViewAvaibalityExpBtn = false;
		this.showRedeemNowBtn = false;
		this.showRedeemNowConfirm = false;
		this.showRedeemNowCancel = false;
		this.backfromRedemmeScheduleBtn = false;
		this.showViewPrintBtn = true;
		this.priceBlock = true;
		this.showRedeemScheduleBtn = true;
		this.showExchangeBtn = true;
		this.onlineScheduleBlock = false;
		this.showSeasonHeadline = true;
		this.isActiveTab = 'summary';
	}

	addCartSpecialClassOnBody() {
		if (this.isBrowser) {
			if (window.innerWidth > 768) {

				var element = document.querySelector("body");
				var htmlelement = document.querySelector("html")
				if (element) {
					element.classList.add('overflow');
				}
				if (htmlelement) {
					htmlelement.classList.add('overflow');
				}
			}
		}
	}

	showPriceChangeMessage() {

		if (typeof this.experienceDetails.purchase_price != undefined && typeof this.experienceDetails.regular_price != undefined && this.experienceDetails.is_purchased == 1 && Number(this.experienceDetails.regular_price) != Number(this.experienceDetails.purchase_price)) {
			this.toastr.infoToastr('This experience price has been changed (from $' + this.experienceDetails.purchase_price + ' to $' + this.experienceDetails.regular_price + ')', 'Info!');
		}
	}

	/* FUNCTION TO ADD EXPERIENCE TO CART */
	openDialog(id, slug, title, qty, image, price, is_buy_for_yourself, veriation_id, type, tax, at_home = 0, instant_ship = 0, experience_type = 0, purchase_from = '') {
		this.isLoading = true;

		var price: any = Number(price);
		if (price == "" || price == 0) {
			this.amount_required_error = true;
			this.isLoading = false;
			return;
		} else {
			this.amount_required_error = false;
		}

		this.close_certificate_popup();
		this.close();

		if (is_buy_for_yourself == 1) {

			this.addCartSpecialClassOnBody();	// calls function for add classes.

			//if (slug != 'gift-certificate') {
			//this.showPriceChangeMessage(); // show price change message.
			//}

			if (experience_type == 1) {
				this.showPriceChangeMessage(); // show price change message.
			}

			var taxValue = ((price * qty) * tax / 100);

			this.purchaseExpID = id;
			this.purchaseExpSlug = slug;
			this.purchaseExpTitle = title;
			this.purchaseExpImage = image;
			this.purchaseExpType = type;
			this.purchaseExpVerID = veriation_id;
			this.purchaseExpPrice = price;
			this.purchaseExpQuantity = qty;
			this.purchaseExpTax = taxValue;
			let pusheditems = {};
			var cartitems: any = [];

			if (localStorage.getItem('cart_details')) {
				cartitems = JSON.parse(localStorage.getItem('cart_details'));
			}
			const $this = this;
			let hasPresentOn = cartitems.find(
				function (el) {

					if (el.product_id === id) {
						$this.isLoading = false;
						return true;
					} else {
						$this.isLoading = false;
						return false;
					}
				}
			);

			//var index = cartitems.findIndex(x => x.slug === 'gift-certificate');
			var index = cartitems.findIndex(x => x.experience_type != 1);
			if (!hasPresentOn) {
				if (this.authUser) {
					if (this.authUser.role_id == 5 || this.authUser.role_id == 1) {
						pusheditems['voucher_name'] = this.authUser.first_name + ' ' + this.authUser.last_name + ' & ' + this.authUser.partner_first_name + ' ' + this.authUser.partner_last_name;
					} else {
						pusheditems['voucher_name'] = this.authUser.first_name + ' ' + this.authUser.last_name;
					}
				}
				pusheditems['product_id'] = this.purchaseExpID;
				pusheditems['title'] = this.purchaseExpTitle;
				pusheditems['slug'] = this.purchaseExpSlug;
				pusheditems['image'] = this.purchaseExpImage;
				pusheditems['type'] = this.purchaseExpType;
				pusheditems['option'] = this.purchaseExpType;
				pusheditems['line_total'] = this.purchaseExpPrice;
				pusheditems['qty'] = this.purchaseExpQuantity;
				pusheditems['variation_id'] = this.purchaseExpVerID;
				pusheditems['tax'] = this.purchaseExpTax;
				pusheditems['at_home'] = at_home;
				pusheditems['instant_ship'] = instant_ship;
				pusheditems['from_experience'] = 1;
				pusheditems['experience_type'] = experience_type;
				pusheditems['is_added_registries'] = 0;
				pusheditems['keep_it_secrate'] = 0;

				pusheditems['purchased_from'] = purchase_from;

				if (this.is_guest_view && this.guest_user_data) {

					pusheditems['user_id'] = this.guest_user_data.id;
					pusheditems['voucher_name'] = this.guest_user_data.couple_full_name;
					pusheditems['to_name'] = this.guest_user_data.couple_full_name;
					pusheditems['purchased_from'] = 'guest-view';
					pusheditems['is_added_registries'] = 1;		// on checkout Message Tab 'TO' Options will not display.
					pusheditems['keep_it_secrate']	= 	(new Date(this.user_data.wedding_date).getTime() > new Date().getTime()) ? 1 : 0;
					pusheditems['purchase_type'] = 'find_registry';

				} else if(this.authUser) {
					// Check if experience type is Event,Gift Certificate, Event variations.
					if ([2,3,8].includes(experience_type)){
						pusheditems['user_id'] = this.selected_couple;
						pusheditems['voucher_name'] = this.gift_certificate_couple_full_name;
						if (this.selected_couple != 0 && this.selected_couple != this.authUser.id) {
							pusheditems['purchased_from'] = 'guest-view';
							pusheditems['is_added_registries'] = 1;
							pusheditems['keep_it_secrate']	= 	(new Date(this.user_data.wedding_date).getTime() > new Date().getTime()) ? 1 : 0;				// on checkout Message Tab 'TO' Options will not display.
							pusheditems['purchase_type'] = 'find_registry';
						} else {
							pusheditems['purchased_from'] = '';
							pusheditems['is_added_registries'] = 0;
							pusheditems['keep_it_secrate']	= 	0;				// on checkout Message Tab 'TO' Options will not display.
						}

					}
					else {
						pusheditems['user_id'] = this.authUser.id;
					}
				} else {
					// Check if experience type is Event,Gift Certificate, Event variations.
					if ([2,3,8].includes(experience_type) || slug == 'gift-certificate'){
						pusheditems['user_id'] = this.selected_couple;
						pusheditems['voucher_name'] = this.gift_certificate_couple_full_name;
						if (!this.authUser && this.selected_couple != 0) {
							pusheditems['purchased_from'] = 'guest-view';
							pusheditems['is_added_registries'] = 1;
							pusheditems['keep_it_secrate']	= 	(new Date(this.user_data.wedding_date).getTime() > new Date().getTime()) ? 1 : 0;
							pusheditems['purchase_type'] = 'find_registry';
						} else {
							pusheditems['purchased_from'] = '';
							pusheditems['is_added_registries'] = 0;
							pusheditems['keep_it_secrate']	= 	0;
						}

					}
					else {
						pusheditems['user_id'] = 0;
					}
				}

				cartitems.push(pusheditems);

				if (this.showContinueShopBtn == false) {
					this.cartService.sendMessage(JSON.stringify(cartitems));
				}
				this.partialPurchasePopup.hide();
				// this.cartService.sendMessage(JSON.stringify(cartitems));
				localStorage.setItem('cart_details', JSON.stringify(cartitems));
				this.cartService.sendMessage(JSON.stringify(cartitems));
			} else {
				// Check if experience type is Event,Gift Certificate, Event variations.
				if ([2,3,8].includes(experience_type) || slug == 'gift-certificate') {
					cartitems[index].line_total = Number(price);
					cartitems[index].user_id = this.selected_couple;
					cartitems[index].voucher_name = this.gift_certificate_couple_full_name;;
					if (this.selected_couple != 0 && !this.authUser) {
						cartitems[index].purchased_from = 'guest-view';
						cartitems[index].is_added_registries = 1;
						cartitems[index].keep_it_secrate	= 	(new Date(this.user_data.wedding_date).getTime() > new Date().getTime()) ? 1 : 0;
					} else {
						cartitems[index].purchased_from = '';
						cartitems[index].is_added_registries = 0;
						cartitems[index].keep_it_secrate	= 	0;				// on checkout Message Tab 'TO' Options will not display.
					}
					localStorage.setItem('cart_details', JSON.stringify(cartitems));
					this.cartService.sendMessage(localStorage.getItem('cart_details'));
				} else {
					// this.cartService.sendMessage(localStorage.getItem('cart_details'));
					alert("Already added in your cart.")
				}
			}
		}
		if (this.showContinueShopBtn == false) {
			this.experieneDetailPopupClose();
		}

		this.isLoading = false;
		this.amount = 0;

		console.log('cartitems', cartitems);
	}

	hidePopup() {
		this.showCountDownText = false;

	}



	removeOverflow() {
		if (this.isBrowser) {
			var element = document.querySelector("body")
			var htmlelement = document.querySelector("html")
			if (element) {
				element.classList.remove('overflow');
			}
			if (htmlelement) {
				htmlelement.classList.remove('overflow');
			}
		}
	}

	/* SHOW/HIDE EXCHANGE EXPERIENCE SECTIONS */
	addExchangeInfo() {
		this.isLoading = true;
		this.showDetailsTab = true;
		this.showExchangeTab = true;
		this.showGiftedByTab = true;
		this.showPhotosVideosTab = false;
		this.showSummeryTab = false;
		this.showHowToRedeemTab = false;
		this.showPartialPurchTab = false;
		this.showReviewTab = false;
		this.showJoinUsTab = false;
		/* SHOW/HIDE BUTTON */
		this.showAddRegBtn = false;
		this.showRemoveRegBtn = false;
		this.showBuyYourSelfBtn = false;
		this.showBuyAsGiftBtn = false;
		this.showCloseBtn = false;
		this.showReviewExpBtn = false;
		this.showAddPhotoVideoBtn = false;
		this.showSendThankYouBtn = false;
		this.showAddPhotoDeviceBtn = false;
		this.showAddVideoDeviceBtn = false;
		this.showRedeemScheduleBtn = false;
		this.showExchangeBtn = false;
		this.showViewPrintBtn = false;
		this.showScheduleExpBtn = false;
		this.showRedeemNowBtn = false;
		this.showExcConfirmBtn = true;
		this.showExcCancelBtn = true;
		this.showCompletePurchBtn = false;
		this.showBackBtn = false;
		this.showCheckoutBtn = false;
		this.showContinueShopBtn = false;
		this.priceBlock = false;
		this.exchangeBlock = true;
		this.redeemScheduleBlock = false;
		this.showSeasonHeadline = false;
		this.isActiveTab = 'exchange';
		this.isLoading = false;
	}
	showCheckout(id, slug, title, qty, image, price, is_buy_for_yourself, veriation_id, type, tax, experience_type = 0, purchasedFrom = "") {
		/* 		console.log(id);
				console.log(slug);
				console.log(title);
				console.log(qty);
				console.log(image);
				console.log(price);
				console.log(is_buy_for_yourself);
				console.log(veriation_id);
				console.log(type);
				console.log(tax);
				console.log(experience_type);
				console.log(purchasedFrom); */

		this.isLoading = true;
		this.showCheckoutBtn = true;
		this.showContinueShopBtn = false;
		this.addedtocart = true;
		this.showCompletePurchBtn = false;
		this.showExchangeBtn = false
		this.isLoading = false;
		this.openDialog(id, slug, title, qty, image, price, is_buy_for_yourself, veriation_id, type, tax, 0, 0, experience_type, purchasedFrom)
		this.showCheckoutBtn = false;

	}

	/* FUNCTION TO EXCHANGE EXPERIENCE */
	exchangeExp(id, order_id, order_item_id, voucher_code) {
		this.isLoading = true;
		this.addexperience.exchangeExp(voucher_code, this.tokenkey).subscribe(res => {
			if (res.response == 'success') {
				this.experienceDetails.experience_status = 3;
				this.experienceDetails.experience_status_msg = 'EXCHANGED';
				this.experienceDetails.exchange_date = new Date();

				this.messageService.sendExpStatusChangedToMainComponent({ 'exp_id': this.experienceDetails.id, 'experience_status': this.experienceDetails.experience_status, 'experience_status_msg': this.experienceDetails.experience_status_msg, 'exp_data': this.experienceDetails }); // send data to add Experience component.

				this.showExcConfirmBtn = false;
				this.newBookingRequestBtn = false;
				this.onlineBookingMsgBlock = false;
				this.showExcCancelBtn = false;
				this.exchangeBlock = false;
				this.exchangedBlock = true;
				this.show_close_button = true;
				this.showBuyYourSelfBtn = false;
				this.showCloseBtn = false;
				this.showBuyAsGiftBtn = false;

				// this.showBuyYourSelfBtn = true;
				// this.showCloseBtn = true;
				// this.showBuyAsGiftBtn = true;
				this.showEwalletBalance = true;

				this.getWalletAmount();

			} else if (res.response == 'error') {
				this.experieneDetailPopupClose();

				//this.toastr.errorToastr(res.message, 'Error!'); 
			}
		});
		this.isLoading = false;
	}

	/* SHOW/HIDE SCHEDULE EXPERIENCE SECTIONS */
	scheduleExpInfo() {
		this.isLoading = true;
		/* SHOW/HIDE BUTTON */
		this.showAddRegBtn = false;
		this.showRemoveRegBtn = false;
		this.showBuyYourSelfBtn = false;
		this.showBuyAsGiftBtn = false;
		this.showCloseBtn = false;
		this.showReviewExpBtn = false;
		this.showAddPhotoVideoBtn = false;
		this.showSendThankYouBtn = false;
		this.showAddPhotoDeviceBtn = false;
		this.showAddVideoDeviceBtn = false;
		this.showRedeemScheduleBtn = false;
		this.showExchangeBtn = false;
		this.showViewPrintBtn = true;
		this.showScheduleExpBtn = false;
		this.showViewAvaibalityExpBtn = false;
		this.showRedeemNowBtn = false;
		this.showExcConfirmBtn = false;
		this.showExcCancelBtn = false;
		this.showCompletePurchBtn = false;
		this.showBackBtn = true;
		this.showCheckoutBtn = false;
		this.showContinueShopBtn = false;

		this.priceBlock = false;
		this.exchangeBlock = false;
		this.redeemScheduleBlock = true;

		this.isActiveTab = 'how_to_redeem';
		this.isLoading = false;
	}

	/* FUNCTION TO REDEEM EXPERIENCE */
	redeemNowExp(id, order_id, order_item_id, voucher_code) {
		this.isLoading = true;
		this.addexperience.redeemExp(voucher_code, this.tokenkey).subscribe(res => {
			if (res.response == 'success') {
				// if(id == this.experienceDetails.id){
				// 	this.experienceDetails.experience_status = 5;
				// 	this.experienceDetails.experience_status_msg = 'REDEEMED';
				// }
				this.experienceDetails.experience_status = 5;
				this.experienceDetails.experience_status_msg = 'REDEEMED';
				this.experienceDetails.redeem_date = new Date();

				this.messageService.sendExpStatusChangedToMainComponent({ 'exp_id': this.experienceDetails.id, 'experience_status': this.experienceDetails.experience_status, 'experience_status_msg': this.experienceDetails.experience_status_msg, 'exp_data': this.experienceDetails }); // send data to add Experience component.

			} else if (res.response == 'error') {
				this.experieneDetailPopupClose();

				//this.toastr.errorToastr(res.message, 'Error!'); 
			}
			this.experieneDetailPopupClose();

		});
		this.isLoading = false;
	}

	/* BACK TO REDEEM/SCHEDULE EXPERIENCE SECTIONS */
	backToRedeem() {
		this.isLoading = true;
		this.showDetailsTab = true;
		this.showExchangeTab = false;
		this.showGiftedByTab = true;
		this.showPhotosVideosTab = false;
		this.showSummeryTab = true;
		this.showHowToRedeemTab = true;
		this.showPartialPurchTab = false;
		this.showReviewTab = false;
		this.showJoinUsTab = false;
		/* SHOW/HIDE BUTTON */
		this.showAddRegBtn = false;
		this.showRemoveRegBtn = false;
		this.showBuyYourSelfBtn = false;
		this.showBuyAsGiftBtn = false;
		this.showCloseBtn = false;
		this.showReviewExpBtn = false;
		this.showAddPhotoVideoBtn = false;
		this.showSendThankYouBtn = false;
		this.showAddPhotoDeviceBtn = false;
		this.showAddVideoDeviceBtn = false;
		this.showRedeemScheduleBtn = false;
		this.showExchangeBtn = false;
		this.showViewPrintBtn = false;
		this.onlineScheduleBlock = false;
		/** If Online Scheduling available then show the availability block Starts **/
		if (this.experienceDetails.experience_booking_type > 0) {
			this.showViewAvaibalityExpBtn = true;
			this.showScheduleExpBtn = false;
			if (this.experienceDetails.experience_booking_type == 1) {
				this.request_to_book = true;
			}
			else {
				this.request_to_book = false;
			}
		} else {
			this.showScheduleExpBtn = true;
			this.showRedeemNowBtn = true;
			this.showViewAvaibalityExpBtn = false;
		}
		/** If Online Scheduling available then show the availability block Ends **/


		this.showExcConfirmBtn = false;
		this.showExcCancelBtn = false;
		this.showCompletePurchBtn = false;
		this.showBackBtn = false;
		this.showCheckoutBtn = false;
		this.showContinueShopBtn = false;

		this.priceBlock = true;
		this.showSeasonHeadline = true;
		this.exchangeBlock = false;
		this.redeemScheduleBlock = false;
		this.isActiveTab = 'how_to_redeem';
		this.isLoading = false;
	}

	/* FUNCTION TO SHOW/HIDE ADD PHOTO/VIDEO SECTION */
	addPhotoVideoInfo() {
		this.isLoading = true;
		/* SHOW/HIDE TABS */
		this.showDetailsTab = true;
		this.showExchangeTab = false;
		this.showGiftedByTab = true;
		this.showPhotosVideosTab = true;
		this.showSummeryTab = false;
		this.showHowToRedeemTab = false;
		this.showPartialPurchTab = false;
		this.showReviewTab = false;
		this.showJoinUsTab = false;

		/* SHOW/HIDE BUTTON */
		this.showRemoveRegBtn = false;
		this.showAddRegBtn = false;
		this.showBuyYourSelfBtn = false;
		this.showBuyAsGiftBtn = false;
		this.showCloseBtn = false;
		this.showReviewExpBtn = false;
		this.showAddPhotoVideoBtn = false;
		this.showSendThankYouBtn = false;
		this.showAddPhotoDeviceBtn = true;
		this.showAddVideoDeviceBtn = true;
		this.showRedeemScheduleBtn = false;
		this.showExchangeBtn = false;
		this.showViewPrintBtn = false;
		this.showScheduleExpBtn = false;
		this.showRedeemNowBtn = false;
		this.showExcConfirmBtn = false;
		this.showExcCancelBtn = false;
		this.showCompletePurchBtn = false;
		this.showBackBtn = false;
		this.showCheckoutBtn = false;
		this.showContinueShopBtn = false;
		this.showEwalletBalance = false;

		this.priceBlock = false;
		this.exchangeBlock = false;
		this.redeemScheduleBlock = false;
		this.reviewRatingBlock = false;
		if (this.experienceDetails.experience_type == 3) {
			this.redeemedBlock = false;
		} else {
			this.redeemedBlock = true;
		}
		this.exchangedBlock = false;
		this.isActiveTab = 'detail';
		this.isLoading = false;
	}

	sendThankYou() {
		this.isLoading = true;
		this.addPhotoVideoModal.hide();
		this.experieneDetailPopupClose();
		this.sendThankYouEmailImages(); // calls function for get already uploaded images.

		this.thankYouStep1 = true;
		this.thankYouStep2 = false;
		this.thankYouStep3 = false;
		this.thankYouStep4 = false;
		this.thankYouStep5 = false;

		this.thankyouModal.show();
		this.isLoading = false;

		this.thank_you_file_url = '';
		this.ThankyouProcessForm.controls['additional_emails'].setValue('');
		this.ThankyouProcessForm.controls['thanku_text'].setValue('');
	}

	ganerateStaticFrames() {
		var frameArr = [];
		//frameArr.push({'id':1, 'image_url':'assets/images/frame1.png'});
		frameArr.push({ 'id': 2, 'image_url': 'assets/images/frame2.png' });
		frameArr.push({ 'id': 3, 'image_url': 'assets/images/frame3.png' });
		frameArr.push({ 'id': 4, 'image_url': 'assets/images/frame4.png' });
		frameArr.push({ 'id': 5, 'image_url': 'assets/images/frame5.png' });
		frameArr.push({ 'id': 6, 'image_url': 'assets/images/frame6.png' });
		frameArr.push({ 'id': 7, 'image_url': 'assets/images/frame7.png' });
		frameArr.push({ 'id': 8, 'image_url': 'assets/images/frame8.png' });

		this.frameImages = frameArr;
	}

	addPhotoVideoFromDevice(type) {
		this.isLoading = true;
		this.experieneDetailPopupClose();

		if (type == 'photo') {
			this.removeFrame();
			this.choosedSharePhotoUrl = ''; // initialize string.
			this.mainService.getFrameImages(this.tokenkey).subscribe(res => {
				if (res.response == 'success') {
					this.frameImages = res.data;
					this.ganerateStaticFrames();	//	show static frames.
					this.photoVideoStep1 = true;
					this.photoVideoStep2 = false;
					this.ShareVideoStep1 = false;
					this.photoVideoStep3 = false;
				} else if (res.response == 'error') {
					this.experieneDetailPopupClose();

				}
			})
		} else {
			this.choosedShareVideoUrl = ''; // initialize string.
			this.ShareVideoStep1 = true;
			this.photoVideoStep1 = false;
			this.photoVideoStep2 = false;
			this.photoVideoStep3 = false;
		}
		this.hide_back_button = true;
		this.addPhotoVideoModal.show();
		this.isLoading = false;
	}

	thankYouNext(step) {
		this.isLoading = true;
		if (step == 1) {
			this.thankYouStep1 = true;
			this.thankYouStep2 = false;
			this.thankYouStep3 = false;
			this.thankYouStep4 = false;
			this.thankYouStep5 = false;
		} else if (step == 2) {
			this.thank_you_file_url = '';
			this.thankYouStep1 = false;
			this.thankYouStep2 = true;
			this.thankYouStep3 = false;
			this.thankYouStep4 = false;
			this.thankYouStep5 = false;
		} else if (step == 3) {
			this.thankYouStep1 = false;
			this.thankYouStep2 = false;
			this.thankYouStep3 = true;
			this.thankYouStep4 = false;
			this.thankYouStep5 = false;
		} else if (step == 4) {
			this.thankYouStep1 = false;
			this.thankYouStep2 = false;
			this.thankYouStep3 = false;
			this.thankYouStep4 = true;
			this.thankYouStep5 = false;
		}
		this.isLoading = false;
		this.thankYouEmailReceipents = this.ThankyouProcessForm.get('additional_emails').value;
	}

	editThankYouImage() {
		this.thank_you_file_url = '';
	}

	chooseThankyouImage(event) {
		this.isLoading = true;
		this.thankYouImages = [];
		var files = event.target.files;
		this.thank_you_file_path = event.target.files[0];
		this.fileFormat = event.target.value.split('.').pop();
		if (this.fileFormat == "jpg" || this.fileFormat == "JPG" || this.fileFormat == "jpeg" || this.fileFormat == "png" || this.fileFormat == "PNG") {
			var reader = new FileReader();
			reader.readAsDataURL(files[0]);
			reader.onload = (e: any) => {
				this.thankYouImages.push(e.target.result);
				this.thank_you_file_url = e.target.result;
			}
			this.isLoading = false;
		} else {
			this.isLoading = false;
			alert("invalid file format")
			return;
		}
	}

	chooseThankyouImageFromUploaded(imageId, imageUrl) {
		const self = this;
		this.images = [];
		const xhr = new XMLHttpRequest();
		self.thankYouImageId = imageId;
		xhr.open("GET", imageUrl);
		xhr.responseType = "blob";
		xhr.send();
		xhr.addEventListener("load", function () {
			var reader = new FileReader();
			reader.readAsDataURL(xhr.response);
			reader.addEventListener("loadend", function () {

				self.thankYouImages.push(reader.result);
				self.thank_you_file_url = reader.result;

			});
		});
	}

	initializeCropieOnThankYouPhoto() {
		if (this.isBrowser) {
			this.thankYouCroppieModal.show();
			setTimeout(() => {
				this.croppieThankYouImageDirective.croppie.bind({ url: this.thank_you_file_url });
			}, 1000);
		}
	}

	saveThankYouCropiePhotoResult() {
		if (this.isBrowser) {
			this.thankYouImages = [];
			const $this = this;
			this.croppieThankYouImageDirective.croppie.result({ type: 'canvas', size: 'original', format: 'png', quality: 1 }).then(function (data) {
				$this.thankYouCroppieModal.hide();
				$this.thankYouImages.push(data);
				$this.thank_you_file_url = data;
			});
		}
	}

	thankYouPreview() {
		if (!this.thankYouMessage_error) {
			this.thankyou_message_is_blank = false;
			this.thankYouNext(4); // calls function to enable step 4.
		} else {
			this.thankyou_message_is_blank = true;
			setTimeout(() => {
				this.thankyou_message_is_blank = false;
			}, 3000)

		}
	}
	thankYouMessage($event) {

		if ($event.target.value.replace(/^\s+|\s+$/gm, '') != '') {
			this.thankYouMessage_error = false;
		} else {
			this.thankYouMessage_error = true;
		}
	}


	sendThankYouEmailImages() {

		let requestBody = {};
		this.experienceShareService.sendThankYouEmailImages(requestBody, this.tokenkey).subscribe(res => {
			if (res.response == 'success') {
				this.thankYouUploadedImages = res.data;
			}
		});
		this.isLoading = false;
	}

	sendThankYouFinal() {

		this.isLoading = true;
		let requestBody = {
			"order_id": this.experienceDetails.order_id,
			"order_item_id": this.experienceDetails.order_item_id,
			"voucher_code": this.experienceDetails.voucher_code,
			"exp_id": this.experienceDetails.id,
			"email": this.experienceDetails.billing_email,
			"additional_email": this.ThankyouProcessForm.get('additional_emails').value,
			"message": this.ThankyouProcessForm.get('thanku_text').value,
			"thank_you_file_url": this.thank_you_file_url,
			"thank_you_image_id": this.thankYouImageId,
		}
		this.experienceShareService.sendThankYouEmail(requestBody, this.tokenkey).subscribe(res => {
			if (res.response == 'success') {
				this.thankYouStep1 = false;
				this.thankYouStep2 = false;
				this.thankYouStep3 = false;
				this.thankYouStep4 = false;
				this.thankYouStep5 = true;
				this.isLoading = false;
				this.experienceDetails.thank_you_email_sent = 1;
				this.messageService.sendExpStatusChangedToMainComponent({ 'exp_id': this.experienceDetails.id, 'experience_status': this.experienceDetails.experience_status, 'experience_status_msg': this.experienceDetails.experience_status_msg, 'exp_data': this.experienceDetails });
			} else if (res.response == 'error') {
				this.thankyouModal.hide();
				this.isLoading = false;
			}
		});

	}

	closePhotoVideoModal() {
		this.addPhotoVideoModal.hide();
	}

	choosePhotoForShare(event) {
		//alert(123);
		this.shareProcess = false;
		this.shareImages = [];
		this.fileFormat = event.target.value.split('.')[1]

		if (this.fileFormat == "jpg" || this.fileFormat == "JPG" || this.fileFormat == "JPEG" || this.fileFormat == "jpeg" || this.fileFormat == "png" || this.fileFormat == "PNG") {
			var files = event.target.files;
			var reader = new FileReader();
			reader.readAsDataURL(files[0]);
			reader.onload = (e: any) => {
				this.shareImages.push(e.target.result);
				this.choosedSharePhotoUrl = e.target.result;
			}
		} else {
			alert("invalid file format")
			return;
		}
	}

	initializeCropieOnSharePhoto() {
		if (this.isBrowser) {
			this.photoShareCroppieModal.show();
			const self = this;
			setTimeout(function () {
				self.croppieShareImageDirective.croppie.bind({ url: self.choosedSharePhotoUrl });
				self.croppieShareImageDirective.croppie.setZoom(0);
			}, 500);
		}
	}
	saveShareCropiePhotoResult() {
		if (this.isBrowser) {
			const $this = this;
			this.croppieShareImageDirective.croppie.result({ type: 'canvas', size: 'original', format: 'png', quality: 1 }).then(function (data) {
				$this.photoShareCroppieModal.hide();
				$this.shareImages.push(data);
				$this.choosedSharePhotoUrl = data;
			});
		}
	}

	takeScreenShot(elementId, type) {
		/* alert(elementId);
		alert(type); */
		if (this.isBrowser) {
			const $this = this;

			if (type == 'image') {

				$this.isLoading = true;

				var imageElement = document.getElementById(elementId);
				const area = imageElement.getBoundingClientRect();
				html2canvas(imageElement, {
					allowTaint: false,
					useCORS: false,
					scrollX: 0,
					scrollY: 0,
					width: area.width,
					height: area.height
				}).then(canvas => {

					var imgData = canvas.toDataURL("image/jpeg");

					$this.choosedSharePhotoUrl = imgData;

					$this.isLoading = false;

					$this.shareType = type;

					$this.ShareVideoStep1 = false;
					$this.photoVideoStep1 = false;
					$this.photoVideoStep3 = false;
					$this.photoVideoStep2 = true;

				});
			} else {
				$this.ShareVideoStep1 = false;
				$this.photoVideoStep1 = false;
				$this.photoVideoStep3 = false;
				$this.photoVideoStep2 = true;
			}
		}
	}

	photoVideoNextStep(type, identify = 0) {
		if (identify == 0) {
			this.takeScreenShot('imageBackDiv', type); // calls function take screenshot.
		}
	}

	chooseVideoForShare(event) {
		this.shareProcess = false;
		const file = event.target.files && event.target.files[0];
		this.share_video_path = event.target.files[0];
		this.fileFormat = event.target.value.split('.')[1]
		if (this.fileFormat == 'mov' || this.fileFormat == 'MOV' || this.fileFormat == 'webm' || this.fileFormat == 'WEBM' || this.fileFormat == 'mp4' || this.fileFormat == 'MP4' || this.fileFormat == 'm4p' || this.fileFormat == 'M4P' || this.fileFormat == 'm4v' || this.fileFormat == 'M4V' || this.fileFormat == 'avi' || this.fileFormat == 'AVI' || this.fileFormat == 'qt' || this.fileFormat == 'QT') {
			if (file) {
				var reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = (event) => {
					this.choosedShareVideoUrl = (<FileReader>event.target).result;
				}
				this.photoVideoNextStep('video'); // trigger function to next step if video is selected
			}
		} else {
			alert("Invalid file format")
		}

	}

	saveImageShareProcess(type) {

		this.isLoading = true;
		let formData = new FormData();
		formData.append('exp_id', this.experienceDetails.id);
		formData.append('order_id', this.experienceDetails.order_id);
		formData.append('order_item_id', this.experienceDetails.order_item_id);
		formData.append('voucher_code', this.experienceDetails.voucher_code);
		formData.append('type', type);
		formData.append('description', this.PhotoVideoShareForm.get('description').value);
		formData.append('hash_tag', this.PhotoVideoShareForm.get('hash_tag').value);
		if (type == "image") {
			formData.append('file_path', this.choosedSharePhotoUrl);
		} else {
			formData.append('file_path', this.share_video_path);
		}

		this.shareProcess = true;

		this.experienceShareService.photoVideoShareSave(formData, this.tokenkey).subscribe(res => {
			if (res.response == 'success') {
				this.isLoading = false;
				this.imageSaveMsg = res.message;
				this.uploadedPhotoVideoUrl = res.file_url;
				this.imageSaveMsgShow = true;
				this.photoVideoSaveStatus = true;
				this.shareProcess = false;
				setTimeout(() => {    //<<<---    using ()=> syntax
					this.imageSaveMsgShow = false;
				}, 4000);
			} else if (res.response == 'error') {
				this.isLoading = false;
				this.addPhotoVideoModal.hide();
				this.shareProcess = false;
			}
		})
		// this.isLoading = false;
	}

	sharePhotoOnFacebook() {

		var shareData = {
			'name': 'Test Exp',
			'url': this.uploadedPhotoVideoUrl,
			'image': this.uploadedPhotoVideoUrl,
			'caption': '',
			'description': '',
			'message': '',
		};

		this.fbService.postToFeed(shareData); // calls share service function.				
		this.shareFacebookStatus = true;

	}

	backToPhotoVideoShareStep1(type) {
		if (type == 'image') {
			this.photoVideoStep1 = true;
			this.ShareVideoStep1 = false;
			this.photoVideoStep2 = false;
			this.photoVideoStep3 = false;
		} else {
			this.photoVideoStep1 = false;
			this.ShareVideoStep1 = true;
			this.photoVideoStep2 = false;
			this.photoVideoStep3 = false;
		}

	}

	completeShareProcess() {
		this.completeSharingProcess = true;

		this.photoVideoStep3 = true;

		this.photoVideoStep1 = false;
		this.ShareVideoStep1 = false;
		this.photoVideoStep2 = false;
	}

	/* FUNCTION TO GET WALLET DETAILS */
	getWalletAmount() {
		if (this.authUser) {
			this.mainService.getWalletAmount(this.tokenkey).subscribe(res => {
				if (res.response == 'success') {
					this.messageService.sendNotificationCount(res);
					this.messageService.sendAmountMessage(res.data.wallet_amount);
					this.wallet_balance = parseFloat(res.data.wallet_amount).toFixed(2);
					this.totalAdded = res.data.total_exp_count;
				} else if (res.response == 'error') {
					this.wallet_balance = 0.00;
				}
			});
		}
	}


	saveCroppieImage() {
		const $this = this
		$this.isLoading = true;
		if (this.isBrowser) {
			this.croppieDirective.croppie.result({ type: 'canvas', size: 'original', format: 'png', quality: 1 }).then(function (data) {
				let requestBody = { 'image': JSON.stringify(data) }
				$this.registerService.upload_profile_picture(requestBody, $this.tokenkey).subscribe(res => {
					if (res.response == "success") {

						$this.isLoading = false
						var updateAuthUser = JSON.parse(localStorage.getItem("authUser"));
						updateAuthUser.profile_image = res.data;
						localStorage.setItem("profile_img_url", res.base_url + res.data);
						localStorage.setItem("authUser", JSON.stringify(updateAuthUser));
						$this.authUser = JSON.parse(localStorage.getItem('authUser'));
						$this.profile_img_url = res.base_url + res.data;
						$this.is_profile_edit = !$this.is_profile_edit;
						$this.upload_profile.hide();
						$this.large.hide();

						$this.authdataMessageService.sendProfilePicMessage($this.profile_img_url);
						$this.profileService.sendMessage($this.profile_img_url);
						/** If Gift voucher amount added then show congratulation Popup work starts **/
						if (typeof res.gift_add != "undefined" && res.gift_add == 1) {

							var updateAuthUser = JSON.parse(localStorage.getItem("authUser"));
							$this.authUser.gift_voucher_received = 1;
							updateAuthUser.gift_voucher_received = 1;
							$this.showCountDownText = false;
							$this.show_congratulation = true;
							$this.congratulationPopup.show();

							localStorage.setItem("authUser", JSON.stringify(updateAuthUser));

							$this.getWalletAmount();
						}
						/** If Gift voucher amount added then show congratulation Popup work ends **/

					} else {
						//$this.toastr.errorToastr(res.message, 'Error!'); 
						$this.isLoading = false
					}
				});
			});
		}

	}

	rotateLeft(degrees) {
		if (this.isBrowser) {
			this.croppieDirective.croppie.rotate(degrees)
		}
	}

	rotateRight(degrees) {
		if (this.isBrowser) {
			this.croppieDirective.croppie.rotate(degrees)
		}
	}

	reset() {
		if (this.isBrowser) {
			this.croppieDirective.croppie.bind({ url: this.images[0] });
		}
	}

	rotateLeftSharePhoto(degrees) {
		if (this.isBrowser) {
			this.croppieShareImageDirective.croppie.rotate(degrees)
		}
	}

	rotateRightSharePhoto(degrees) {
		if (this.isBrowser) {
			this.croppieShareImageDirective.croppie.rotate(degrees)
		}
	}

	resetSharePhoto() {
		if (this.isBrowser) {
			this.croppieShareImageDirective.croppie.bind({ url: this.shareImages[0] });
		}
	}

	rotateLeftThankYouPhoto(degrees) {
		if (this.isBrowser) {
			this.croppieThankYouImageDirective.croppie.rotate(degrees)
		}
	}

	rotateRightThankYouPhoto(degrees) {
		if (this.isBrowser) {
			this.croppieThankYouImageDirective.croppie.rotate(degrees)
		}
	}

	resetThankYouPhoto() {
		if (this.isBrowser) {
			this.croppieThankYouImageDirective.croppie.bind({ url: this.thankYouImages[0] });
		}
	}





	videoPopup(vdo) {

		this.videoPopupModal.show();
		this.experience_video_url = this.sanitizer.bypassSecurityTrustResourceUrl(vdo);
	}

	closeVideoPopup(event) {
		if (this.isBrowser) {
			var veboBody = document.getElementById("vebo_body");
			veboBody.classList.remove('videoPopup-hidden');
			document.getElementById('video-popup-wrapper').hidden = true;
		}
	}


	applyFrame(imgFrmeUrl) {
		this.imgFrmeUrlCover = imgFrmeUrl;
	}

	removeFrame() {
		this.imgFrmeUrlCover = 'opacityImg';
	}

	frameZoom(type = '') {
		if (type == 'minus') {
			if (this.isBrowser) {
				var GFG = document.getElementById("sharePhotoSource");
				var currWidth = GFG.clientWidth;
				GFG.style.width = (currWidth - 100) + "px";
			}
		} else if (type == 'plus') {
			if (this.isBrowser) {
				var GFG = document.getElementById("sharePhotoSource");
				var currWidth = GFG.clientWidth;
				GFG.style.width = (currWidth + 100) + "px";
			}
		}
	}

	onSchedulingDateSelect($event) {
		this.isLoading = true;
		this.slot = '';
		this.people_data = [];
		this.booking_price = 0;
		this.booking_price_with_tax = 0;
		this.selected_people = 0;
		this.no_slot_avail_message = '';
		this.booking_slot_data = [];
		this.fare_harbor_custom_data = [];
		var year = "" + $event.getFullYear();
		var month = "" + ($event.getMonth() + 1); if (month.length == 1) { month = "0" + month; }
		var day = "" + $event.getDate(); if (day.length == 1) { day = "0" + day; }
		this.requestedDate = year + "-" + month + "-" + day;
		//console.log('exp-details', this.experienceDetails);
		//alert(this.experienceDetails.instant_book);
		if (this.experienceDetails.instant_book == 1 && this.instant_book_request == 1) {
			let requestBody = { 'experience_id': this.experienceDetails.id, 'booking_date': this.requestedDate };
			this.experienceProviderAvailablityService.provider_availablity(requestBody, this.tokenkey).subscribe(res => {
				if (res.response == 'success') {
					switch (res.data.booking_type) {
						case 2:
							this.setDataForFareharbor(res.data);
							break;

						case 3:
							this.setDataForHornBlower(res.data);
							break;

						case 4:
							this.setDataForRezdy(res.data);
							break;

						case 5:
							this.setDataForTiqets(res.data);
							break;
						case 6:
							this.setDataForPeekOcta(res.data);
							break;
					}
				}
				else {
					this.request_to_book = false;
					this.show_instant_booking = true;
					this.isLoading = false;
					this.no_slot_avail_message = res.message;
					this.toastr.errorToastr(res.message, 'Error!');
				}
			})
		}else{
			this.instant_book_request =   2;
			this.isLoading = false;
			this.request_to_book = true;
			//this.show_fareharbor = false;
			this.show_instant_booking = true;
		}

	}

	setDataForFareharbor(res) {
		console.log('res', res);
		this.instant_book_request     	=   1;
		this.booking_slot_data 			= 	res.slots;
		this.fare_harbor_custom_data 	= 	res.custom_fields_data;
		this.hotel_data 				= 	res.hotels;
		this.isLoading 					= 	false;
		this.request_to_book 			= 	false;
		this.show_instant_booking 		= 	true;
		this.OnlineSchedulingForm.get('booking_type').setValue(res.booking_type);
	}

	setDataForHornBlower(res) {
		this.instant_book_request     	=   1;
		this.booking_slot_data 			= 	res.slots;
		this.isLoading 					= 	false;
		this.request_to_book 			= 	false;
		this.show_instant_booking 		= 	true;
		this.OnlineSchedulingForm.get('booking_type').setValue(res.booking_type);
		//this.OnlineSchedulingForm.get('hornblower_category').setValue(res.category);
	}

	setDataForRezdy(res) {
		this.instant_book_request     	=   1;
		this.booking_slot_data 			= 	res.slots;
		this.isLoading 					= 	false;
		this.request_to_book 			= 	false;
		this.show_instant_booking 		= 	true;
		this.OnlineSchedulingForm.get('booking_type').setValue(res.booking_type);
		this.OnlineSchedulingForm.get('product_code').setValue(res.product_code);
		this.OnlineSchedulingForm.get('time_options').setValue(res.time_options);
	}

	

	setDataForTiqets(res) {
		this.instant_book_request     	=   1;
		this.booking_slot_data 			= 	res.slots;
		this.isLoading 					= 	false;
		this.request_to_book 			= 	false;
		this.show_instant_booking 		= 	true;
		this.OnlineSchedulingForm.get('booking_type').setValue(res.booking_type);
	}

	setDataForPeekOcta(res) {
		this.instant_book_request     	=   1;
		this.booking_slot_data 			= 	res.slots;
		this.peek_availability_id 		= 	res.availability_id;
		this.isLoading 					= 	false;
		this.request_to_book 			= 	false;
		this.show_instant_booking 		= 	true;
		this.OnlineSchedulingForm.get('booking_type').setValue(res.booking_type);

	}

	time_slot(event, slot, index) {
		this.slot = slot;
		this.requires_visitors_details = [];
		if (this.OnlineSchedulingForm.get('booking_type').value == 2) {
			//this.booking_slot_data[index]
			this.OnlineSchedulingForm.get('display_book_number').setValue(event.target.value);
		} else if (this.OnlineSchedulingForm.get('booking_type').value == 3) {
			this.OnlineSchedulingForm.get('hornblower_category').setValue(event.target.value);
		} else if (this.OnlineSchedulingForm.get('booking_type').value == 5) {
			this.OnlineSchedulingForm.get('variant_id').setValue(event.target.value);
		} else if (this.OnlineSchedulingForm.get('booking_type').value == 6) {
			this.OnlineSchedulingForm.get('peek_unit_id').setValue(event.target.value);
		}


		if (this.booking_slot_data[index]) {
			if (this.OnlineSchedulingForm.get('booking_type').value == 2 || this.OnlineSchedulingForm.get('booking_type').value == 4) {

				this.booking_price = this.booking_slot_data[index].price;
				this.booking_price_with_tax = this.booking_slot_data[index].price_with_tax;
				this.OnlineSchedulingForm.get('price').setValue(this.booking_price);
				this.OnlineSchedulingForm.get('book_number').setValue(this.booking_slot_data[index].book_number);

				if (this.OnlineSchedulingForm.get('booking_type').value == 4) {
					let seat_label = this.booking_slot_data[index].seat_label;
					this.OnlineSchedulingForm.get('seat_label').setValue(seat_label);
				}

			}else{
				this.OnlineSchedulingForm.get('price').setValue(0);
				if(this.OnlineSchedulingForm.get('booking_type').value == 5){
					if(typeof this.booking_slot_data[index].requires_visitors_details != undefined && this.booking_slot_data[index].requires_visitors_details){
						if(this.booking_slot_data[0].requires_visitors_details && this.booking_slot_data[0].requires_visitors_details.length > 0){
						  for (let index = 0; index < this.booking_slot_data[0].requires_visitors_details.length; index++) {
							const ele = this.booking_slot_data[0].requires_visitors_details[index];
							this.requires_visitors_details.push({key : ele, value : "" , err : null});
						  }
						}
					  }
				}
			}

		} else {
			this.booking_price = 0;
			this.booking_price_with_tax = 0;
		}
	}

	/* select_people(event){
		var people 	=	event.target.value;
		if(people){
			this.selected_people			=	people;
		}else{
			this.selected_people			=	0;
		}
	} */

	dynamic_dropdown(identify, event) {

		let dynamic_option_object = {};

		const $this = this;
		let hasPresentOn = this.dynamic_option_array.find(
			function (el) {
				if (el.key == event.target.name) {
					$this.index = $this.dynamic_option_array.findIndex(index_id => index_id.key == event.target.name);
					return true;
				} else {
					return false;
				}
			}
		);
		if (!hasPresentOn) {
			dynamic_option_object['key'] = event.target.name;
			dynamic_option_object['value'] = event.target.value;
			this.dynamic_option_array.push(dynamic_option_object);
		}
		else {
			this.dynamic_option_array[this.index].key = event.target.name;
			this.dynamic_option_array[this.index].value = event.target.value;
		}

	}


	tiqets_input(identify, event) {

		let dynamic_option_object = {};

		const $this = this;
		let hasPresentOn = this.requires_visitors_details.find(
			function (el) {
				if (el.key == event.target.name) {
					$this.index = $this.requires_visitors_details.findIndex(index_id => index_id.key == event.target.name);
					return true;
				} else {
					return false;
				}
			}
		);
		if (!hasPresentOn) {
			dynamic_option_object['key'] = event.target.name;
			dynamic_option_object['value'] = event.target.value;
			this.requires_visitors_details.push(dynamic_option_object);
		}
		else {
			this.requires_visitors_details[this.index].key = event.target.name;
			this.requires_visitors_details[this.index].value = event.target.value;
			this.requires_visitors_details[this.index].err = null;
		}

	}


	showPartialPurchase(exp_id) {
		this.additionalImages = this.experienceDetails.additional_images;

		this.remainingUnits = this.experienceDetails.partial_purchase_max - this.experienceDetails.partial_purchase_qty

		this.generatePartialUnits(); // calls function for generate partial units.

		this.partialPurchasePopup.show();
	}

	generatePartialUnits() {
		this.units = [];
		var counter = 1;

		var unitLength = this.experienceDetails.partial_purchase_max - this.experienceDetails.partial_purchase_qty;
		for (var j = 1; j <= unitLength; j++) {
			var classType = 'remaingPartialPurchaseUnits';
			this.units.push({ 'name': counter, 'className': classType });
			counter++;
		}
		if (this.experienceDetails.partial_purchase_qty > 0) {
			for (var k = 1; k <= this.experienceDetails.partial_purchase_qty; k++) {
				var classType = 'PartialPurchasedUnits';
				this.units.push({ 'name': counter, 'className': classType });
				counter++;
			}
		}
	}

	/* close_group_pricing_popup(){
		  this.experienceGroupPricingPopup.hide()
		  this.avail_child_exp=false;
	} */

	close() {
		if (this.isBrowser) {
			var element = document.querySelector("body")
			var htmlelement = document.querySelector("html")
			if (element)
				element.classList.remove('overflow');
			if (htmlelement)
				htmlelement.classList.remove('overflow');
		}

		this.addedtocart = false
		this.experieneDetailPopupClose()
		this.avail_child_exp = false;
		this.showRedeemNowCancel = false;
		this.showRedeemNowConfirm = false;
	}
	CloseAddCartJoinUs() {
		this.joinUsStep4 = false;
		this.addCartJoinUsPopup.hide()
	}
	owlCharityDetail = {
		mouseDrag: false,
		margin: 0,
		autoplayTimeout: 7000,
		transitionStyle: "slide",
		touchDrag: true,
		autoplay: true,
		lazyLoad: true,
		items: 1,
		smartSpeed: 500,
		nav: false,
		loop: true,
		dots: false,
		autoplayHoverPause: true,
	}


	/* FUNCTION TO ADD TO REGISTRY */
	addToRegistry_group(exp_id, type, is_suggestion = 0, registry_for = "") {

		this.isLoading = true;
		this.type_identity = type;
		this.showRegChkmark = true;
		this.avail_child_exp = false;
		for (let i = 0; i < this.resultExp.length; i++) {
			if (this.resultExp[i].id == exp_id) {
				this.resultExp[i].is_show_ckbx = 1;
				break;
			}
		}
		this.addexperience.addExperienceRegistry(exp_id, type, this.tokenkey).subscribe(res => {

			if (res.response == 'success') {
				if (registry_for == 'suggested') {
					for (let i = 0; i < this.suggestionData.length; i++) {
						if (this.suggestionData[i].id == exp_id) {
							this.suggestionData[i].is_show_ckbx = 0;
							if (type == "experiences_join_us") {
								if (this.suggestionData[i].is_added_join_us == 1) {
									this.suggestionData[i].is_added_join_us == 0
									this.suggestionData[i].is_registry_status = 3;
								} else {
									this.suggestionData[i].is_added_join_us == 1
									this.suggestionData[i].is_registry_status = 4;
								}
							} else {
								if (this.suggestionData[i].is_added_registry == 1) {
									this.suggestionData[i].is_added_registry = 0;
									this.suggestionData[i].is_registry_status = 2;
								} else {
									this.suggestionData[i].is_added_registry = 1;
									this.suggestionData[i].is_registry_status = 1;
								}
							}
							break;
						}
					}
				} else {
					for (let i = 0; i < this.resultExp.length; i++) {
						if (this.resultExp[i].id == exp_id) {

							this.resultExp[i].is_show_ckbx = 0;
							if (type == "experiences_join_us") {
								if (this.resultExp[i].is_added_join_us == 1) {
									this.resultExp[i].is_added_join_us = 0;
									this.resultExp[i].is_added_registry = 0;
									this.resultExp[i].is_registry_status = 3;
								} else {
									this.resultExp[i].is_added_join_us = 1;
									this.resultExp[i].is_added_registry = 1;
									this.resultExp[i].is_registry_status = 4;
								}
							} else {
								if (this.resultExp[i].is_added_registry == 1) {
									this.resultExp[i].is_added_registry = 0;
									this.resultExp[i].is_added_join_us = 0;
									this.resultExp[i].is_registry_status = 2;
								} else {
									this.resultExp[i].is_added_registry = 1;
									this.resultExp[i].is_added_join_us = 1;
									this.resultExp[i].is_registry_status = 1;
								}
							}
							if (Object.keys(this.experienceDetails).length > 0) {
								if (this.experienceDetails.id == exp_id) {
									this.experienceDetails.experience_status_msg = res.data.experience_status_msg;
									this.experienceDetails.status = (this.experienceDetails.status == 1) ? 0 : 1;
								}
							}
							break;
						}
					}
				}
				// this.messageService.sendExpStatusChangedToMainComponent({ 'exp_id': exp_id, 'added_registry': 1, 'status': true }); // send data to add Experience component.
				this.messageService.sendExpStatusChangedToMainComponent({ 'exp_id': exp_id, 'type': type, 'is_suggestion': is_suggestion, 'registry_for': registry_for, 'is_registry_status': res.data.is_registry_status, 'experience_status': res.data.is_registry_status, 'experience_status_msg': res.data.experience_status_msg, 'added_registry': 1, 'added_registry_type': res.data.added_registry_type, 'exp_data': this.experienceDetails });

				this.isLoading = false;
				this.experieneDetailPopupClose();
				// this.toastr.successToastr("Experience has been successfully added to registry", 'Success!'); 
			} else if (res.response == 'error') {
				this.isLoading = false;
				// this.toastr.errorToastr(res.message, 'Error!'); 
			}
			// this.isLoading = false; 
		});

		if (is_suggestion > 0) {
			// calls function to show modal if sugggestion exists.
			var requestbody = { 'experience_id': exp_id };
			this.addexperience.getExperienceSuggestionData(requestbody, this.tokenkey).subscribe(res => {
				if (res.response == 'success' && res.data.length > 0) {
					for (let i = 0; i < res.data.length; i++) {
						res.data[i].is_show_ckbx = 0;
						res.data[i].is_added_join_us = 0;
					}
					this.suggestionData = res.data;
					this.suggestedExperiencePopup.show();
				}
			});
		}
	}

	selected_child_exp(event) {
		this.isLoading = true;
		if (event.target.value == '') {
			this.avail_child_exp = false;
			this.exp_data_in_popup(this.main_exp_id);
		} else {
			this.exp_data_in_popup(event.target.value);
			this.avail_child_exp = true;
		}
	}
	exp_data_in_popup(id) {

		var requestbody = { 'experience_id': id, 'request_page': this.other_page_data };
		this.addexperience.getExperienceDetails(requestbody, this.tokenkey).subscribe(res => {
			if (res.response == 'success') {
				var responseData = res.data;
				if (responseData.variations_data.length > 0) {
					for (let k = 0; k < responseData.variations_data.length; k++) {
						if (responseData.variations_data[k].slug == 'buy-for-yourself') {
							responseData.is_buy_for_yourself = 1;
							responseData.buy_for_yourself_veriation_id = responseData.variations_data[k].id;
							break;
						} else {
							responseData.is_buy_for_yourself = 0;
							responseData.buy_for_yourself_veriation_id = 0;
						}
					}
				} else {
					responseData.is_buy_for_yourself = 0;
					responseData.buy_for_yourself_veriation_id = 0;
				}
				this.experienceDetails = responseData;
				this.additionalImages = this.experienceDetails.additional_images;
				this.isLoading = false;
			} else if (res.response == 'error') {
				this.isLoading = false;
				// this.toastr.errorToastr(res.message, 'Error!'); 
			}
			this.isLoading = false;
		});
	}

	getData(event) {

	}

	/* FUNCTION TO SHOW LOGIN MESSAGE POPUP */
	loginToContinue(template: TemplateRef<any>) {
		this.isLoading = true;
		this.modalRef = this.modalService.show(template);
		this.isLoading = false;
	}

	/* REMOVE PRODUCT FROM CART FUNCTION */
	proceedToLogin() {
		this.isLoading = true;
		this.router.navigate(['/login']);
		this.isLoading = false;
		this.modalRef.hide();
	}

	/* DECLINE TO REMOVE PRODUCT FROM CART FUNCTION */
	decline(): void {
		this.modalRef.hide();
	}

	uploadProfile() {
		this.congratulationPopuphide();
		this.upload_profile.show();
		this.is_profile_edit = false;
		this.is_cover_edit = false;
	}

	closePhoto() {
		this.upload_profile.hide()
	}



	uploadProfileImage($event) {
		this.images = []
		if (($event.target.files).length === 0) {
			return;
		}
		//console.log("uploadProfile", this.croppieDirective);
		this.fileFormat = $event.target.value.split('.')[1]
		if (this.fileFormat == "jpg" || this.fileFormat == "JPG" || this.fileFormat == "JPEG" || this.fileFormat == "jpeg" || this.fileFormat == "png" || this.fileFormat == "PNG") {
			var mimeType = $event.target.files[0].type;
			if (mimeType.match(/image\/*/) != null) {
				let reader = new FileReader();
				reader.readAsDataURL($event.target.files[0]);
				reader.onload = (e: any) => {
					this.images.push(e.target.result)
					this.upload_profile.hide()
					setTimeout(() => {
						this.large.show();
					}, 500);
					setTimeout(() => {
						if (this.isBrowser) {
							//console.log("uploadProfile2", this.croppieDirective);
							this.croppieDirective.croppie.bind({ url: e.target.result });
						}
					}, 1000);

				}
			}
		} else {
			alert("invalid file format")
			return;
		}
	}

	upload() {
		if (this.isBrowser) {
			document.getElementById('upload').click();
		}
	}

	uploadCoverImage() {
		this.img_type = 'cover_image';
		this.upload_profile.hide()
		this.upload_cover_profile_image.show()
	}

	showCropper() {
		this.fbAlbumPopup.hide();
		this.upload_cover_profile_image.hide();
		this.largecoverimage.show();
	}

	imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.base64;
	}

	imageLoaded() {
		this.showCoverImageCropper = true;
	}

	cropperReady(sourceImageDimensions: Dimensions) {
		// console.log('Cropper ready', sourceImageDimensions);
	}

	loadImageFailed() {
		//console.log('Load failed');
	}

	rotateLeftCropper() {
		this.canvasRotation = this.canvasRotation - 0.5;

		this.flipAfterRotate();
	}

	rotateRightCropper() {
		this.canvasRotation = this.canvasRotation + 0.5;

		this.flipAfterRotate();
	}

	private flipAfterRotate() {
		const flippedH = this.transform.flipH;
		const flippedV = this.transform.flipV;
		this.transform = {
			...this.transform,
			flipH: flippedV,
			flipV: flippedH
		};
	}

	updateRotation() {
		this.transform = {
			...this.transform,
			rotate: this.rotation
		};
	}

	resetImage() {
		this.scale = 1;
		this.rotation = 0;
		this.canvasRotation = 0;
		this.transform = {};
	}

	zoomOut() {
		this.scale -= .1;
		this.transform = {
			...this.transform,
			scale: this.scale
		};
	}

	zoomIn() {
		this.scale += .1;
		this.transform = {
			...this.transform,
			scale: this.scale
		};
	}

	saveCroppieCoverImage() {
		const $this = this;
		$this.isLoading = true;
		if (this.isBrowser) {
			let requestBody = { 'image': $this.croppedImage }
			$this.registerService.upload_cover_image(requestBody, $this.tokenkey).subscribe(res => {
				if (res.response == "success") {
					var updateAuthUser = JSON.parse(localStorage.getItem("authUser"));
					updateAuthUser.cover_image = res.data;
					$this.isLoading = false;
					localStorage.setItem("authUser", JSON.stringify(updateAuthUser));
					localStorage.setItem("cover_image_url", res.base_url + res.data);
					$this.cover_image_url = res.base_url + res.data;
					$this.largecoverimage.hide()
					$this.is_cover_image_edit = 1;

					if (this.isBrowser) {
						document.getElementById('cover_image_url').setAttribute("data-bg", res.base_url + res.data);
						document.getElementById('cover_image_url').style.background = "url('" + res.base_url + res.data + "')";
						document.getElementById('cover_image_url').style.backgroundSize = "cover";
					}

					$this.upload_cover_profile_image.show();
					//$this.authdataMessageService.sendProfilePicMessage($this.cover_image_url);
					//$this.messageService.sendMessage($this.cover_image_url)

				} else {
					//$this.toastr.errorToastr(res.message, 'Error!'); 
					$this.isLoading = false;
				}
			});
		}
	}

	fileChangeEvent(event: any): void {
		const $this = this;
		$this.upload_cover_profile_image.hide();

		var files = event.target.files;
		var filename = files[0].name;
		var extension = files[0].type;

		var file = files[0];
		var reader = new FileReader();
		var baseString;
		reader.onloadend = function () {
			$this.choosedCoverPhotoUrl = reader.result;
		};
		reader.readAsDataURL(file);

		this.imageChangedEvent = event;
		// this.upload_cover_profile_image.hide()
		this.largecoverimage.show();
	}

	checkTAB(tabdetail) {
		this.isActiveTab = tabdetail
	}

	close_certificate_popup() {
		this.pop_first_content = true;
		this.pop_second_content = false;
		this.pop_third_content = false;
		this.buymodal.hide();
		this.show_couple = false;
		this.submitted = false;
		this.no_couple_data_found = false;
		this.search_string = '';
	}

	couple_searching(search_key) {
		this.Loading = true;
		this.data1 = '';
		if (search_key != '') {
			this.findCoupleService.find_couples(this.page, search_key, this.tokenkey).subscribe(res => {
				if (res.response == 'success') {
					this.data1 = res.data.data.data;
					this.couple_image_url = res.base_url;
					this.show_couple = true;
					this.Loading = false;
					this.no_couple_data_found = false;
				}
				else {
					this.show_couple = true;
					this.no_couple_data_found = true;
					this.Loading = false;
				}
			});
		} else {
			this.show_couple = false;
			this.no_couple_data_found = false;
			setTimeout(() => {
				this.Loading = false;
			}, 1000);
		}
	}
	clean_search() {
		this.couple_searching('');
		this.search_string = '';
	}
	open_amount_section(couple_id, couple_full_name = '',wedding_date='') {
		if (couple_full_name == '') {
			if (this.authUser) {
				if (this.authUser.role_id == 5 || this.authUser.role_id == 1) {
					this.gift_certificate_couple_full_name = this.authUser.first_name + ' ' + this.authUser.last_name + ' & ' + this.authUser.partner_first_name + ' ' + this.authUser.partner_last_name;
				} else {
					this.gift_certificate_couple_full_name = this.authUser.first_name + ' ' + this.authUser.last_name
				}
			} else {
				this.gift_certificate_couple_full_name = '';
			}
		} else {
			this.gift_certificate_couple_full_name = couple_full_name;
		}
		this.Loading = true;
		if (couple_id == 0) {
			if (this.authUser) {
				this.selected_couple = this.authUser.id;
				this.wedding_date = this.authUser.wedding_date;
			} else {
				this.selected_couple = 0;
			}
		} else {
			this.selected_couple = couple_id;
			this.wedding_date = wedding_date;
		}

		setTimeout(() => {
			this.pop_first_content = false;
			this.pop_second_content = false;
			this.pop_third_content = true;
			this.Loading = false;
		}, 1000);
	}
	search_for_registered() {
		this.Loading = true;
		setTimeout(() => {
			this.pop_first_content = false;
			this.pop_second_content = true;
			this.Loading = false;
		}, 1000);
	}
	selected_amount(amount) {
		this.amount = amount;
	}
	redeem_confirmation() {
		this.showRedeemNowBtn = false;
		this.showRedeemScheduleBtn = false;
		this.showViewPrintBtn = false;
		this.onlineBookingMsgBlock = false;
		this.showRedeemNowConfirm = true;
		this.showRedeemNowCancel = true;
		this.showExchangeBtn = false;
		this.priceBlock = false;
		this.showSeasonHeadline = false;
		this.backfromRedemmeScheduleBtn = false;
	}

	loadImage(imageUrl) {
		this.addPhotoVideoFromDevice('photo');
		//this.photoVideoNextStep('image',1)
		//this.hide_back_button = false;
		const self = this;
		this.images = [];
		const xhr = new XMLHttpRequest()
		xhr.open("GET", imageUrl);
		xhr.responseType = "blob";
		xhr.send();
		xhr.addEventListener("load", function () {
			var reader = new FileReader();
			reader.readAsDataURL(xhr.response);
			reader.addEventListener("loadend", function () {
				self.images.push(reader.result);
				self.choosedSharePhotoUrl = reader.result;
				self.isLoading = true;
				setTimeout(() => {
					self.photoVideoNextStep('image', 1);
					self.hide_back_button = false;
					self.isLoading = false;
				}, 1000);
			});
		});
	}


	showCountDownTimer() {

		if (this.authUser.created_at != "") {

			if (this.authUser.gift_voucher_received == 0) {

				const $this = this;

				var timeRemaining = this.authUser.time_remaining;

				var startTimestamp = new Date().getTime();
				var endTimestamp = Number(startTimestamp) + Number(timeRemaining);

				var diffTimestamp = Number(endTimestamp) - Number(startTimestamp);

				//console.log('Start timestamp : -> '+startTimestamp);
				//console.log('End timestamp : -> '+endTimestamp);
				//console.log('Diff timestamp : -> '+diffTimestamp);


				//alert(diffTimestamp);

				if (diffTimestamp > 0) {
					//console.log('Timer difference available');
					$this.showCountDownText = true;
					$this.timerString = "";
					//this.countdown.begin();
					$this.generateCountTimer(diffTimestamp);

				} else {
					$this.showCountDownText = false;
					//console.log('timer issue 1');
					//$this.timerStartDate = "";
					$this.timerEndDate = "";
				}

			} else {
				this.showCountDownText = false;
			}
		}
	}


	minTwoDigits(n) {
		return (n < 10 ? '0' : '') + n;
	}

	handleTimerEvent(event) {

	}


	generateCountTimer(diffTimestamp) {

		// timerString
		const $this = this;

		this.timerString = '';
		clearInterval(this.timerIntervalFunc);

		this.timerIntervalFunc = setInterval(() => {
			if (Number(diffTimestamp) > 0) {

				var seconds = Math.floor((diffTimestamp) / (1000) % 60);
				var minutes = Math.floor((diffTimestamp) / (1000 * 60) % 60);
				var hours = Math.floor((diffTimestamp) / (1000 * 60 * 60) % 60);


				$this.timerString = $this.minTwoDigits(hours) + " : " + $this.minTwoDigits(minutes) + " : " + $this.minTwoDigits(seconds);

			}
			diffTimestamp -= Number(1000);
		}, 1000);
	}

	showFacebookAlbum() {
		this.upload_profile.hide();
		this.FacebookAlbum.sendMessage('message');
	}

	chooseFacebookAlbum(index) {
		if (this.facebookAlbums[index]) {

			if (typeof this.facebookAlbums[index].photos != "undefined" && typeof this.facebookAlbums[index].photos.data != "undefined" && this.facebookAlbums[index].photos.data.length > 0)
				this.choosedFacebookAlbums = this.facebookAlbums[index].photos.data;
		} else {
			this.choosedFacebookAlbums = [];
		}

		this.albumStep2 = true;
		this.albumStep1 = false;
	}

	backToFacebookAlbum() {
		this.albumStep2 = false;
		this.albumStep1 = true;
	}

	chooseProfilePhotoFromFacebookAlbum(imageUrl) {
		//alert(imageUrl);
		const self = this;
		// alert(self.fbImageType);
		this.images = [];
		const xhr = new XMLHttpRequest()
		xhr.open("GET", imageUrl);
		xhr.responseType = "blob";
		xhr.send();
		xhr.addEventListener("load", function () {
			var reader = new FileReader();
			reader.readAsDataURL(xhr.response);
			reader.addEventListener("loadend", function () {
				//console.log(reader.result);
				self.choosedFacebookPhotoUrl = reader.result;
				self.choosedCoverPhotoUrl = reader.result;
				if (self.fbImageType == 'profile_image') {
					self.initializeCropieOnProfilePhoto();
				} else if (self.fbImageType == 'cover_image') {
					self.showCropper();
				}
			});
		});
	}

	initializeCropieOnProfilePhoto() {
		this.large.show();
		this.fbAlbumPopup.hide();
		setTimeout(() => {
			if (this.isBrowser) {
				this.croppieDirective.croppie.bind({ url: this.choosedFacebookPhotoUrl });
			}
		}, 1000);

	}

	uploadFromFacebook(data) {
		//alert(123);
		//console.log(data);

		var imageType = data.image_type;
		let imageTypeData = { 'image_type': imageType };
		const $this = this;

		/** Condition check for charity user croppie popup will be Square Shape Starts **/
		if (typeof data.user_type != "undefined" && data.user_type == 'charity') {
			$this.cropyUserType = data.user_type;
		} else {
			$this.cropyUserType = '';
		}
		/** Condition check for charity user croppie popup will be Square Shape Ends **/

		$this.fbService.getFacebookAlbums().then((res: any) => {

			$this.facebookAlbums = [];
			$this.choosedFacebookAlbums = [];
			$this.albumStep2 = false;
			$this.albumStep1 = true;
			$this.fbImageType = imageType;

			if (res) {
				if (typeof res.albums != "undefined") {
					if (typeof res.albums.data != "undefined") {
						$this.facebookAlbums = res.albums.data;
					}
				}
			}
			$this.fbAlbumPopup.show();
		});

	}

	congratulationPopuphide(): void {
		this.congratulationPopup.hide();

		if (this.show_congratulation) {
			this.showCountDownText = false;
		}
	}

	experieneDetailPopupClose() {
		if (this.isBrowser) {
			var element = document.querySelector("body")
			var htmlelement = document.querySelector("html")
			if (element)
				element.classList.remove('overflow');
			if (htmlelement) {
				htmlelement.classList.remove('overflow');
			}
		}
		var video;
		if (this.isBrowser) {
			if (document.getElementById('main-video11')) {
				video = document.getElementById('main-video11')
				video.pause();
			}
		}
		this.experienceDetailsPopup.hide();

		//this.swiperCore.destroy(); 
	}

	onSwiper(event) {
		this.thumbsSwiper = event;
	}

	reviewClick() {
		if (this.isBrowser) {
			let reviewEle = document.querySelector('#reviews');
			let modalEle = document.querySelector('#experienceDetailsPopup .modal-content');
			//console.log("modalEle", modalEle);
			if (!reviewEle.classList.contains('show')) {
				let el: HTMLElement = this.review_accordion_element.nativeElement;
				el.click();
			}
			$("#modal-content").scrollTop(0);
			modalEle.scrollTo(0, 1650);
		}
	}

	autocompleteLocationClear() {
		//
	}


	autocompleteLocationSearch(event) {
		//console.log(event);
		let requestBody = { 'search': event.query };
		//console.log(requestBody);
		this.locationService.locationAutocomplete(requestBody).subscribe(res => {
			if (res.response == 'success') {
				this.locationAutocompleteList = res.data;
			} else {
				this.locationAutocompleteList = [];
			}
		});
	}

	onSelectAutocompleteLocation(event) {
		//this.exp_slug = event.value;
		switch (event.type) {
			case "location":
				if (event.value) {
					if (event.country_slug == null && event.state_slug == null) {
						this.router.navigate(['/continents/' + event.value]);
					} else if (event.country_slug == null && event.state_slug != null) {
						this.router.navigate(['/location-region/' + event.value]);
					} else if (event.country_slug != null && event.state_slug != null) {
						this.router.navigate(['/location-region/' + event.state_slug + '/' + event.value]);
					}
				}
				break;
		}
		this.autocomplete_location_search = '';
	}


	onLocationClick() {
		this.visitorService.getCurrent_location().then((res: any) => {
			//console.log(res);
			if (typeof res.address != 'undefined' && typeof res.address.address_components != 'undefined' && typeof res.address.address_components[6] != 'undefined' && typeof res.address.address_components[6].long_name != 'undefined') {
				let loc_region = res.address.address_components[6].long_name;
				loc_region = loc_region.toLowerCase();

				this.experience.getLocationPageContent(loc_region).subscribe(res => {
					if (res.response == 'success') {
						this.router.navigate(['/location-region/' + loc_region]);
					} else {
						this.router.navigate(['/location-region/united-states']);
					}
				});
			} else {
				this.router.navigate(['/location-region/united-states']);
			}
		});
	}

	getMobileDeviceName() {

		var userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;
		if (/windows phone/i.test(userAgent)) {
			this.mobileDeviceName = "Windows Phone";
		} else if (/android/i.test(userAgent)) {
			this.mobileDeviceName = "android";
		} else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			this.mobileDeviceName = "ios";
		} else {
			this.mobileDeviceName = "unknown";
		}
		//alert(this.mobileDeviceName);
	}

	name_error_msg() {
		this.name_error = false;
	}

	convertDateToUTC(date) { 
		return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()); 
	}

	expDetalPopupClose(event){
		if(this.backfromRedemmeScheduleBtn){
			this.backfromRedemmeSchedule();
		}
	}

	checkDateIsFutureDate(date){
		let startDate = new Date(date);
		let currDate = new Date();

		if(startDate > currDate){
			return true;
		}
		return false;
	}

	getPromotionalDetails(){
		this.isLoading = true;
		let requestBody	  =	{
			"exp_id":this.main_exp_id,
		}
		this.PromotionalService.getPromotionalData(requestBody).subscribe(res=>{
			if(res.response == 'success'){
				this.calendarOptions.events = res.data;
				if(res.data.length > 0){
					this.showPromoCalendar = true;
				}else{
					this.showPromoCalendar = false;
				} 
			}else{
				this.calendarOptions.events = []; 
				this.showPromoCalendar = false;
			}
			this.isLoading = false;
		});
	}

	handleDateClick(info) {
		if(info && this.experienceDetails.available_dates.includes(info.dateStr)){
			this.isLoading = true;
			this.slot = '';
			this.people_data = [];
			this.booking_price = 0;
			this.booking_price_with_tax = 0;
			this.selected_people = 0;
			this.no_slot_avail_message = '';
			this.booking_slot_data = [];
			this.fare_harbor_custom_data = [];
			this.requires_visitors_details = [];
			this.requestedDate = info.dateStr;
			if (this.experienceDetails.instant_book == 1) {
				let requestBody = { 'experience_id': this.experienceDetails.id, 'booking_date': this.requestedDate };
				this.experienceProviderAvailablityService.provider_availablity(requestBody, this.tokenkey).subscribe(res => {
					if (res.response == 'success') {
						switch (res.data.booking_type) {
							case 2:
								this.setDataForFareharbor(res.data);
								break;

							case 3:
								this.setDataForHornBlower(res.data);
								break;

							case 4:
								this.setDataForRezdy(res.data);
								break;

							case 5:
								this.setDataForTiqets(res.data);
								break;
							case 6:
								this.setDataForPeekOcta(res.data);
								break;
						}
					}
					else {
						this.request_to_book = false;
						this.show_instant_booking = true;
						this.isLoading = false;
						this.no_slot_avail_message = res.message;
						this.toastr.errorToastr(res.message, 'Error!');
					}
				})
			}
			else {
				this.isLoading = false;
				this.request_to_book = true;
				this.show_instant_booking = false;
			}
		}
	}

	handleBgDateClick(info){
		var d = new Date(info.date);
		var mm = "" + (d.getMonth() + 1); if (mm.length == 1) { mm = "0" + mm; };
		var dd = "" + d.getDate(); if (dd.length == 1) { dd = "0" + dd; };
		var yy = "" + d.getFullYear();
		let date            =   yy + "-" + mm + "-" + dd;
		if(!this.experienceDetails.available_dates.includes(date)){
		  info.isOther = true;
		}
	}

	/* handelSelectClick(info){
		if(info && this.experienceDetails.available_dates.includes(info.startStr)){
			this.isLoading = true;
			this.slot = '';
			this.people_data = [];
			this.booking_price = 0;
			this.booking_price_with_tax = 0;
			this.selected_people = 0;
			this.no_slot_avail_message = '';
			this.booking_slot_data = [];
			this.fare_harbor_custom_data = [];
			this.requestedDate = info.startStr;
			if (this.experienceDetails.instant_book == 1) {
				let requestBody = { 'experience_id': this.experienceDetails.id, 'booking_date': this.requestedDate };
				this.experienceProviderAvailablityService.provider_availablity(requestBody, this.tokenkey).subscribe(res => {
					if (res.response == 'success') {
						switch (res.data.booking_type) {
							case 2:
								this.setDataForFareharbor(res.data);
								break;

							case 3:
								this.setDataForHornBlower(res.data);
								break;

							case 4:
								this.setDataForRezdy(res.data);
								break;

							case 5:
								this.setDataForTiqets(res.data);
								break;
							case 6:
								this.setDataForPeekOcta(res.data);
								break;
						}
					}
					else {
						this.request_to_book = false;
						this.show_instant_booking = true;
						this.isLoading = false;
						this.no_slot_avail_message = res.message;
						this.toastr.errorToastr(res.message, 'Error!');
					}
				})
			}
			else {
				this.isLoading = false;
				this.request_to_book = true;
				this.show_instant_booking = false;
			}
			return true;
		}else{
			return false;
		}
	} */

	parseDateToObject(dateStr){
		let date = new Date(dateStr)
		return new Date(date.toISOString().slice(0, -1));
	}
}

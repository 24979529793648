import { Component, OnInit, HostListener , OnDestroy, PLATFORM_ID , Inject} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NavigationStart, NavigationEnd, Router } from '@angular/router';
import { MainService } from '../../../main.service';
import * as globalConstant from './../../../global_constant';
import { MessageService } from '../../../message.service';
import { AuthdataMessageService } from '../../../authdata-message.service';
import { Subscription } from 'rxjs';
declare var $: any;
declare var window: any;

@Component({
  selector: 'app-wedding-couple-subheader',
  templateUrl: './wedding-couple-subheader.component.html',
  styleUrls: ['./wedding-couple-subheader.component.css']
})
export class WeddingCoupleSubheaderComponent implements OnInit , OnDestroy {
  expUplodsUrl: string = globalConstant.EXPERIENCE_UPLOADS_URL;
  authCheck: boolean = false;
  public currentURL: string = "";
  public currentURL1: string = "";
  authUser: any;
  wallet_balance: number = 0;
  tokenkey: any;
  isLoading: boolean = false;
  is_cart_fill: boolean = false;
  total_sales_tax: number = 0;
  total_cart_amount: number = 0;
  cart_details: any = []
  show_card_details: any = false;
  location_id: any = '';
  total_friend_counter: any = 0;
  total_notification_counter: any = 0;
  authDataMessageSubscription: Subscription;
  getNotificationCountSubscription: Subscription;
  isBrowser: boolean;
  constructor(
    private router: Router,
    private mainservice: MainService,
	private messageService: MessageService,
	private authdataMessageService: AuthdataMessageService,
	@Inject(PLATFORM_ID) platformId: Object) 
  { 
	this.isBrowser = isPlatformBrowser(platformId);
	if(this.isBrowser){
		window.scrollTo(0,0);
	}
    this.currentURL = this.router.url.split("/")[1];
    this.currentURL1 = this.currentURL.split('?')[0];
    if (this.router.url.split("/")[3] != '' && this.router.url.split("/")[3] != undefined) {
      this.location_id = this.router.url.split("/")[3].split("=")[1]
    }
    this.router.events.subscribe(event => {

      if (event instanceof NavigationStart) {
        this.onWindowScroll();
      }
      if (event instanceof NavigationEnd) {
        this.currentURL = this.router.url.split("/")[1];
        this.currentURL1 = this.currentURL.split('?')[0];
        if (this.router.url.split("/")[3] != '' && this.router.url.split("/")[3] != undefined) {
          this.location_id = this.router.url.split("/")[3].split("=")[1]
        }
        if (localStorage.getItem('cart_details')) {
          this.cart_details = JSON.parse(localStorage.getItem('cart_details'));
          if (this.cart_details.length > 0) {
            this.is_cart_fill = true;
          }
          else {
            this.is_cart_fill = false;
          }
          this.total_sales_tax = 0;
          this.total_cart_amount = 0;
          for (let i = 0; i < this.cart_details.length; i++) {
            this.total_sales_tax = this.total_sales_tax + this.cart_details[i].tax
            this.total_cart_amount = this.total_cart_amount + this.cart_details[i].line_total
          }
        }
        this.getNotificationCountSubscription = this.messageService.getNotificationCount().subscribe(res => {
			//console.log(res);
			this.wallet_balance = res.data.wallet_amount;
			this.total_notification_counter = res.data.total_notification
			this.total_friend_counter = res.data.friend_notification
			
			//console.log("noti : " + this.total_notification_counter);
			//console.log("total_req : " + this.total_friend_counter);
		});
      }
    });

    if (localStorage.getItem('authUser')) {
      this.authCheck = true;
      this.tokenkey = localStorage.getItem('login_token');
    }
    else {
      this.authCheck = false;
    }
	this.authDataMessageSubscription = this.authdataMessageService.getAuthUserDataMessage().subscribe(messageData => {
		if (messageData) {
			this.authUser = JSON.parse(messageData);
		}
	});
  }

  ngOnInit() {
    if (localStorage.getItem('authUser')) {
      this.authCheck = true;
      this.authUser = JSON.parse(localStorage.getItem('authUser'));

    }
    else {
      this.authCheck = false;
    }

    // this.getWalletAmount();

    if (localStorage.getItem('cart_details')) {
      this.cart_details = JSON.parse(localStorage.getItem('cart_details'));
      if (this.cart_details.length > 0) {
        this.is_cart_fill = true;
      }
      else {
        this.is_cart_fill = false;
      }

      this.total_sales_tax = 0;
      this.total_cart_amount = 0;
      for (let i = 0; i < this.cart_details.length; i++) {
        this.total_sales_tax = this.total_sales_tax + this.cart_details[i].tax
        this.total_cart_amount = this.total_cart_amount + this.cart_details[i].line_total
      }
    }

    this.mainservice.getWalletAmount(this.tokenkey).subscribe(res => {
      if (res.response == 'success') {
        this.wallet_balance = res.data.wallet_amount;
        this.total_notification_counter = res.data.total_notification
        this.total_friend_counter = res.data.friend_notification
		
		//console.log("noti : " + this.total_notification_counter);
		//console.log("total_req : " + this.total_friend_counter);
      } else if (res.response == 'error') {
        this.wallet_balance = 0.00;
      }
    });

  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    // do some stuff here when the window is scrolled
	if(this.isBrowser){
		const verticalOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

		var navbar = document.getElementById("wedding-couple-subheader");
		if (navbar !== null && navbar !== undefined) {
		  if (verticalOffset < 145) {
			navbar.classList.remove('is-sticky');
		  }
		  if (verticalOffset > 145) {
			if (!navbar.classList.contains('is-sticky')) {
			  navbar.classList.add('is-sticky');
			}
		  }
		  if (verticalOffset > 145 * 2) {
			if (!navbar.classList.contains('awake')) {
			  navbar.classList.add('awake');
			}
		  }
		  if (verticalOffset < 145 * 2) {
			if (navbar.classList.contains('awake')) {
			  navbar.classList.remove('awake');
			}
		  }
		}
	}

  }
  owlCharityDetail = {
    mouseDrag: false,
    margin: 0,
    autoplayTimeout: 7000,
    transitionStyle: "slide",
    touchDrag: true,
    autoplay: true,
    lazyLoad: true,
    items: 1,
    smartSpeed: 500,
    nav: false,
    loop: true,
    dots: false,
    autoplayHoverPause: true,
  }

  ngAfterViewInit() {
	if(this.isBrowser){
		var subMenuBtn = document.getElementById("wedding-couple-menu-toggle");
		var weddingHead = document.getElementById("wedding-couple-subheader");
		var weddingHeadMenu = document.getElementById("weddingCoupleNav");
		var tagon = document.getElementById("totaltag_click");
		var tagwrap = document.getElementById("totaltag_clicked");
		if (subMenuBtn != null && subMenuBtn != undefined) {
		  subMenuBtn.addEventListener("click", (e) => {

			subMenuBtn.classList.toggle("clicked");

			if (subMenuBtn.classList.contains("clicked")) {
			  weddingHeadMenu.hidden = false;
			}
			else {
			  weddingHeadMenu.hidden = true;
			}

		  });
		  if (tagon != null && tagon != undefined) {
			tagon.addEventListener("click", (e) => {
			  tagwrap.classList.toggle("active");
			});
		  }
		}
    }

  }

  /* FUNCTION TO GET WALLET DETAILS */
  // getWalletAmount(){
  // 	this.isLoading = true; 
  // 	this.mainservice.getWalletAmount(this.tokenkey).subscribe(res=>{
  // 		if(res.response == 'success'){
  // 			this.wallet_balance = res.data.wallet_amount;
  // 		}else if(res.response == 'error'){
  // 			this.wallet_balance = 0;
  // 		}
  // 		this.isLoading = false; 
  // 	});
  // }

  checkout() {
    this.show_card_details = false
    this.router.navigate(['/checkout'])
  }

  getCartDetail() {
    this.show_card_details = !this.show_card_details
    if (this.show_card_details == true) {
      this.cart_details = []
      if (localStorage.getItem('cart_details')) {
        this.cart_details = JSON.parse(localStorage.getItem('cart_details'));
        if (this.cart_details.length > 0) {
          this.is_cart_fill = true;
        }
        else {
          this.is_cart_fill = false;
        }
        this.total_sales_tax = 0;
        this.total_cart_amount = 0;
        for (let i = 0; i < this.cart_details.length; i++) {
          this.total_sales_tax = this.total_sales_tax + this.cart_details[i].tax
          this.total_cart_amount = this.total_cart_amount + this.cart_details[i].line_total
        }
      }
    }
  }


  removeItem(product_id) {
    for (let i = 0; i < this.cart_details.length; i++) {
      if (product_id == this.cart_details[i].product_id) {
        this.cart_details.splice(i, 1);
        break;
      }

    }

    this.total_sales_tax = 0;
    this.total_cart_amount = 0;
    for (let i = 0; i < this.cart_details.length; i++) {
      this.total_sales_tax = this.total_sales_tax + this.cart_details[i].tax
      this.total_cart_amount = this.total_cart_amount + this.cart_details[i].line_total
    }

    if (this.cart_details.length > 0) {
      localStorage.setItem('cart_details', JSON.stringify(this.cart_details));
    }
    else {
      localStorage.removeItem('cart_details')
    }

  }
  ngOnDestroy() {
	// unsubscribe to ensure no memory leaks
	if(this.authDataMessageSubscription!=undefined && this.authDataMessageSubscription!=null)
	{
		this.authDataMessageSubscription.unsubscribe();
	}
	if(this.getNotificationCountSubscription!=undefined && this.getNotificationCountSubscription!=null)
	{
		this.getNotificationCountSubscription.unsubscribe();
	}
  }
}


// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  WEDDING_COUPLE_ROLE_ID:1,
  EXPERIENCE_PROVIDER_ROLE_ID:2,
  CHARITY_USER_ROLE_ID:3,
  INDIVIDUAL_USER_ROLE_ID:4,
  BBB_USER_ROLE_ID:5,
  CUSTOMER_SUPPORT_ROLE_ID:6,
  ALTERNATE_EXPERIENCE_PROVIDER_ROLE_ID:7,
  REGISTERFINDER_ROLE_ID:8,
  BBB_BABY_USER_ROLE_ID:9,
  KNOT_USER_ROLE_ID:10,
  JOY_USER_ROLE_ID:11
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

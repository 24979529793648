import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { catchError,map } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as globalConstant from './../global_constant';

@Injectable({
  providedIn: 'root'
})

export class ExperienceProviderAvailablityService {
  apiDomain					=	globalConstant.DOMAIN;
	siteRequestFrom			=	globalConstant.SITE_REQUEST_FROM;
	getAvailablityApi		=	globalConstant.GET_AVAILABLITY_API;
	updateAvailablityApi	=	globalConstant.UPDATE_AVAILABLITY_API;
  fareHarborApi			=	globalConstant.FARE_HARBOR_API;
	getProviderAvailablityApi			=	globalConstant.GET_PROVIDER_AVAILABLITY_API;
	getCalendarApi			      =	  globalConstant.GET_CALENDAR_DATES_API;

  constructor(public router: Router,private http: HttpClient) {
    
  }

  getAvailablityData(formData,headers){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'requestFrom':this.siteRequestFrom,
        'Authorization':'Bearer '+headers,
      })
    };
    return this.http.post<any>(this.apiDomain+this.getAvailablityApi,formData,httpOptions).pipe(map(res=>{
      return res;
    }));
  }

  updateAvailablityData(formData,headers){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'requestFrom':this.siteRequestFrom,
        'Authorization':'Bearer '+headers,
      })
    };
    return this.http.post<any>(this.apiDomain+this.updateAvailablityApi,formData,httpOptions).pipe(map(res=>{
      return res;
    }));
  }
  fare_harbor(formData,headers){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'requestFrom':this.siteRequestFrom,
        'Authorization':'Bearer '+headers,
      })
    };
    return this.http.post<any>(this.apiDomain+this.fareHarborApi,formData,httpOptions).pipe(map(res=>{
      return res;
    }));
  }
  provider_availablity(formData,headers){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'requestFrom':this.siteRequestFrom,
        'Authorization':'Bearer '+headers,
      })
    };
    return this.http.post<any>(this.apiDomain+this.getProviderAvailablityApi,formData,httpOptions).pipe(map(res=>{
      return res;
    }));
  }
  getCalendarData(formData, headers){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'requestFrom':this.siteRequestFrom,
        'Authorization':'Bearer '+headers,
      })
    };
    return this.http.post<any>(this.apiDomain+this.getCalendarApi,formData,httpOptions).pipe(map(res=>{
      return res;
    }));
  }
}
 
import { Component, OnInit,ViewChild , HostListener, PLATFORM_ID, Inject , OnDestroy } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NavigationStart, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthdataMessageService } from '../../../authdata-message.service';
declare var $: any;
declare var window: any;

@Component({
	selector: 'app-ep-subheader',
	templateUrl: './ep-subheader.component.html',
	styleUrls: ['./ep-subheader.component.css']
})
export class EpSubheaderComponent implements OnInit , OnDestroy {
	authUser: any;
	currentURL: any = '';
	currentURL1: any = '';
	isBrowser : boolean;

	authDataMessageSubscription: Subscription;

	constructor(
		private router: Router,
		private authdataMessageService: AuthdataMessageService,
		@Inject(PLATFORM_ID) platformId: Object
		)
	{
		this.isBrowser = isPlatformBrowser(platformId);
		if(this.isBrowser){
			window.scrollTo(0,0)
		}

		this.currentURL = this.router.url.split("/")[1];
		this.currentURL1 = this.currentURL.split("?")[0];
		if (localStorage.getItem('authUser')) {
			this.authUser = JSON.parse(localStorage.getItem('authUser'));
			//console.log(this.authUser.provider_require_online_scheduling);
		}
		//console.log(this.authUser);
		this.router.events.subscribe(event => {
			if (localStorage.getItem('authUser')) {
				this.authUser = JSON.parse(localStorage.getItem('authUser'));
			}
			

			if (event instanceof NavigationStart) {
				if(this.isBrowser){
					this.onWindowScroll();
				}
			}

			if (event instanceof NavigationEnd) {
				this.currentURL = this.router.url.split("/")[1];
				this.currentURL1 = this.currentURL.split("?")[0];

			}

		});
		
		//console.log(this.authUser);
		this.authDataMessageSubscription = this.authdataMessageService.getAuthUserDataMessage().subscribe(messageData => {
			if (messageData) {
				this.authUser = JSON.parse(messageData);
				console.log(this.authUser);
			}
		});

	}

	ngOnDestroy() {
		// unsubscribe to ensure no memory leaks
		if (this.authDataMessageSubscription != undefined && this.authDataMessageSubscription != null) {
			this.authDataMessageSubscription.unsubscribe();
		}
	}

	ngOnInit() {
		if (localStorage.getItem('authUser')) {
			this.authUser = JSON.parse(localStorage.getItem('authUser'));
		}

	}

	@HostListener("window:scroll", [])
	onWindowScroll() {
		// do some stuff here when the window is scrolled
		if(this.isBrowser){
			const verticalOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

			var navbar = document.getElementById("epdash-subheader");

			if (navbar !== null && navbar !== undefined) {
				if (verticalOffset < 145) {
					navbar.classList.remove('is-sticky');

				}
				if (verticalOffset > 145) {
					if (!navbar.classList.contains('is-sticky')) {
						navbar.classList.add('is-sticky');
					}
				}
				if (verticalOffset > 145 * 2) {
					if (!navbar.classList.contains('awake')) {
						navbar.classList.add('awake');
					}
				}
				if (verticalOffset < 145 * 2) {
					if (navbar.classList.contains('awake')) {
						navbar.classList.remove('awake');
					}
				}
			}
		}

	}


	ngAfterViewInit() {
		if(this.isBrowser){
			var subMenuBtn = document.getElementById("epdash-menu-toggle");
			var weddingHead = document.getElementById("epdash-subheader");
			var weddingHeadMenu = document.getElementById("epdash-menu-toggleNav");
			if (subMenuBtn != null || subMenuBtn != undefined) {
				subMenuBtn.addEventListener("click", (e) => {
					//weddingHead.classList.toggle("collapsed");
					subMenuBtn.classList.toggle("clicked");

					if (subMenuBtn.classList.contains("clicked")) {
						weddingHeadMenu.hidden = false;
					}
					else {
						weddingHeadMenu.hidden = true;
					}

				});
			}
		}
	}

}
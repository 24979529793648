import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MessageService } from './message.service';

 

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private router:Router,public toastr: ToastrManager,private messageService: MessageService,) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
				
				localStorage.removeItem('authUser');
				localStorage.removeItem('profile_img_url')
				localStorage.removeItem('login_token')
				localStorage.removeItem('cart_details');
				localStorage.removeItem('gift_box_details');
				this.toastr.errorToastr('Your session has expired please login again. ', 'Error!');
				// localStorage.removeItem('accepted')
				setTimeout(() => {
					window.location.href = '/login'
				}, 1000);

                const error = err.error.message || err.statusText;
                return throwError(error);
            }else{
            //this.toastr.errorToastr('Please check your internet connection and try again. ', 'Error!');
				//console.log("called interceptor")
				const error = err.error.message || err.statusText;
				return throwError(error);
			}
        }))
    }
}
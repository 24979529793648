import { BrowserModule,Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {TransferHttpCacheModule} from '@nguniversal/common';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule,HttpClient,HTTP_INTERCEPTORS  } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './share/shared.module';
import {RouterModule} from '@angular/router';
import { NgxCaptchaModule } from 'ngx-captcha';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { CommonModule, DatePipe } from '@angular/common'; 

import { AuthGuard  } from './guards/auth.guard';
import { GuestGuard  } from './guards/guest.guard';
import { ExpGuestGuard  } from './guards/expguest.guard';
import { ToastrModule } from 'ng6-toastr-notifications';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { OwlModule} from 'ngx-owl-carousel';

import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { WeddingCoupleSubheaderComponent } from './components/wedding-couple-after-login/wedding-couple-subheader/wedding-couple-subheader.component';
import { IndividualSubheaderComponent } from './components/individual-after-login/individual-subheader/individual-subheader.component';
import { CharitySubHeaderComponent } from './components/charity-after-login/charity-sub-header/charity-sub-header.component';
import { EpSubheaderComponent } from './components/ep-dashboard/ep-subheader/ep-subheader.component';
import { VeboticketingSubheaderComponent } from './components/veboticketing/veboticketing-subheader/veboticketing-subheader.component';
import { RegisterfinderSubheaderComponent } from './components/registerfinder-after-login/registerfinder-subheader/registerfinder-subheader.component'; 
import { AdditionalSubheaderComponent } from './components/additional-user-after-login/additional-subheader/additional-subheader.component';
import { ErrorInterceptor } from './error.interceptor';
import { AgmCoreModule } from '@agm/core';
import { SwiperModule } from 'swiper/angular';
import {AutoCompleteModule} from 'primeng/autocomplete';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // a plugin!

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin
]);


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    WeddingCoupleSubheaderComponent,
    IndividualSubheaderComponent,
    CharitySubHeaderComponent,
    EpSubheaderComponent,
    VeboticketingSubheaderComponent,
    RegisterfinderSubheaderComponent,
    AdditionalSubheaderComponent
  ],
  
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    BrowserModule,
	  BrowserModule.withServerTransition({ appId: 'universal-cli' }),
    TransferHttpCacheModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    SharedModule,
    RouterModule,
    CommonModule,
    CarouselModule,
    OwlModule,
    NgxCaptchaModule,
    SwiperModule,
    AutoCompleteModule,
    FullCalendarModule,
	  AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAzwqCO7sws38TcKsEqh5ke7pIN_ER2UZM&libraries=places&language=en',
      libraries: ['places']
    }),
    /* NgxGoogleAnalyticsModule.forRoot('MEASUREMENT-ID'),
    NgxGoogleAnalyticsRouterModule */
  ], 
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    Title,
    GuestGuard,
    AuthGuard,
    ExpGuestGuard,
	  DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

import { Injectable ,  ViewChild, ElementRef, NgZone } from '@angular/core';
import { HttpClient, HttpErrorResponse,HttpHeaders } from '@angular/common/http';
import { Observable, throwError , Subject } from 'rxjs';
import { catchError, map , retry } from 'rxjs/operators';
import { MapsAPILoader } from '@agm/core';
import * as globalConstant from './../global_constant';

@Injectable({
  providedIn: 'root'
})
export class VisitorsService {
  apiDomain				=	globalConstant.DOMAIN;
  siteRequestFrom			=	globalConstant.SITE_REQUEST_FROM;
  getUserLocationApi		=	globalConstant.GET_USER_LOCATION_API;
  
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;
  
  constructor(
    private http: HttpClient,
	private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
  ) 
  { 
	
	
  }
 
  getIpAddress() {
    return this.http
	  .get('https://api.ipify.org/?format=json')
	  .pipe(
		catchError(this.handleError)
	  );
  } 

   getGEOLocation(ip) {
  // Update your api key to get from https://ipgeolocation.io
  let url = "https://api.ipgeolocation.io/ipgeo?apiKey=9dd1ee4f3d294103adb57a64b3ac49d8&ip="+ip; 
    return this.http
          .get(url)
          .pipe(
            catchError(this.handleError)
          );
  }

    getUserLocation(data,headers){
	  //console.log(data);
      const httpOptions = {
        headers: new HttpHeaders({
          'requestFrom':this.siteRequestFrom,
          'Content-Type': 'application/json',
          'Authorization':headers == "" ?"":'Bearer '+headers
        })
      };
      return this.http.post<any>(this.apiDomain+this.getUserLocationApi,data,httpOptions).pipe(map(res=>{
        return res;
      }),
      catchError(err => {
        if(err.status == 401)
        {
          localStorage.removeItem('authUser')
          localStorage.removeItem('login_token')
          localStorage.removeItem('profile_img_url')
          window.location.href = '/login'
        }
        return err.error;
      }));
    }  

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }
  
  getCurrent_location():any{
		return new Promise((resolve, reject) => {
			var data = this.setCurrentLocation();	
			resolve(data);
			this.mapsAPILoader.load().then(() => {
			  this.geoCoder = new google.maps.Geocoder;
			});
			
		});
  }
  
  setCurrentLocation()  {
	return new Promise((resolve, reject) => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position:any) => {
			this.latitude = position.coords.latitude;
			this.longitude = position.coords.longitude;
			this.zoom = 8;
			var data = this.getAddress(this.latitude, this.longitude);
			resolve(data);
		},(error:any) => {
			resolve({'lat':'', 'long':'', 'address':''});
		});
      }
	});
   }
  
  
  getAddress(latitude, longitude) {
	return new Promise((resolve, reject) => {
		this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
		  if (status === 'OK') {
			if (results[0]) {
			  this.zoom = 12;
			  this.address = results[0].formatted_address;
			  var array = {'lat':latitude,'long':longitude,'address':results[0]}
			  resolve(array);
			}else{
				resolve({'lat':'', 'long':'', 'address':''});
			} 
		  }else{
			 resolve({'lat':'', 'long':'', 'address':''}); 
		  }
		  
		});
	});
  }

}

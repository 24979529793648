import { Pipe, PipeTransform } from '@angular/core'; 

@Pipe({
    name:'wordLimit'
})
export class WordLimit implements PipeTransform {
	transform(value:string, args:any[]): string{

		if(value != null && value != "" && value.length > 0){
			const limit = args.length > 0 ? parseInt(args[0]) : 100;
			const trail = args.length > 1 ? args[1] : '...';
			return value.length > limit ? value.substring(0, limit) + trail : value;
		}else{
			return value;
		}
    }
}
'use strict';
//import { environment } from '../../../environments/environment';
export var login_user_id,token;

// For Live server.
/* export const default_country				= 	449;   // on Live Server 
export const WEBSITE_URL					=   'https://spurexperiences.com/'
export const SITE_ADMIN_URL					=	'https://spurpanel.spurexperiences.com/'
export const DOMAIN							=	'https://node.spurexperiences.com/v2/' */
//---------------------------------------------------------------------------------------------------

/* // For New Stage server.
export const default_country				= 	449;   // on New Stage Server
export const WEBSITE_URL					=   'https://newstage.spurexperiences.com/'
export const SITE_ADMIN_URL					=	'https://newstageadmin.spurexperiences.com/'
export const DOMAIN							=	'https://newstagenode.spurexperiences.com/v2/'
//--------------------------------------------------------------------------------------------------- */

// For New Stage server.
export const default_country				= 	449;   // on New Stage Server
export const WEBSITE_URL					=   'https://uat.spurexperiences.com/'
export const SITE_ADMIN_URL					=	'https://panel-uat.spurexperiences.com/'
export const DOMAIN							=	'https://node-uat.spurexperiences.com/v2/'
//---------------------------------------------------------------------------------------------------


// For local system server
/* export const default_country				= 	265;   // on local system server
export const WEBSITE_URL					=   'http://localhost:4222/'
export const SITE_ADMIN_URL					=	'https://vebolifeadmin.stage04.obdemo.com/'
export const DOMAIN							=	'http://localhost:3003/v2/' */

//---------------------------------------------------------------------------------------------------

export const SITE_UPLOADS_URL				=	SITE_ADMIN_URL+'public/uploads/'
export const EXPERIENCE_UPLOADS_URL			=	SITE_UPLOADS_URL+'experience/'
export const SITE_KEY                       = 	'6LdmZf8ZAAAAAEEC-S8q--jY9JSsliSr7eWUlrwZ'
export const CAPTCHA_SECRET_KEY             = 	'6LdmZf8ZAAAAAHLU2AxMnvwV7hW7HeSRpIcSpVvx'

export const SUPER_ADMIN_ROLE_ID			=	1; 
export const WEDDING_COUPLE_ROLE_ID			=	1;
export const EXPERIENCE_PROVIDER_ROLE_ID	=	2; 
export const CHARITIES_ROLE_ID				=	3; 
export const INDIVIDUAL_MEMBER_ROLE_ID		=	4; 
export const BBB_USER_ROLE_ID				=	5;
export const BBB_BABY_USER_ROLE_ID			=	9;
export const KNOT_USER_ROLE_ID				=	10;
export const JOY_USER_ROLE_ID				=	11;

export const userItem : any = [
    {
        id : 0,
        text : 'All type'
    },
    {
        id : 1,
        text : 'Wedding Couple'
    },
    {
        id : 2,
        text : 'Experience Provider' 
    },
    {
        id : 3,
        text : 'Charity' 
    },
    {
        id : 4,
        text : 'Individual'
    },
    {
        id : 5,
        text : 'BBB' 
    },
    {
        id : 9,
        text : 'BBB BABY' 
    },
    {
        id : 10,
        text : 'KNOT' 
    },
    {
        id : 11,
        text : 'JOY' 
    },
];

export const SITE_TITLE						=	'Spur Experiences'
export const SITE_TITLE_SEPARATOR			=	'::'
//export const FACEBOOK_APP_ID				=	'1686651951620507'
export const FACEBOOK_APP_ID				=	'750757005024737'
export const FACEBOOK_APP_SECRET			=	'e19675c1115d887581f477a52485a561'
export const FACEBOOK_SOCIAL_LINK			=	'http://www.facebook.com/pages/Vebo/177434365796317'
export const LINKEDIN_SOCIAL_LINK			=	'https://www.linkedin.com/company/7043916'
export const PINTEREST_SOCIAL_LINK			=	'https://www.pinterest.com/SpurExperiences'
export const INSTAGRAM_SOCIAL_LINK			=	'https://www.instagram.com/spurexperiences/'
export const ANDROID_APP_DOWNLOAD_LINK		=	'https://play.google.com/store/apps/details?id=com.vebolife'
export const IOS_APP_DOWNLOAD_LINK		    =	'https://apps.apple.com/us/app/spur-experiences/id1123434344'

export const REVIEW_RATING_SCRIPT_URL		=	'https://apps.bazaarvoice.com/deployments/vebo/main_site/staging/en_US/bv.js'
export const EXP_PROVIDER_SLUG				=	'terms-and-conditions-experience'
export const SITE_REQUEST_FROM				=	'web'
export const DEVICE_TYPE	       			=	'desktop'
export const ENABLE_RFP_222					=  	true;
export const ENABLE_RFP_204					=  	true;
export const ENABLE_RFP_224					=  	true;
export const IS_LIVE						=  	true;
export const ENABLE_RFP_225                 =   true;
export const ENABLE_RFP_226                 =   true;
export const ENABLE_RFP_227                 =   true;
export const ENABLE_EXP_COVER_VIDEO         =   true;
export const ENABLE_RFP_229                 =   true;
export const ENABLE_RFP_232                 =   false;
export const ENABLE_RFP_233                 =   false;
export const ENABLE_RFP_236                 =   false;
export const ENABLE_RFP_237                 =   false;
export const ENABLE_RFP_238                 =   false;

export const INVAILD_NAME_STR               =   ['registry','knot','user','happy','couple'];

export const SIGNUP_API						=	'register'
export const FAQ_CATEGORY_API				=	'faq-category'
export const FAQ_DETAIL_API					=	'faq-detail'
export const FAQ_POST_API					=	'faq-post'
export const BBB_API						=	'bed-bath-beyond'
export const STATIC_COUPLE_API				=	'static-couples'
export const PRESS_SECTIONS_API				=	'press-sections'
export const LOGIN_API						=	'login'
export const GET_AUTH_USER_DATA				=	'get-auth-user'
export const VERIFY_USER_API				=   'verify-user'
export const REGISTER_STEP_1_API			=	'register/step/1'
export const REGISTER_STEP_2_API			=	'register/step/2' 
export const REGISTER_STEP_3_API			=	'register/step/3'
export const REGISTER_STEP_4_API			=	'register/step/4'
export const REGISTER_STEP_5_API			=	'register/step/5'
export const REGISTER_STEP_6_API			=	'register/step/6'
export const REGISTER_STEP_7_API			=	'register/step/7'
export const REGISTER_STEP_8_API			=	'register/step/8'
export const FORGOT_PASSWORD_API			=	'forgot-password'
export const CHECK_USERNAME_API				=	'check-username'
export const REGISTER_COUPLE_API			=	'registerCouple'
export const REGISTER_COUPLE_STEP_3_API		=	'registerCoupleStep3'
export const CHECK_EMAIL_API				=	'check-email'
export const CHECK_RESET_PASSWORD_TOKEN_API	=	'check-reset-password'
export const RESET_PASSWORD_API				=	'reset-password'
export const PAGE_SECTION_API				=	'page-sections'
export const HOME_SECTION_API				=	'home'
export const FOOTER_SECTION_API				=	'settings'
export const BLOG_CATEGORY_API				=   'blog-category'
export const BLOG_ARTICLE_API				=   'blogs'
export const BLOG_DETAILS_API				=   'blog-detail'
export const CATEGORIES_API					=   'get-category'
export const CATEGORIES_DETAIL				=   'category-blog'
export const PAGE_DATA_API					=   'page-data'
export const ARTICLE_API					=   'get-article'
export const UPLOAD_PROFILE_PICTURE_API	    =   'upload-profile-picture'
export const REGISTER_COUPLE_STEP_5_API	    =   'registerCoupleStep5'
export const GET_CATEGORY_DETAILS_API	    =   'get-category-details'
export const REGISTER_COUPLE_CHARITY_STEP_6_API	    		=   'registerCoupleCharityChooseStep6'
export const REGISTER_COUPLE_CHARITY_STEP_7_API	    		=   'registerCoupleCharityChooseStep7'
export const REGISTER_INDIVIDUAL_USER_API					=	'registerIndividualUser'
export const REGISTER_EXPERIENCE_USER_API					=	'registerExperienceUser'
export const EXPERIENCE_USER_SAVE_PAYMENT_INFO_STEP_2API	=	'experienceUserSavePaymentInfoStep2'
export const UPLOAD_COMPANY_LOGO_API						=	'upload-company-logo'
export const EXPERIENCE_USER_SAVE_REDEEM_INFO_STEP_4_API	=	'experienceUserSaveRedeemInfoStep4'
export const UPDATE_USER_FIELD_API						=	'UpdateUserField'
export const UPDATE_SHIPPING_SETTINGS_API				=	'UpdateShippingSettings'
export const UPDATE_PASSWORD_API						=	'UpdatePassword'
export const UPDATE_ACTIVE_STATUS_API					=	'updateActiveStatus'
export const INVITE_PAERTNER_API						=	'invitePartner'
export const REFER_FRIEND_API							=	'ReferFriend'
export const GET_VEBO_FRIENDS_API						=	'GetVeboFriends'
export const GET_VEBO_FRIENDS_BY_OTHER_API				=	'GetVeboFriendsByOther'
export const REMOVE_FRIEND_API							=	'RemoveFriend'
export const SEND_FRIEND_REQUEST_API					=	'send-friend-request'
export const CONFIRM_FRIEND_REQUEST_API					=	'confirm-friend-request'
export const REGISTER_CHARITY_USER_API					=	'registerCharityUser'
export const SAVE_CHARITY_USER_STEP_2_API				=	'saveCharityUserStep2'
export const SAVE_CHARITY_INFORMATION_STEP_3_API		=	'saveCharityInformationStep3'
export const SAVE_CHARITY_INFORMATION_STEP_4_API		=	'saveCharityInformationStep4'
export const SAVE_CHARITY_MAILING_ADDRESS_STEP_6_API	=	'saveCharityMailingAddressStep6'
export const SAVE_CHARITY_GALLERY_API					=	'save-gallery'
export const PROFILE_SETTING_API		    			=	'GetProfileSettings'
export const CREATE_EXPERIENCE_STEP_1_API		    	=	'create-experience-step1'
export const CREATE_EXPERIENCE_STEP_2_API		    	=	'create-experience-step2'
export const CREATE_EXPERIENCE_STEP_3_API		    	=	'create-experience-step3'
export const CREATE_EXPERIENCE_STEP_7_API		    	=	'create-experience-step7'
export const CREATE_EXPERIENCE_API		    			=	'create-experience'
export const UPDATE_CONTACT_SETTINGS_API		    	=	'UpdateContactSettings'
export const UPDATE_COMPANY_SETTINGS_API		    	=	'UpdateCompanySettings'
export const UPDATE_PAYMENT_INFO_SETTINGS_API		    =	'UpdatePaymentInfoSettings'
export const ADD_ALTERNATIVE_EXPERIENCE_PROVIDER_API	=	'AddAlternativeExperienceProvider'
export const UPDATE_ONLINE_SCHEDULING_API		   	 	=	'UpdateOnlineScheduling'
export const ALTERNATIVE_EXPERIENCE_PROVIDERS_API		=	'AlternativeExperienceProviders'
export const GET_COUPLES_API		    				=	'GetCouples'
export const GET_EXPERIENCES_API		    			=	'experiences'
export const GET_EXPERIENCES_DETAIL_API		    		=	'experiences-detail'
export const UPDATE_EXPERIRENCE_STEP1_API				=	'update-experience-step1'
export const UPDATE_EXPERIRENCE_STEP2_API				=	'update-experience-step2'
export const UPDATE_EXPERIRENCE_STEP3_API				=	'update-experience-step3'
export const UPDATE_EXPERIRENCE_STEP4_API				=	'update-experience-step4'
export const UPDATE_EXPERIRENCE_STEP5_API				=	'update-experience-step5'
export const UPDATE_EXPERIRENCE_STEP6_API				=	'update-experience-step6'
export const UPDATE_EXPERIRENCE_STEP7_API				=	'update-experience-step7'
export const UPDATE_CONTACT_SETTING_API		    		=	'UpdateContactSettings'
export const UPDATE_COMPANY_SETTING_API		    		=	'UpdateCompanySettings'
export const UPDATE_COMPANY_SOCIAL_LINK_API				=	'UpdateCompanySocialLinks'
export const MYCHARITY_API								=	'MyCharity'
export const UPDATE_CHARITY_FIELD_API					=	'UpdateCharityFields'
export const GALLERY_API								=	'Gallery'
export const REMOVEGALLERY_API							=	'RemoveGallery'
export const UPDATE_GALLERY_FEATURED_API				=	'UpdateGalleryFeatured'
export const UPDATE_CHARITY_CATEGORY_API				=	'UpdateCharityCategory'
export const UPLOAD_CHARITY_VIDEO						=	'upload-charity-video'
export const FAVOURITE_EXPERIANCE						=	'favourite-experience'
export const ACTIVE_COUPLES		    					=	'active-couples'
export const GET_CHARITIES	    						=	'get-charities'
export const MASTER_LOGIN	    						=	'master-login'
export const FIND_COUPLE_API	    					=	'find-couples'
export const GET_LOCATION_API	    					=	'get-location' 
export const GET_EXPERIENCE_CONTENT_API	    			=	'page-data'
export const GET_LOCATION_CONTENT_API	    			=	'get-location-by-slug'
export const GET_LOCATION_ROW_API	    				=	'get-location-row-by-slug'
export const GET_LOCATION_REGION_API	    			=	'get-location-region-by-slug'
export const GET_TOP_TEN_CATEGORY_API	    			=	'get-category-by-location'
export const GET_EXPERIENCE_BY_LOCATION_API	    		=	'get-experience-by-location'
export const GET_EXPERIENCE_BY_LOCATION_AND_CATEGORY_API =	'get-experience-by-location'
export const PROCESS_CHECKOUT_API	   					 =	'process-checkout'
// export const GET_ADD_EXPERIENCE_API	   					 =	'get-experience-by-country'
export const GET_ADD_EXPERIENCE_API	   					 =	'add-experiences'
export const GET_ADD_EXPERIENCE_CATEGORY_API	   		 =	'get-category'
export const GET_ADD_EXPERIENCE_COUNTRY_API	   			 =	'country-list' 
export const GET_ADD_EXPERIENCE_STATE_API	   			 =	'state-list'
export const GET_ADD_EXPERIENCE_CITY_API	   			 =	'city-list'
export const UPLOAD_COVER_PICTURE_API	   				 =	'UploadCoverPicture'
export const ADD_EXPERIENCE_REGISTERY_API	   			 =	'save-experience-registery'
export const GET_EXPERIENCE_DETAILS_DATA_API	   		 =	'get-experience-data'
export const GET_WALLET_AMOUNT_API	   			         =	'get-wallet-amount'
export const OUR_REGISTRY_API	   			     		 =	'our-registry'
export const GUEST_REGISTRY_API	   			     		 =	'guest-registry'
export const GET_HOME_PAGE_EXPERIENCE_API	   			 =	'get-experience-home'
export const OUTSIDE_GUEST_REGISTRY_API	   			     =	'outside-guest-registry'
export const GUEST_PROVIDER_API	   					     =	'guest-provider'
export const EXCHANGE_EXPERIENCE_API	   			     =	'exchange'
export const REDEEM_EXPERIENCE_API  	   			     =	'voucher-redeem'
export const GET_EXPERIENCE_BY_COUNTRY_API	   			 =	'get-experience-by-country'
export const UPDATE_EXPERIENCE_COVER_IMAGE_API	   		 =	'update-experience-cover-image'
export const GET_CHARITY	   		                     =	'get-charity'
export const GET_NEWS_FEED_API	   		                 =	'get-news-feed'
export const REVOKE_PARTNER_INVITATION	   		         =	'revoke-partner-invitation'
export const ACCEPT_PARTNER_INVITATION	   		         =	'accept-partner-invitation'
export const CHECK_PARTNER_INVITATION   		         =	'check-invitation-token'
export const THANK_YOU_API	   		                 	 =	'thank-you'
export const GET_SEARCH_AUTOCOMPLETE_FOR_LOCATION        =	'search-autocomplete-for-location'
export const GET_SEARCH_AUTOCOMPLETE_FOR_HOME_LOCATION   =	'search-autocomplete-for-home-location'
export const GET_SEARCH_AUTOCOMPLETE_FOR_OUR_REGISTRY	 =	'search-autocomplete-for-our-registry'
export const GET_SEARCH_AUTOCOMPLETE_FOR_GUEST_REGISTRY	 =	'search-autocomplete-for-guest-registry'
export const GET_SEARCH_AUTOCOMPLETE_FOR_ADD_EXPERIENCES =	'search-autocomplete-for-add-experiences'
export const GET_PROVIDER_LANDING_DATA  				 =	'get-provider-landing-data'
export const VOUCHER_REDEEM_DATA_API                	 =	'voucher-redeem-data'
export const OUR_REGISTRY_TAB_VIEW                  	 =	'our-registry-tab-view'
export const GIFT_TRACKER_API                  	 		 =	'gift-tracker'
export const GET_AVAILABLITY_API                	 	 =	'get-availability'
export const UPDATE_AVAILABLITY_API                	 	 =	'update-availability'
export const UPDATE_PROMOTION_API                	 	 =	'save-promotion-price'
export const GET_PROMOTION_API                	 	     =	'get-promotion-price'
export const GET_EXPERIENCE_BY_CATEGORY_API              =	'get-experience-by-category'
export const SEARCH_AUTOCOMPLETE_FOR_EXP_BY_CATEGORY     =	'search-autocomplete-for-experience-by-category'
export const DEACTIVATE_EXPERIENCE_REASON_API      	 	 =	'deactive-experience-reasons'
export const DEACTIVATE_EXPERIENCE_API      	 	 	 =	'deactive-experience'
export const CHARITY_COUPLE_API      	 	 			 =	'charity-couple'
export const CUSTOMER_SUPPORT_USER_LIST_API      	 	 =	'user-list'
export const UPDATE_EXPERIENCE_EXCLUSIVE      	 	 	 =	'update-experience-exclusive' 
export const TRACK_SALE_API				      	 	 	 =	'track-sale' 
export const TRACK_REFERRAL_LINK			      	 	 =	'track-referral-link' 
export const TICKET_EVENTS					      	 	 =	'ticket-evaluation/events' 
export const TICKET_EVENT_DETAIL			      	 	 =	'ticket-evaluation/event-detail'
export const TICKET_EVENT_GROUPS			      	 	 =	'ticket-evaluation/ticket-groups' 
export const SEND_CHAIRTY_THANK_YOU_EMAIL         	 	 =	'save-charity-message' 
export const CHARITY_COUPLE_REPORT_API		      	 	 =	'charity-couple-report'
export const GET_EXPERIENCE_SUGGESTION		      	 	 =	'experience-suggestion-data'
export const USER_DATA_API					      	 	 =	'user-data'
export const GIFT_BOX_API           		      	 	 =	'gift-box'
export const UPLOAD_EXPERIENCE_VIDEO					 =	'update-experience-video' 
export const REMOVE_EXPERIENCE_VIDEO					 =	'remove-experience-video'
export const ORDER_CANCEL_API						     =	'cancel-order'
export const PARTIAL_ORDER_SHARE						 =	'partial-order-share'
export const PARTIAL_ORDER_SHARE_EMAIL					 =	'partial-order-share-email'
export const OUR_TICKETS_API							 =	'ticket-evaluation/our-tickets'
export const PRINT_ETICKETS_API							 =	'ticket-evaluation/print-eticket'
export const GET_EXPERIENCE_BY_SLUG_API					 =	'get-experience-by-slug'
export const REMOVE_CHARITY_VIDEO_API					 =	'remove-charity-video'
export const EXPERIENCE_BOOKINGS_API					 =	'experience-bookings'
export const EXPERIENCE_BOOKINGS_DATA_API			     =	'experience-booking-data'
export const ACCEPT_REJECT_BOOKING_API				     =	'accept-reject-booking'
export const EXPERIENCE_BOOKINGS_REQUESTS_API		     =	'experience-booking-requests'
export const REQUEST_EXPERIENCE_BOOKING_API				 =	'request-to-booking'
export const FRAME_IMAGES_API				 			 =	'get-frame-images'
export const EXP_SHARE_PHOTO_VIDEO_API				 	 =	'save-sharing-data'
export const EXP_SEND_THANK_YOU_EMAIL_API				 =	'send-thankyou-email'
export const UPLOAD_THANK_YOU_IMAGE_API				 	 =	'upload-thankyou-image'
export const EXPERIENCE_LIST_DROPDOWN_API				 =	'experience-list-dropdown'
export const UPDATE_EXPERIENCE_FIELD_API				 =	'update-experience-fields'
export const VOUCHER_DATA_API						 	 =	'voucher-data'
export const REGISTER_BBB_USER_API					 	 =	'registerBBBUser'
export const REGISTER_BBB_USER_ADDRESS_INFO_API		 	 =	'registerBBBUserAddressInfo'
export const CLAIM_VOUCHER_API						 	 =	'claim-voucher'
export const FARE_HARBOR_API						 	 =	'fare-harbor/get-availability'
export const RESEND_INVITE_PAERTNER_API                  =  'resend-partner-invitation'
export const CANCEL_BOOKING_API                  		 =  'cancel-booking'
export const EXPERIENCE_SEARCH_BOOKINGS_API          	 =  'experience-search-booking'
export const EXPERIENCE_HOMEPAGE_BY_LOCATION_API      	 =  'experience-homepage-by-location'
export const PAYPAL_SETUP_INTENT_API      	 			 =  'paypal-payment'
export const ORDER_DATA_API      	 			 		 =  'get-order-data'
export const VOUCHER_REDEEM_API      	 			 	 =  'voucher-redeem'
export const GET_USER_LOCATION_API     	 			 	 =  'get-user-location'
export const CONVERT_IMAGE_TO_BASE64_API     	 		 =  'convertImageToBase64'
export const PARTIAL_ORDER_SHARE_EMAIL_API     	 		 =  'partial-order-share-email'
export const VIEW_ALL_VOUCHERS_API     	 		 		 =  'view-all-voucher'
export const SUBSCRIBE_API     	 		 		 		 =  'subscribe'
export const EXPERIENCE_ROW_API     	 		 		 =  'get-experience-row'
export const THANK_YOU_EMAIL_IMAGES_API     	 		 =  'send-thankyou-email-images'
export const GET_CURRENT_TIME_API     	 		 		 =  'get-current-time'
export const UPDATE_CHARITY_STATUS     	 		 		 =  'update-charity-status'
export const EXPERIENCE_SEARCH_BOOKING_OUTSIDE_INSTANT_API =  'experience-search-booking-outside-instant'
export const GET_EXPERIENCE_BY_CATEGORY_PAGINATION_API   =  'get-experience-by-category-pagination'
export const SAVE_EXPERIENCE_REVIEW_API 			     =  'save-experience-review'
export const EXPERIENCE_HOME_REGION_REGISTRATION_API     =  'experience-home-region-registration'
export const HOME_REGION_LIST_API                        =  'home-region-list'
export const SEARCH_EXPERIENCE_AUTOCOMPLETE_API          =  'search-experience-autocomplete'
export const CUSTOMER_SUPPORT_TICKET_FORM_API            =  'customer-support-ticket-form'
export const SAVE_CUSTOMER_SUPPORT_ISSUE_API             =  'save-customer-support-issue'
export const UPDATE_CUSTOMER_SUPPORT_API	             =  'update-customer-support-issue'
export const GET_CUSTOMER_SUPPORT_ISSUE_API      	     =  'get-customer-support-issues'
export const GET_CUSTOMER_SUPPORT_ISSUE_BY_ID_API        =  'get-customer-support-issue-by-id'
export const ADD_TO_CART_API        					 =  'add-to-cart'
export const UPDATE_CART_ITEM_API        				 =  'update-cart-item'
export const REMOVE_CART_ITEM_API        				 =  'delete-cart-item'
export const GET_CART_API        				 		 =  'get-cart'
export const SAVE_REFERRAL_SOURCE_API			 		 =  'save-referral-source'
export const UPDATE_HONEYMOON_SETTING_API		 		 =  'update-honeymoon-settings'
export const MY_ORDERS_API						 		 =  'my-orders'

export const GET_ADD_LOCATION_MENU_API              =  'location-menu'
export const GET_LOCATION_CONTINENT_API             =   'get-location-row-by-slug'
export const GET_EXPERIENCE_WITH_MAP_API             =  'get-near-experiences'
export const GET_HOME_CIRCLE_SECTION_API             =  'home-circle-section'
export const REGISTER_BBB_BABY_API                        =  'registerBabyUser'

export const SEARCH_LOCATION_AUTOCOMPLETE_API             =  'get-locations-autocomplete'

export const ABOUT_TEAM_SECTION_API				          =	'get-spur-teams'
export const GET_PROVIDER_AVAILABLITY_API                 =	'experience/get-availability'
export const GET_CALENDAR_DATES_API                       =	'experience/get-calendar-dates'

export const HOME_EXPERIENCE_API                          =  'get-home-experiences'

export const RECAPTCHA_VARIABLE          	             =  false
export const PAYPAL_CHECKOUT_ENABLE          	         =  false

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProfileService {
    private subject = new Subject<any>();
	private subjectPhoto = new Subject<any>();

    sendMessage(message: string) {
        this.subject.next( message );
    }

    clearMessage() {
        this.subject.next();
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
	
	sendProfileMessage(message: string) {
        this.subjectPhoto.next( message );
    }

    clearProfileMessage() {
        this.subjectPhoto.next();
    }

    getProfileMessage(): Observable<any> {
        return this.subjectPhoto.asObservable();
    }
}

import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { UserProfileService } from 'src/app/services/Userprofile.service';
@Injectable()
export class AuthGuard implements CanActivate {
    string:any;
	isBrowser: boolean;
	tokenkey: any	=	'';
	constructor(
		private router: Router,
		private userProfileService: UserProfileService,
		@Inject(PLATFORM_ID) platformId: Object
	) {
		this.isBrowser = isPlatformBrowser(platformId);
	}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if(this.isBrowser){
			if (localStorage.getItem('authUser')){
				const currentUser 		= 	JSON.parse(localStorage.getItem('authUser'));
				if(route.data.roles && route.data.roles.indexOf(currentUser.role_id) === -1) {
					// role not authorised so redirect to home page
					this.router.navigate(['/']);
					return false;
				}
				return true;      
			}else{
				// not logged in so redirect to login page with the return url
				this.router.navigate(['/login']);
				return false;
			}
		}
		return true;
    }
}
 
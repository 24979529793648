import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { catchError,map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import * as globalConstant from './../global_constant';
import { isPlatformBrowser } from '@angular/common';

import { MessageService } from 'src/app/message.service';
declare var window: any;
declare var FB: any;
@Injectable({
  providedIn: 'root'
})
export class FbService {
	isBrowser		: 	boolean;
	fbAppId 		: 	any 	=	globalConstant.FACEBOOK_APP_ID;
	siteUrl 		: 	string 	=	globalConstant.WEBSITE_URL;
	
	fbUser 			: 	any 	= 	[];
	
	
	constructor(
		public router: Router,
		private http: HttpClient,
		private messageService: MessageService,
		@Inject(PLATFORM_ID) platformId: Object
	) {
		this.loadFBSDK();
	}
	
	loadFBSDK() {
		var $this = this;
		//if($this.isBrowser){
			// This function initializes the FB variable 
			(function(d, s, id){
				var js, fjs = d.getElementsByTagName(s)[0];
				if (d.getElementById(id)) {return;}
				js = d.createElement(s); js.id = id;
				js.src = '//connect.facebook.net/en_US/sdk.js';
				if(fjs && fjs.parentNode){
					fjs.parentNode.insertBefore(js, fjs);
				}
			}(document, 'script', 'facebook-jssdk'));
			 
			window.fbAsyncInit = () => {
				//alert(123); 
				FB.init({ 
				  appId: $this.fbAppId,
				 // status: true, 
				  cookie: true, 
				  xfbml: true,
				  version: 'v2.4'
				});
			};
		//}
	}
	
	shareOnFacebook(shareData){
		//console.log(shareData);
		
		if(shareData.type == 'video'){
			
			FB.ui(
			{
				method: shareData.method,
				//name: shareData.title,
				link: shareData.media,
				//caption: shareData.caption,
				//description: shareData.description,
				//message: shareData.message
			});
		}else{
			FB.ui(
			{
				method: shareData.method,
				//name: shareData.title,
				//link: shareData.link,
				href: shareData.media,
				//caption: shareData.caption,
				//description: shareData.description,
				//message: shareData.message
			});
		}
		
	}
	
	
	postToFeed(data){
		var url 			=	data.url;
		var image 			=	data.image;
		var name 			=	data.name;
		var description 	=	data.description;
		
		FB.login((response) => {
			if (response.authResponse) {
				FB.ui({
					method: 'share_open_graph',
					action_type: 'og.shares',
					action_properties: JSON.stringify({
						object : {
						   'og:url': url, // your url to share
						   'og:title': name,
						   'og:site_name':'Spur Experiences',
						   'og:description': description,
						   'og:image': image,
						   //'og:image:width':'250',//size of image in pixel
						   //'og:image:height':'257',
						   //'og:image:type': 'image/jpeg'
						}
					}),
					display:'popup',
				},(response1) => {
					//console.log(response1);
				});
			} else {
				alert("User cancelled login or did not fully authorize.");
			}
		}, {scope: 'email, user_photos', return_scopes: true});
	}
	
	
	getFacebookAlbums(){
		
		return new Promise((resolve, reject) => {
			//var facebookAlbums 	=	[];
			var $this 	=	this;
			
			FB.login((response) => {
				//console.log(response.authResponse.accessToken);
				//console.log('login response',response);
				if (response.authResponse) {
				  var fields	= 	"id,name,email,picture.type(large),cover,albums.limit(500){name,picture,link,count,photos.type(large).limit(1000){link,picture.type(large),source}}";	
				  FB.api('/me', {
					fields: fields,
				  }, (userInfo) => {
		 
					//console.log("user information");
					//console.log(userInfo);
					
					$this.fbUser 	=	userInfo;
					
					resolve(userInfo);
					
				  });
				  
				 // resolve(postsArr);
				   
				} else {
				  console.log('User login failed');
				}
			}, {scope: 'email, user_photos', return_scopes: true});
			
		});
		
	}
	
	loginViaFacebook(){
		
		return new Promise((resolve, reject) => {
			//var facebookAlbums 	=	[];
			var $this 	=	this;
			FB.login((response) => {
				//console.log(response.authResponse.accessToken);
				//console.log('login response',response);
				if (response.authResponse) {
				  var fields	= 	"id,name,email,picture.type(large),cover,albums.limit(500){name,picture,link,count,photos.type(large).limit(1000){link,picture.type(large),source}}";	
				  FB.api('/me', {
					fields: fields,
				  }, (userInfo) => {
		 
					//console.log("user information");
					//console.log(userInfo);
					
					$this.fbUser 	=	userInfo;
					
				  });
				  
				 // resolve(postsArr);
				   
				} else {
				  //console.log('User login failed');
				}
			}, {scope: 'email, user_photos', return_scopes: true});
			
			resolve($this.fbUser);
			
		});
		
	}
	
	getFacebookUser(accessToken){
		var fields	= 	"id,name,email,picture.type(large),cover,albums.limit(500){name,picture,link,count,photos.type(large).limit(1000){link,picture.type(large),source}}";
		
		FB.api('/me', {
			fields: 'last_name, first_name, email',
			access_token: accessToken,
		}, (userInfo) => {
 
			//console.log("user information");
			//console.log(userInfo);
		});
	}
	
	/* Facebook image work*/
	logInWithFacebook(){
		FB.login((response) => {
			if (response.authResponse) {
			//alert('You are logged in &amp; cookie set!');
			//console.log(response.authResponse);
				var access_token = FB.getAuthResponse()['accessToken'];
				
			} else {
				// alert('User cancelled login or did not fully authorize.'); /
			}
		},{scope: 'email,user_photos', return_scopes: true});
		return false;
	};
	
	
	generateGalleryData(){
		/* $user = $response->getGraphUser();
		// echo 'Name: ' . $user['name']; /

		//$album = $user->albums;
		//echo "<pre>";

		$album_count = count($user['albums']);
		$output .= "<div class='facebook_images'>";
		
		for($x=0; $x<$album_count; $x++){
			$photo_count = $user['albums'][$x]['count'];
			$output .= "<div class='fbthumb_image_container face_img_indivisual'>";
			$output .= "<a href='javascript:void(0);' style='background-image:url(".$user['albums'][$x]['picture']['url'].")' data-album_id='".$user['albums'][$x]['id']."' class='album_img_thumb get_album_images_".$x."' id='get_album_images_".$x."'>";
			$output .= "<img class='img-responsive' src='".$user['albums'][$x]['picture']['url']."' alt=''>";
			$output .= "</a>";
			$output .= "<a id='get_album_imagess_".$x."' href='javascript:void(0);' data-album_id='".$user['albums'][$x]['id']."' class='album_img_name get_album_images_".$x."'>".$user['albums'][$x]['name']."<span>(".$photo_count.")</span></a>";
			$output .= "</div>";


			$output .= "<div class='folder_in' style='display:none;' id='folderIn_".$x."'>";
			$output .= "<div class='album_heading'><h5>".$user['albums'][$x]['name']."</h5><a href='javascript:void(0);' id='backToalbum' class='backToalbum'>back</a></div>
			";
			$output .= "<div class='clearfix'></div>";
			
			if($photo_count > 0){

				for($j=0; $j<$photo_count; $j++){

					$output .= "<div class='fbthumb_image_container'>";
					$output .= "<a href='javascript:void(0);' style=' background-image:url(".$user['albums'][$x]['photos'][$j]['source'].")' data-src_face='".$user['albums'][$x]['photos'][$j]['source']."' class='album_img_thumb facebook_img'>";
					$output .= "<img class='img-responsive' src='".$user['albums'][$x]['photos'][$j]['source']."' alt=''>";
					$output .= "</a>";
					$output .= "</div>";

				}

			}else{
				$output .= "<div class='no_images_album'>No images in this album.</div>";
			}
			$output .= "<input type='hidden' name='imageType' id='imageType' value='".$imageType."'></div>";

		}
		$output .= "</div>";
		
		if($output!=''){
			echo json_encode(array('succes'=>true, 'html'=> $output, 'message'=>__('succes')));

		} else {
			echo json_encode(array('succes'=>false, 'message'=>__('Inserted access token is not valid!')));
		} */
	}
	
}





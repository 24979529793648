import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { catchError,map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import * as globalConstant from './global_constant';

@Injectable({
  providedIn: 'root'
})
export class MainService {
	apiDomain						=	globalConstant.DOMAIN;
	categoryApi						=	globalConstant.CATEGORIES_API;
	getCategoryDetailsApi			=	globalConstant.GET_CATEGORY_DETAILS_API;
	siteRequestFrom					=	globalConstant.SITE_REQUEST_FROM;
	checkEmailApi					=	globalConstant.CHECK_EMAIL_API;
	checkUsernameApi				=	globalConstant.CHECK_USERNAME_API;
	processCheckoutApi				=	globalConstant.PROCESS_CHECKOUT_API;
	paypalSetupIntentApi			=	globalConstant.PAYPAL_SETUP_INTENT_API;
	orderDataApi					=	globalConstant.ORDER_DATA_API;
	getWalletAmountApi				=	globalConstant.GET_WALLET_AMOUNT_API;
	getAvailablityApi				=	globalConstant.GET_AVAILABLITY_API;
	deactivateExpReasonApi			=	globalConstant.DEACTIVATE_EXPERIENCE_REASON_API;
	deactivateExpApi				=	globalConstant.DEACTIVATE_EXPERIENCE_API;
	customerSupportUserListApi		=	globalConstant.CUSTOMER_SUPPORT_USER_LIST_API;
	giftBoxApi						=	globalConstant.GIFT_BOX_API;
	frameImagesApi					=	globalConstant.FRAME_IMAGES_API;
	convertImageUrlToBase64Api		=	globalConstant.CONVERT_IMAGE_TO_BASE64_API;
	subscribeApi					=	globalConstant.SUBSCRIBE_API;
	voucherRedeemApi        		= 	globalConstant.VOUCHER_REDEEM_API
	experienceRowApi    			=	globalConstant.EXPERIENCE_ROW_API;
	getCurrentTimeApi    			=	globalConstant.GET_CURRENT_TIME_API;
	addToCartApi    				=	globalConstant.ADD_TO_CART_API;
	updateCartItemApi    			=	globalConstant.UPDATE_CART_ITEM_API;
	removeCartItemApi    			=	globalConstant.REMOVE_CART_ITEM_API;
	getCartApi    					=	globalConstant.GET_CART_API;
	orderCancelApi    					=	globalConstant.ORDER_CANCEL_API;
	constructor(public router: Router,private http: HttpClient) {    

	}
  
  get_categories(formData){

	const httpOptions = { 
      headers: new HttpHeaders({
        //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
        'Content-Type': 'application/json',
        'requestFrom':this.siteRequestFrom
      })
    };
    return this.http.post<any>(this.apiDomain+this.categoryApi,formData,httpOptions).pipe(map(res=>{
      return res;
    }),
    catchError(err => {
      return err.error;
    }));
	
  }
  
  
  get_categorty_detail(formData){

      const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            'Content-Type': 'application/json',
            'requestFrom':this.siteRequestFrom
          })
        };
        return this.http.post<any>(this.apiDomain+this.getCategoryDetailsApi,formData,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          return err.error;
        }));
      }
  
      check_email(email){

        const httpOptions = {
            headers: new HttpHeaders({
              //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
              'Content-Type': 'application/json',
              'requestFrom':this.siteRequestFrom
            })
          };
          return this.http.post<any>(this.apiDomain+this.checkEmailApi,{'email':email},httpOptions).pipe(map(res=>{
            return res;
          }),
          catchError(err => {
            return err.error;
          }));
        }
        
      check_username(username){
        const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            'Content-Type': 'application/json',
            'requestFrom':this.siteRequestFrom
          })
        };
        return this.http.post<any>(this.apiDomain+this.checkUsernameApi,{'username':username},httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          return err.error;
        }));
      }
        
      process_checkout(formData,headers){
        const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            'Content-Type': 'application/json',
            'requestFrom':this.siteRequestFrom,
            'Authorization':'Bearer '+headers,
          })
        };
        return this.http.post<any>(this.apiDomain+this.processCheckoutApi,formData,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          return err.error;
        }));
      }

	paypalSetupIntent(formData,headers){
        const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            'Content-Type': 'application/json',
            'requestFrom':this.siteRequestFrom,
            'Authorization':'Bearer '+headers,
          })
        };
        return this.http.post<any>(this.apiDomain+this.paypalSetupIntentApi,formData,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          return err.error;
        }));
    }
	
	getOrderData(formData,headers){
        const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            'Content-Type': 'application/json',
            'requestFrom':this.siteRequestFrom,
            'Authorization':'Bearer '+headers,
          })
        };
        return this.http.post<any>(this.apiDomain+this.orderDataApi,formData,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          return err.error;
        }));
    }

      getWalletAmount(headers){
        const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            'Content-Type': 'application/json',
            'requestFrom':this.siteRequestFrom,
            'Authorization':'Bearer '+headers,
          })
        };
        return this.http.get<any>(this.apiDomain+this.getWalletAmountApi,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          if(err.status == 401)
			{
			  localStorage.removeItem('authUser')
			  localStorage.removeItem('login_token')
			  localStorage.removeItem('profile_img_url')
			  window.location.href = '/login'
			}
			return err.error;
        }));
      }   

      getAvailablityData(formData,headers){
        const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            'Content-Type': 'application/json',
            'requestFrom':this.siteRequestFrom,
            'Authorization':'Bearer '+headers,
          })
        };
        //console.log(httpOptions);
        return this.http.post<any>(this.apiDomain+this.getAvailablityApi,formData,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          return err.error;
        }));
      }  

      
      deactivateExpReason(headers,experience_id){
        const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            'Content-Type': 'application/json',
            'requestFrom':this.siteRequestFrom,
            'Authorization':'Bearer '+headers,
          })
        };
        return this.http.post<any>(this.apiDomain+this.deactivateExpReasonApi,{'experience_id':experience_id},httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          return err.error;
        }));
      }
      deactivateExp(headers,formData){
        const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            'Content-Type': 'application/json',
            'requestFrom':this.siteRequestFrom,
            'Authorization':'Bearer '+headers,
          })
        };
        return this.http.post<any>(this.apiDomain+this.deactivateExpApi,formData,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          return err.error;
        }));
      }
      
      getCustomerSupportUserList(headers,role_id,search=''){
        const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            'Content-Type': 'application/json',
            'requestFrom':this.siteRequestFrom,
            'Authorization':'Bearer '+headers,
          })
        };
        return this.http.post<any>(this.apiDomain+this.customerSupportUserListApi,{'role_id':role_id,'search' : search},httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          return err.error;
        }));
      }

      getGiftBox(headers){
        const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            'Content-Type': 'application/json',
            'requestFrom':this.siteRequestFrom,
            'Authorization':headers == "" ? "":'Bearer '+headers
          })
        };
        return this.http.get<any>(this.apiDomain+this.giftBoxApi,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          return err.error;
        }));
      }
	  
	getFrameImages(headers){
        const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            'Content-Type': 'application/json',
            'requestFrom':this.siteRequestFrom,
            'Authorization':'Bearer '+headers,
          })
        };
        return this.http.get<any>(this.apiDomain+this.frameImagesApi,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          return err.error;
        }));
    }
    

  voucherRedeem(formData,headers){

    const httpOptions = { 
        headers: new HttpHeaders({
          //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
          'Content-Type': 'application/json',
          'requestFrom':this.siteRequestFrom,
          'Authorization':'Bearer '+headers,
        })
      };
      return this.http.post<any>(this.apiDomain+this.voucherRedeemApi,formData,httpOptions).pipe(map(res=>{
        return res;
      }),
      catchError(err => {
        return err.error;
      }));
    
    }
	
	
	convert_image_url_to_base_64(imageUrl){

	const httpOptions = {
		headers: new HttpHeaders({
		  //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
		  'Content-Type': 'application/json',
		  'requestFrom':this.siteRequestFrom
		})
	  };
	  return this.http.post<any>(this.apiDomain+this.convertImageUrlToBase64Api,{'image_url':imageUrl},httpOptions).pipe(map(res=>{
		return res;
	  }),
	  catchError(err => {
		return err.error;
	  }));
	}
	
	subscribeUser(formData){

		const httpOptions = {
			headers: new HttpHeaders({
			//'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
			'Content-Type': 'application/json',
			'requestFrom':this.siteRequestFrom
			})
		};
		return this.http.post<any>(this.apiDomain+this.subscribeApi,formData,httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			return err.error;
		}));
	}
	
	getExperienceRow(formData){

		const httpOptions = {
			headers: new HttpHeaders({
			//'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
			'Content-Type': 'application/json',
			'requestFrom':this.siteRequestFrom
			})
		};
		return this.http.post<any>(this.apiDomain+this.experienceRowApi,formData,httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			return err.error;
		}));
	}
	
	getCurrentTime(formData){

		const httpOptions = {
			headers: new HttpHeaders({
			//'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
			'Content-Type': 'application/json',
			'requestFrom':this.siteRequestFrom
			})
		};
		return this.http.post<any>(this.apiDomain+this.getCurrentTimeApi,formData,httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			return err.error;
		}));
	}
	
	addToCart(formData){

		const httpOptions = {
			headers: new HttpHeaders({
			//'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
			'Content-Type': 'application/json',
			'requestFrom':this.siteRequestFrom
			})
		};
		return this.http.post<any>(this.apiDomain+this.addToCartApi,formData,httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			return err.error;
		}));
	}
	
	updateCartItem(formData){

		const httpOptions = {
			headers: new HttpHeaders({
			//'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
			'Content-Type': 'application/json',
			'requestFrom':this.siteRequestFrom
			})
		};
		return this.http.post<any>(this.apiDomain+this.updateCartItemApi,formData,httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			return err.error;
		}));
	}
	
	removeCartItem(formData){

		const httpOptions = {
			headers: new HttpHeaders({
			//'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
			'Content-Type': 'application/json',
			'requestFrom':this.siteRequestFrom
			})
		};
		return this.http.post<any>(this.apiDomain+this.removeCartItemApi,formData,httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			return err.error;
		}));
	}
	
	getCartData(formData){

		const httpOptions = {
			headers: new HttpHeaders({
			//'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
			'Content-Type': 'application/json',
			'requestFrom':this.siteRequestFrom
			})
		};
		return this.http.post<any>(this.apiDomain+this.getCartApi,formData,httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			return err.error;
		}));
	}

  updateOrderCancel(formData){

		const httpOptions = {
			headers: new HttpHeaders({
			//'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
			'Content-Type': 'application/json',
			'requestFrom':this.siteRequestFrom
			})
		};
		return this.http.post<any>(this.apiDomain+this.orderCancelApi,formData,httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			return err.error;
		}));
	}

}





import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { catchError,map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import * as globalConstant from './../global_constant';

@Injectable({
  providedIn: 'root'
})
export class SettingsService { 
  apiDomain									=	globalConstant.DOMAIN;
  footerApi									=	globalConstant.FOOTER_SECTION_API;
  updateUserFieldApi						=	globalConstant.UPDATE_USER_FIELD_API;
  updateShippingSettingsApi					=	globalConstant.UPDATE_SHIPPING_SETTINGS_API;
  updatePasswordApi							=	globalConstant.UPDATE_PASSWORD_API;
  updateActiveStatusApi						=	globalConstant.UPDATE_ACTIVE_STATUS_API;
  invitePartnerApi							=	globalConstant.INVITE_PAERTNER_API;
  siteRequestFrom							=	globalConstant.SITE_REQUEST_FROM;
  expUpdateContactSettingApi				=	globalConstant.UPDATE_CONTACT_SETTINGS_API;
  expUpdateCompanySettingApi				=	globalConstant.UPDATE_COMPANY_SETTINGS_API;
  expUpdatePaymentInfoSettingApi			=	globalConstant.UPDATE_PAYMENT_INFO_SETTINGS_API;
  expAddAlternativeExperienceProviderApi	=	globalConstant.ADD_ALTERNATIVE_EXPERIENCE_PROVIDER_API;
  expUpdateOnlineSchedulingApi				=	globalConstant.UPDATE_ONLINE_SCHEDULING_API;
  getAlternativeExperienceProvidersApi		=	globalConstant.ALTERNATIVE_EXPERIENCE_PROVIDERS_API;
  myCharityApi								=	globalConstant.MYCHARITY_API;
  updateCompanySocialLinkApi				=	globalConstant.UPDATE_COMPANY_SOCIAL_LINK_API;
  updateContactSettingApi					=	globalConstant.UPDATE_CONTACT_SETTING_API;
  updateCompanySettingApi				  	=	globalConstant.UPDATE_COMPANY_SETTING_API;
  deviceType  							  	= 	globalConstant.DEVICE_TYPE;
  revokePartnerInvitationApi  			  	= 	globalConstant.REVOKE_PARTNER_INVITATION	;
  acceptPartnerInvitationApi  			  	= 	globalConstant.ACCEPT_PARTNER_INVITATION	;
  checkPartnerInvitationApi  			  	= 	globalConstant.CHECK_PARTNER_INVITATION	;
  resendinvitePartnerApi					=	globalConstant.RESEND_INVITE_PAERTNER_API;
  myOrdersApi								=	globalConstant.MY_ORDERS_API;
  

  constructor(public router: Router,private http: HttpClient) {  
    if(window.orientation > -1){
      this.deviceType = "mobile";
    }else{
      this.deviceType = "desktop";
    }

  } 

  get_settings(){

	const httpOptions = {
      headers: new HttpHeaders({
        //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
        //'Content-Type': 'application/x-www-form-urlencoded',
        'requestFrom':this.siteRequestFrom,
      })
    };
    return this.http.get<any>(this.apiDomain+this.footerApi,httpOptions).pipe(map(res=>{
      return res;
    }),
    catchError(err => {
      return err.error;
    }));
  }
  

  update_user_field(data,headers){

    const httpOptions = {
        headers: new HttpHeaders({
          //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
          'Content-Type': 'application/json',
          'Authorization':'Bearer '+headers,
          'requestFrom':this.siteRequestFrom,
          'device' :  this.deviceType
        })
      };
      return this.http.post<any>(this.apiDomain+this.updateUserFieldApi,data,httpOptions).pipe(map(res=>{
        return res;
      }),
      catchError(err => {
        /* if(err.status == 401)
        {
          localStorage.removeItem('authUser')
          localStorage.removeItem('login_token')
          localStorage.removeItem('profile_img_url')
          window.location.href = '/login'
        } */
        return err.error;
      }));
  }


  update_shipping_settings_api(data,headers){

    const httpOptions = {
        headers: new HttpHeaders({
          //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
          'Content-Type': 'application/json',
          'Authorization':'Bearer '+headers,
          'requestFrom':this.siteRequestFrom,
        })
      };
      return this.http.post<any>(this.apiDomain+this.updateShippingSettingsApi,data,httpOptions).pipe(map(res=>{
        return res;
      }),
      catchError(err => {
        if(err.status == 401)
        {
          localStorage.removeItem('authUser')
          localStorage.removeItem('login_token')
          localStorage.removeItem('profile_img_url')
          window.location.href = '/login'
        }
        return err.error;
      }));
  }

  update_password_api(data,headers){

    const httpOptions = {
        headers: new HttpHeaders({
          //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
          'Content-Type': 'application/json',
          'Authorization':'Bearer '+headers,
          'requestFrom':this.siteRequestFrom,
        })
      };
      return this.http.post<any>(this.apiDomain+this.updatePasswordApi,data,httpOptions).pipe(map(res=>{
        return res;
      }),
      catchError(err => {
        if(err.status == 401)
        {
          localStorage.removeItem('authUser')
          localStorage.removeItem('login_token')
          localStorage.removeItem('profile_img_url')
          window.location.href = '/login'
        }
        return err.error;
      }));
  }


  update_active_status(data,headers){

    const httpOptions = {
        headers: new HttpHeaders({
          //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
          'Content-Type': 'application/json',
          'Authorization':'Bearer '+headers,
          'requestFrom':this.siteRequestFrom,
        })
      };
      return this.http.post<any>(this.apiDomain+this.updateActiveStatusApi,data,httpOptions).pipe(map(res=>{
        return res;
      }),
      catchError(err => {
        if(err.status == 401)
        {
          localStorage.removeItem('authUser')
          localStorage.removeItem('login_token')
          localStorage.removeItem('profile_img_url')
          window.location.href = '/login'
        }
        return err.error;
      }));
  }

  invite_partner(data,headers){

    const httpOptions = {
        headers: new HttpHeaders({
          //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
          'Content-Type': 'application/json',
          'Authorization':'Bearer '+headers,
          'requestFrom':this.siteRequestFrom,
        })
      };
      return this.http.post<any>(this.apiDomain+this.invitePartnerApi,data,httpOptions).pipe(map(res=>{
        return res;
      }),
      catchError(err => {
        if(err.status == 401)
        {
          localStorage.removeItem('authUser')
          localStorage.removeItem('login_token')
          localStorage.removeItem('profile_img_url')
          window.location.href = '/login'
        }
        return err.error;
      }));
  }


  resend_invite_partner(data,headers){

    const httpOptions = {
        headers: new HttpHeaders({
          //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
          'Content-Type': 'application/json',
          'Authorization':'Bearer '+headers,
          'requestFrom':this.siteRequestFrom,
        })
      };
      return this.http.post<any>(this.apiDomain+this.resendinvitePartnerApi,data,httpOptions).pipe(map(res=>{
        return res;
      }),
      catchError(err => {
        if(err.status == 401)
        {
          localStorage.removeItem('authUser')
          localStorage.removeItem('login_token')
          localStorage.removeItem('profile_img_url')
          window.location.href = '/login'
        }
        return err.error;
      }));
  }

  exp_update_contact_settings(data,headers){

    const httpOptions = {
        headers: new HttpHeaders({
          //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
          'Content-Type': 'application/json',
          'Authorization':'Bearer '+headers,
          'requestFrom':this.siteRequestFrom,
        })
      };
      return this.http.post<any>(this.apiDomain+this.expUpdateContactSettingApi,data,httpOptions).pipe(map(res=>{
        return res;
      }),
      catchError(err => {
        if(err.status == 401)
        {
          localStorage.removeItem('authUser')
          localStorage.removeItem('login_token')
          localStorage.removeItem('profile_img_url')
          window.location.href = '/login'
        }
        return err.error;
      }));
  }

  exp_update_company_settings(data,headers){

    const httpOptions = {
        headers: new HttpHeaders({
          //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
          //'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization':'Bearer '+headers,
          'requestFrom':this.siteRequestFrom,
        })
      };
      return this.http.post<any>(this.apiDomain+this.expUpdateCompanySettingApi,data,httpOptions).pipe(map(res=>{
        return res;
      }),
      catchError(err => {
        if(err.status == 401)
        {
          localStorage.removeItem('authUser')
          localStorage.removeItem('login_token')
          localStorage.removeItem('profile_img_url')
          window.location.href = '/login'
        }
        return err.error;
      }));
  }
  
  exp_update_payment_info_setting(data,headers){

    const httpOptions = {
        headers: new HttpHeaders({
          //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
          'Content-Type': 'application/json',
          'Authorization':'Bearer '+headers,
          'requestFrom':this.siteRequestFrom,
        })
      };
      return this.http.post<any>(this.apiDomain+this.expUpdatePaymentInfoSettingApi,data,httpOptions).pipe(map(res=>{
        return res;
      }),
      catchError(err => {
        if(err.status == 401)
        {
          localStorage.removeItem('authUser')
          localStorage.removeItem('login_token')
          localStorage.removeItem('profile_img_url')
          window.location.href = '/login'
        }
        return err.error;
      }));
  }
  
  exp_add_alternative_experience_provider(data,headers){

    const httpOptions = {
        headers: new HttpHeaders({
          //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
          'Content-Type': 'application/json',
          'Authorization':'Bearer '+headers,
          'requestFrom':this.siteRequestFrom,
        })
      };
      return this.http.post<any>(this.apiDomain+this.expAddAlternativeExperienceProviderApi,data,httpOptions).pipe(map(res=>{
        return res;
      }),
      catchError(err => {
        if(err.status == 401)
        {
          localStorage.removeItem('authUser')
          localStorage.removeItem('login_token')
          localStorage.removeItem('profile_img_url')
          window.location.href = '/login'
        }
        return err.error;
      }));
  }

  update_online_scheduling(data,headers){

    const httpOptions = {
        headers: new HttpHeaders({
          //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
          'Content-Type': 'application/json',
          'Authorization':'Bearer '+headers,
          'requestFrom':this.siteRequestFrom,
        })
      };
      return this.http.post<any>(this.apiDomain+this.expUpdateOnlineSchedulingApi,data,httpOptions).pipe(map(res=>{
        return res;
      }),
      catchError(err => {
        if(err.status == 401)
        {
          localStorage.removeItem('authUser')
          localStorage.removeItem('login_token')
          localStorage.removeItem('profile_img_url')
          window.location.href = '/login'
        }
        return err.error;
      }));
  }

  
  get_alternative_experience_provider(headers){

    const httpOptions = {
        headers: new HttpHeaders({
          //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
          //'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization':'Bearer '+headers,
          'requestFrom':this.siteRequestFrom,
        })
      };
      return this.http.get<any>(this.apiDomain+this.getAlternativeExperienceProvidersApi,httpOptions).pipe(map(res=>{
        return res;
      }),
      catchError(err => {
        if(err.status == 401)
        {
          localStorage.removeItem('authUser')
          localStorage.removeItem('login_token')
          localStorage.removeItem('profile_img_url')
          window.location.href = '/login'
        }
        return err.error;
      }));
  }

  my_charity(headers){
    const httpOptions = {
        headers: new HttpHeaders({
          //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
          //'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization':'Bearer '+headers,
    'requestFrom':this.siteRequestFrom
        })
      };
      return this.http.get<any>(this.apiDomain+this.myCharityApi,httpOptions).pipe(map(res=>{
        return res;
      }),
      catchError(err => {
        if(err.status == 401)
        {
          localStorage.removeItem('authUser')
          localStorage.removeItem('login_token')
          localStorage.removeItem('profile_img_url')
          window.location.href = '/login'
        } 
        return err.error;
      }));
    }  


    update_company_social(data,headers){

      const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+headers,
        'requestFrom':this.siteRequestFrom
          })
        };
        return this.http.post<any>(this.apiDomain+this.updateCompanySocialLinkApi,data,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          if(err.status == 401)
          {
            localStorage.removeItem('authUser')
            localStorage.removeItem('login_token')
            localStorage.removeItem('profile_img_url')
            window.location.href = '/login'
          }
          return err.error;
        }));
    } 
  
  
  
    update_contact_setting(data,headers){

      const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+headers,
			'requestFrom':this.siteRequestFrom
          })
        };
        return this.http.post<any>(this.apiDomain+this.updateContactSettingApi,data,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          if(err.status == 401)
          {
            localStorage.removeItem('authUser')
            localStorage.removeItem('login_token')
            localStorage.removeItem('profile_img_url')
            window.location.href = '/login'
          }
          return err.error;
        }));
    }  
  

  
    update_company_setting(data,headers){

      const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+headers,
        'requestFrom':this.siteRequestFrom
          })
        };
        return this.http.post<any>(this.apiDomain+this.updateCompanySettingApi,data,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          if(err.status == 401)
          {
            localStorage.removeItem('authUser')
            localStorage.removeItem('login_token')
            localStorage.removeItem('profile_img_url')
            window.location.href = '/login'
          }
          return err.error;
        }));
    }
  
  revoke_invite_partner(headers){

    const httpOptions = {
        headers: new HttpHeaders({
          //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
          'Content-Type': 'application/json',
          'Authorization':'Bearer '+headers,
          'requestFrom':this.siteRequestFrom,
        })
      };
      return this.http.post<any>(this.apiDomain+this.revokePartnerInvitationApi,{},httpOptions).pipe(map(res=>{
        return res;
      }),
      catchError(err => {
        if(err.status == 401)
        {
          /* localStorage.removeItem('authUser')
          localStorage.removeItem('login_token')
          localStorage.removeItem('profile_img_url')
          window.location.href = '/login' */
        }
        return err.error;
      }));
  }
  accept_invite_partner(data){

    const httpOptions = {
        headers: new HttpHeaders({
          //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
          //'Content-Type': 'application/json',
          //'Authorization':'Bearer '+headers,
          'requestFrom':this.siteRequestFrom,
        })
      };
      return this.http.post<any>(this.apiDomain+this.acceptPartnerInvitationApi,data,httpOptions).pipe(map(res=>{
        return res;
      }),
      catchError(err => {
        if(err.status == 401)
        {
          localStorage.removeItem('authUser')
          localStorage.removeItem('login_token')
          localStorage.removeItem('profile_img_url')
          window.location.href = '/login'
        }
        return err.error;
      }));
  }

  check_invite_partner(data){

    const httpOptions = {
        headers: new HttpHeaders({
          //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
          //'Content-Type': 'application/json',
          //'Authorization':'Bearer '+headers,
          'requestFrom':this.siteRequestFrom,
        })
      };
      return this.http.post<any>(this.apiDomain+this.checkPartnerInvitationApi,data,httpOptions).pipe(map(res=>{
        return res;
      }),
      catchError(err => {
        if(err.status == 401)
        {
          localStorage.removeItem('authUser')
          localStorage.removeItem('login_token')
          localStorage.removeItem('profile_img_url')
          window.location.href = '/login' 
        }
        return err.error;
      }));
  }

  getMyOrders(data,headers){

    const httpOptions = {
        headers: new HttpHeaders({
          //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
          'Content-Type': 'application/json',
          'Authorization':'Bearer '+headers,
          'requestFrom':this.siteRequestFrom,
        })
      };
      return this.http.post<any>(this.apiDomain+this.myOrdersApi,data,httpOptions).pipe(map(res=>{
        return res;
      }),
      catchError(err => {
        if(err.status == 401)
        {
          localStorage.removeItem('authUser')
          localStorage.removeItem('login_token')
          localStorage.removeItem('profile_img_url')
          window.location.href = '/login' 
        }
        return err.error;
      }));
  }
}





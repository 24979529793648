import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
/* import { FrontLayoutComponent } from './components/layout/front-layout/front-layout.component';
import { DashboardLayoutComponent } from './components/layout/dashboard-layout/dashboard-layout.component'; */


const routes: Routes = [

  // meeting components
  {
    path: '',
	loadChildren: () => import('./components/home.module').then(m => m.HomeModule)
  },
  {
    path: 'login',
	loadChildren: () => import('./components/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'master-login/:string',
	loadChildren: () => import('./components/master-login/master-login.module').then(m => m.MasterLoginModule)
  },
  {
    path: 'lostPass',
	loadChildren: () => import('./components/lost-passwrd/lost-passwrd.module').then(m => m.LostPasswrdModule)
  },
  {
    path: 'partner-signup',
	loadChildren: () => import('./components/partner-signup/partner-signup.module').then(m => m.PartnerSignupModule)
  },
  {
    path: 'reset-password',
	loadChildren: () => import('./components/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
  },
  {
    path: 'cart',
	loadChildren: () => import('./components/cart/cart.module').then(m => m.CartModule)
  },
  {
    path: 'registration',
	loadChildren: () => import('./components/register/register.module').then(m => m.RegisterModule)
  },
  {
    path: 'tickets',
	loadChildren: () => import('./components/tickets/tickets.module').then(m => m.TicketsModule)
  },
  {
    path: '',
	loadChildren: () => import('./components/veboticketing/veboticketing.module').then(m => m.VeboticketingModule)
  },
  {
    path: 'find-couple',
	loadChildren: () => import('./components/find-couple/find-couple.module').then(m => m.FindCoupleModule)
  },
  {
    path: 'bed-bath-beyond',
	loadChildren: () => import('./components/bed-bath-beyond/bed-bath-beyond.module').then(m => m.BedBathBeyondModule)
  },
  {
    path: 'knot',
	loadChildren: () => import('./components/knot/knot.module').then(m => m.KnotModule)
  },
  {
    path: 'theknot',
	loadChildren: () => import('./components/knot/knot.module').then(m => m.KnotModule)
  },
  /* {
    path: 'bbb',
	loadChildren: () => import('./components/bbb-baby/bbb-baby.module').then(m => m.BbbBabyModule)
  },
  {
    path: 'buybuybaby',
	loadChildren: () => import('./components/buy-buy-baby/buy-buy-baby.module').then(m => m.BuyBuyBabyModule)
  }, */
  {
    path: 'zola',
	loadChildren: () => import('./components/zola/zola.module').then(m => m.ZolaModule)
  },
  {
    path: 'tk',
	loadChildren: () => import('./components/knot/knot.module').then(m => m.KnotModule)
  },
  {
    path: 'joy',
	loadChildren: () => import('./components/joy/joy.module').then(m => m.JoyModule)
  },
  {
    path: 'withjoy',
	loadChildren: () => import('./components/joy/joy.module').then(m => m.JoyModule)
  },
  {
    path: 'claim-voucher',
	loadChildren: () => import('./components/claim-voucher/claim-voucher.module').then(m => m.ClaimVoucherModule)
  },
  {
    path: 'at-home-experiences',
	loadChildren: () => import('./components/home-exp/home-experience.module').then(m => m.HomeExperienceModule)
  },
  /* {
    path: 'couple',
	loadChildren: () => import('./components/couple/couple.module').then(m => m.CoupleModule)
  }, */
  /* {
    path: 'howitwork',
	loadChildren: () => import('./components/how-it-work/how-it-work.module').then(m => m.HowItWorkModule)
  }, */
  {
    path: 'howitwork',
	loadChildren: () => import('./components/how-it-work/how-it-work.module').then(m => m.HowItWorkModule)
  },
 /*  {
    path: 'about',
	loadChildren: () => import('./components/about/about.module').then(m => m.AboutModule)
  }, */
  {
    path: 'about',
	loadChildren: () => import('./components/about/about.module').then(m => m.AboutModule)
  },
  {
    path: 'blog',
	loadChildren: () => import('./components/blog/blog.module').then(m => m.BlogModule)
  },
  {
    path: 'blog-detail/:slug',
	loadChildren: () => import('./components/blog-details/blog-details.module').then(m => m.BlogDetailsModule)
  },
  {
    path: 'faqs',
	loadChildren: () => import('./components/faqs/faqs.module').then(m => m.FaqsModule)
  },
  {
    path: 'faq-category/:string',
	loadChildren: () => import('./components/faq-category/faq-category.module').then(m => m.FaqCategoryModule)
  },
  {
    path: 'faq-cate-details/:string',
	loadChildren: () => import('./components/faq-cate-details/faq-cate-details.module').then(m => m.FaqCateDetailsModule)
  },
  {
    path: 'press',
	loadChildren: () => import('./components/press/press.module').then(m => m.PressModule)
  },
  {
    path: 'category-blog/:slug',
	loadChildren: () => import('./components/category-blog/category-blog.module').then(m => m.CategoryBlogModule)
  },
  {
    path: 'artical-detail',
	loadChildren: () => import('./components/artical-details/artical-details.module').then(m => m.ArticalDetailsModule)
  },
  {
    path: 'paypal-response',
	loadChildren: () => import('./components/paypal-response/paypal-response.module').then(m => m.PaypalResponseModule)
  },
  {
    path: 'thankyou',
	loadChildren: () => import('./components/thank-you/thank-you.module').then(m => m.ThankYouModule)
  },
  {
    path: 'view-all-voucher',
	loadChildren: () => import('./components/view-all-voucher/view-all-voucher.module').then(m => m.ViewAllVoucherModule)
  },
  {
    path: 'persona-landing',
	loadChildren: () => import('./components/persona-landing/persona-landing.module').then(m => m.PersonaLandingModule)
  },
  {
    path: 'vebo-seats',
	loadChildren: () => import('./components/vebo-seats/vebo-seats.module').then(m => m.VeboSeatsModule)
  },
  {
    path: 'customer-support',
	loadChildren: () => import('./components/customer-support/customer-support.module').then(m => m.CustomerSupportModule)
  },
  {
    path: 'experience-search-booking',
	loadChildren: () => import('./components/experience-search-booking/experience-search-booking.module').then(m => m.ExperienceSearchBookingModule)
  },
  {
    path: '',
	loadChildren: () => import('./components/high-level-category/high-level-category.module').then(m => m.HighLevelCategoryModule)
  },
  {
    path: 'find-vebo-couples',
	loadChildren: () => import('./components/find-vebo-couples/find-vebo-couples.module').then(m => m.FindVeboCouplesModule)
  },
  {
    path: '',
	loadChildren: () => import('./components/gift-ideas/gift-ideas.module').then(m => m.GiftIdeasModule)
  },
  {
    path: 'location-region',
	loadChildren: () => import('./components/location-region/location-region.module').then(m => m.LocationRegionModule)
  },
  {
    path: 'country-location',
	loadChildren: () => import('./components/location-region/location-region.module').then(m => m.LocationRegionModule)
  },
  {
    path: 'locations/travel/:string',
	loadChildren: () => import('./components/international/international.module').then(m => m.InternationalModule)
  },
  {
    path: 'locations/:string',
	loadChildren: () => import('./components/location/location.module').then(m => m.LocationModule)
  },
  {
    path: 'experiences',
	loadChildren: () => import('./components/experiences/experiences.module').then(m => m.ExperiencesModule)
  },
  /* {
    path: 'experiences/:slug',
	loadChildren: () => import('./components/experience-detail/experience-detail.module').then(m => m.ExperienceDetailModule)
  }, */ 
  {
    path: 'experiences/:slug',
	loadChildren: () => import('./components/exp-detail/exp-detail.module').then(m => m.ExpDetailModule)
  },
  {
    path: 'top-ten-category',
	loadChildren: () => import('./components/top-ten-category/top-ten-category.module').then(m => m.TopTenCategoryModule)
  },
  {
    path: 'registries/:string',
	loadChildren: () => import('./components/registries/registries.module').then(m => m.RegistriesModule)
  },
  {
    path: 'add-experiences',
	loadChildren: () => import('./components/add-experiences/add-experiences.module').then(m => m.AddExperiencesModule)
  },
  {
    path: 'add-experience-by-city',
	loadChildren: () => import('./components/add-experience-by-city/add-experience-by-city.module').then(m => m.AddExperienceByCityModule)
  },
  {
    path: 'add-experience-by-region',
	loadChildren: () => import('./components/add-experience-by-region/add-experience-by-region.module').then(m => m.AddExperienceByRegionModule)
  },
  {
    path: 'region',
	loadChildren: () => import('./components/experiences/experiences.module').then(m => m.ExperiencesModule)
  },
  {
    path: 'adventure',
	loadChildren: () => import('./components/adventure/adventure.module').then(m => m.AdventureModule)
  },
  {
    path: 'eastern-us',
	loadChildren: () => import('./components/eastern-us/eastern-us.module').then(m => m.EasternUsModule)
  },
  {
    path: 'western-us',
	loadChildren: () => import('./components/western-us/western-us.module').then(m => m.WesternUsModule)
  },
  
  {
    path: 'provider/:slug/:slug',
	loadChildren: () => import('./components/ep-providers/ep-providers.module').then(m => m.EpProvidersModule)
  },
  {
    path: 'profile',
	loadChildren: () => import('./components/profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'ep-profile',
	loadChildren: () => import('./components/profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'bbb-profile',
	loadChildren: () => import('./components/profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'knot-profile',
	loadChildren: () => import('./components/profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'joy-profile',
	loadChildren: () => import('./components/profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'zola-profile',
	loadChildren: () => import('./components/profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'your-profile/charity',
	loadChildren: () => import('./components/profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'my-profile',
	loadChildren: () => import('./components/profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'experience-provider-add',
	loadChildren: () => import('./components/add-experience-provider/add-experience-provider.module').then(m => m.AddExperienceProviderModule)
  },
  {
    path: 'settings',
	loadChildren: () => import('./components/settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'my-settings',
	loadChildren: () => import('./components/settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'experience-settings',
	loadChildren: () => import('./components/settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'charity-settings',
	loadChildren: () => import('./components/settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'edit-charity',
	loadChildren: () => import('./components/edit-charity/edit-charity.module').then(m => m.EditCharityModule)
  },
  {
    path: 'charity-couple',
	loadChildren: () => import('./components/charity-couple/charity-couple.module').then(m => m.CharityCoupleModule)
  },
  {
    path: 'gift-tracker',
	loadChildren: () => import('./components/gift-tracker/gift-tracker.module').then(m => m.GiftTrackerModule)
  },
  {
    path: 'news-feeds',
	loadChildren: () => import('./components/news-feed/news-feed.module').then(m => m.NewsFeedModule)
  },
  {
    path: 'our-friends',
	loadChildren: () => import('./components/my-friends/my-friends.module').then(m => m.MyFriendsModule)
  },
  {
    path: 'my-friends',
	loadChildren: () => import('./components/my-friends/my-friends.module').then(m => m.MyFriendsModule)
  },
  {
    path: 'refer-friends',
	loadChildren: () => import('./components/refer-friends/refer-friends.module').then(m => m.ReferFriendsModule)
  },
  {
    path: 'vebo-map-app-view',
	loadChildren: () => import('./components/map-app-view/map-app-view.module').then(m => m.MapAppViewModule)
  },
  {
    path: '',
	loadChildren: () => import('./components/registerfinder-after-login/registerfinder.module').then(m => m.RegisterfinderModule)
  },
  {
    path: 'charities',
	loadChildren: () => import('./components/charity/charity.module').then(m => m.CharityModule)
  },
  {
    path: 'charity-category',
	loadChildren: () => import('./components/charity-category/charity-category.module').then(m => m.CharityCategoryModule)
  },
  {
    path: '',
	loadChildren: () => import('./components/ep-dashboard/ep-dashboard.module').then(m => m.EpDashboardModule)
  },
  /* {
    path: 'checkout',
	loadChildren: () => import('./components/checkout/checkout.module').then(m => m.CheckoutModule)
  }, */
  {
    path: 'checkout',
  loadChildren: () => import('./components/checkout/checkout.module').then(m => m.CheckoutModule)
  },
  {
    path: 'providers',
	loadChildren: () => import('./components/providers/providers.module').then(m => m.ProvidersModule)
  },
  {
    path: 'weddingregistry',
	loadChildren: () => import('./components/weddingregistry/weddingregistry.module').then(m => m.WeddingregistryModule)
  },
  {
    path: 'whyregisterwithspur',
	loadChildren: () => import('./components/whyregisterwithcouple/whyregisterwithcouple.module').then(m => m.WhyregisterwithcoupleModule)
  },
  {
    path: 'review-rating',
	loadChildren: () => import('./components/review-rating/review-rating.module').then(m => m.ReviewRatingtModule)
  },
  {
    path: 'payment-failed',
	loadChildren: () => import('./components/payment-failed/payment-failed.module').then(m => m.PaymentFailedModule)
  },
  {
    path: 'ticket-map-web-view',
	loadChildren: () => import('./components/ticket-map-web-view/ticket-map-web-view.module').then(m => m.TicketMapWebViewModule)
  },
  {
    path: 'continents/:slug',
  loadChildren: () => import('./components/continent/continent.module').then(m => m.ContinentModule)
  },
  {
    path: 'continents/:slug/:slug',
  loadChildren: () => import('./components/continent/continent.module').then(m => m.ContinentModule)
  },
  {
    path: '**',
	loadChildren: () => import('./components/not-found/not-found.module').then(m => m.NotFoundModule)
  },
  {
    path: '404',
	loadChildren: () => import('./components/not-found/not-found.module').then(m => m.NotFoundModule)
  }
  
  
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' })
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
   
@Pipe({
 name: 'dayDateFormat'
}) 
export class DayDateFormat extends 
			DatePipe implements PipeTransform {
 transform(value: any, args?: any): any {
   return super.transform(value, "MMMM d, yyyy");
 }
}
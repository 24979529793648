import { Component, OnInit, HostListener , PLATFORM_ID, Inject} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NavigationStart, NavigationEnd, Router } from '@angular/router';
declare var $: any;
declare var window: any;

@Component({
  selector: 'app-charity-sub-header',
  templateUrl: './charity-sub-header.component.html',
  styleUrls: ['./charity-sub-header.component.css']
})
export class CharitySubHeaderComponent implements OnInit {
  public currentURL: string = "";
  isBrowser : boolean;
  constructor(private router: Router,
	@Inject(PLATFORM_ID) platformId: Object) 
  { 
	this.isBrowser = isPlatformBrowser(platformId);
	if(this.isBrowser){
		window.scrollTo(0,0)
	}
	this.currentURL = this.router.url.split("/")[1];
    this.router.events.subscribe(event => {

      if (event instanceof NavigationStart) {
        this.onWindowScroll();
      }

      if(event instanceof NavigationEnd) {
        this.currentURL = this.router.url.split("/")[1];
      }

    });
  }

  ngOnInit() {
  }
  ngAfterViewInit() {

  }

  @HostListener("window:scroll", []) 
  onWindowScroll() {
    // do some stuff here when the window is scrolled
	if(this.isBrowser){
		const verticalOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
		var navbar = document.getElementById("charity-sub-header");
	
		if (verticalOffset < 145 && navbar!=null && navbar!=undefined) {
			navbar.classList.remove('is-sticky');
		}
		if (verticalOffset > 145 && navbar!=null && navbar!=undefined) {
		  if (!navbar.classList.contains('is-sticky')) {
			navbar.classList.add('is-sticky');
		  }
		}
		if (verticalOffset > 145 * 2 && navbar!=null && navbar!=undefined) {
		  if (!navbar.classList.contains('awake')) {
			navbar.classList.add('awake');
		  }
		}
		if (verticalOffset < 145 * 2 && navbar!=null && navbar!=undefined) {
		  if (navbar.classList.contains('awake')) {
			navbar.classList.remove('awake');
		  }
		}
	}
  }

}

import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { NavigationStart, NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import * as globalConstant from '../../global_constant';
import { isPlatformBrowser } from '@angular/common';
import { SettingsService } from 'src/app/services/Settings.service';
declare var window: any;

@Component({
	selector: 'vebo-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

	copy_right: any;
	isBrowser: boolean;
	settings_data: any;
	login: any = '';
	currentYear : any= ''

	constructor(
		private settingService: SettingsService,
		public router: Router,
		@Inject(PLATFORM_ID) platformId: Object
	) {
		this.currentYear = new Date().getFullYear()
		this.isBrowser = isPlatformBrowser(platformId);
		if(this.isBrowser){
			window.scrollTo(0,0);
		}
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				if (localStorage.getItem('authUser')) {
					this.login = true;
				} else {
					this.login = false;
				}
			}
		});

	}

	ngOnInit() {
		if(this.isBrowser){
			this.settingService.get_settings().subscribe(res => {
				if (res.response == "success") {
					this.settings_data = res.data;
				}
			});
		}
		
	}

	ngAfterViewInit() {
		if(this.isBrowser){
			var msgProp = document.getElementById("msgIocnFixed")
			if(msgProp != null && msgProp != undefined){
				msgProp.classList.add('open');
			}
		}
		
	}
}

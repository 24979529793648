import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { catchError,map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import * as globalConstant from './../global_constant';
declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  apiDomain							=	globalConstant.DOMAIN;
  registerApiStep1					=	globalConstant.REGISTER_STEP_1_API;
  registerApiStep2					=	globalConstant.REGISTER_STEP_2_API;
  registerApiStep3					=	globalConstant.REGISTER_STEP_3_API;
  registerApiStep4					=	globalConstant.REGISTER_STEP_4_API;
  registerApiStep5					=	globalConstant.REGISTER_STEP_5_API;
  registerApiStep6					=	globalConstant.REGISTER_STEP_6_API;
  registerApiStep7					=	globalConstant.REGISTER_STEP_7_API;
  registerApiStep8					=	globalConstant.REGISTER_STEP_8_API;
  checkUsernameApi					=	globalConstant.CHECK_USERNAME_API; 
  checkEmailApi						=	globalConstant.CHECK_EMAIL_API;
  registerWeddingCoupleApi			=	globalConstant.REGISTER_COUPLE_API;
  registerWeddingCoupleStep3Api		=	globalConstant.REGISTER_COUPLE_STEP_3_API;
  uploadProfilePictureApi			=	globalConstant.UPLOAD_PROFILE_PICTURE_API;
  registerCoupleApiStep5			=	globalConstant.REGISTER_COUPLE_STEP_5_API;
  registerCoupleCharityApiStep6		=	globalConstant.REGISTER_COUPLE_CHARITY_STEP_6_API;
  registerCoupleCharityApiStep7		=	globalConstant.REGISTER_COUPLE_CHARITY_STEP_7_API;
  registerIndividualUserApi			=	globalConstant.REGISTER_INDIVIDUAL_USER_API;
  registerExperienceUserApi			=	globalConstant.REGISTER_EXPERIENCE_USER_API;
  experienceUserSavePaymentInfoStep2Api		=	globalConstant.EXPERIENCE_USER_SAVE_PAYMENT_INFO_STEP_2API;
  uploadCompanyLogoApi						=	globalConstant.UPLOAD_COMPANY_LOGO_API;
  experienceUserSaveRedeemInfoStep4Api		=	globalConstant.EXPERIENCE_USER_SAVE_REDEEM_INFO_STEP_4_API;
  siteRequestFrom							=	globalConstant.SITE_REQUEST_FROM;
  registerCharityUserApi					=	globalConstant.REGISTER_CHARITY_USER_API;
  saveCharityStep2Api						=	globalConstant.SAVE_CHARITY_USER_STEP_2_API;
  saveCharityInformationStep3Api			=	globalConstant.SAVE_CHARITY_INFORMATION_STEP_3_API;
  saveCharityInformationStep4Api			=	globalConstant.SAVE_CHARITY_INFORMATION_STEP_4_API;
  saveCharityMailingAddressStep6Api			=	globalConstant.SAVE_CHARITY_MAILING_ADDRESS_STEP_6_API;
  saveCharityGalleryApi						=	globalConstant.SAVE_CHARITY_GALLERY_API;
  UploadCoverPictureApi						=	globalConstant.UPLOAD_COVER_PICTURE_API;
  deviceType 								= globalConstant.DEVICE_TYPE
  voucerDataApi								= globalConstant.VOUCHER_DATA_API
  registerBbbUserApi						= globalConstant.REGISTER_BBB_USER_API
  registerBbbUserAddressInfoApi				= globalConstant.REGISTER_BBB_USER_ADDRESS_INFO_API
  expHomeRegionRegistrationApi				= globalConstant.EXPERIENCE_HOME_REGION_REGISTRATION_API
  registerBbbBabyApi      = globalConstant.REGISTER_BBB_BABY_API;
 
  
  constructor(public router: Router,private http: HttpClient) {  
    if(window.orientation > -1){
      this.deviceType = "mobile";
    }else{
      this.deviceType = "desktop";
    }
  } 

  registration_step_1(formData){

	const httpOptions = {
      headers: new HttpHeaders({
        //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
        //'Content-Type': 'application/x-www-form-urlencoded',,
		'requestFrom':this.siteRequestFrom,
        'device' :  this.deviceType
      })
    };
    return this.http.post<any>(this.apiDomain+this.registerApiStep1,formData,httpOptions).pipe(map(res=>{
      return res;
    }),
    catchError(err => {
      return err.error;
    }));
  }
  
  
  check_username(username){

	const httpOptions = {
      headers: new HttpHeaders({
        //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
        'Content-Type': 'application/json',
        'requestFrom':this.siteRequestFrom,
        'device' :  this.deviceType
      })
    };
    return this.http.post<any>(this.apiDomain+this.checkUsernameApi,{'username':username},httpOptions).pipe(map(res=>{
      return res;
    }),
    catchError(err => {
      return err.error;
    }));
  }
   
  check_email(email){

	const httpOptions = {
      headers: new HttpHeaders({
        //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
        'Content-Type': 'application/json',
        'requestFrom':this.siteRequestFrom,
        'device' :  this.deviceType
      })
    };
    return this.http.post<any>(this.apiDomain+this.checkEmailApi,{'email':email},httpOptions).pipe(map(res=>{
      return res;
    }),
    catchError(err => {
      return err.error;
    }));
  }
  
  
  register_wedding_couple(formData){

	const httpOptions = {
      headers: new HttpHeaders({
        //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
        'Content-Type': 'application/json',
        'requestFrom':this.siteRequestFrom,
        'device' :  this.deviceType
      })
    };
    return this.http.post<any>(this.apiDomain+this.registerWeddingCoupleApi,formData,httpOptions).pipe(map(res=>{
      return res;
    }),
    catchError(err => {
      return err.error;
    }));
  }

  
  register_wedding_couple_step_3(formData,headers){

	const httpOptions = {
      headers: new HttpHeaders({
        //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+headers,
        'device' :  this.deviceType,
        'requestFrom':this.siteRequestFrom,
      })
    };
    return this.http.post<any>(this.apiDomain+this.registerWeddingCoupleStep3Api,formData,httpOptions).pipe(map(res=>{
      return res;
    }),
    catchError(err => {

      if(err.status == 401)
      {
        localStorage.removeItem('authUser')
        localStorage.removeItem('login_token')
        localStorage.removeItem('profile_img_url')
        window.location.href = '/login'
      }

      return err.error;
    }));
  }
  
  register_save_step_7(formData){

	const httpOptions = {
      headers: new HttpHeaders({
        //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
        //'Content-Type': 'application/x-www-form-urlencoded',,
        'device' :  this.deviceType
        
      })
    };
    return this.http.post<any>(this.apiDomain+this.registerWeddingCoupleStep3Api,formData,httpOptions).pipe(map(res=>{
      return res;
    }),
    catchError(err => {
      return err.error;  
    }));
  }


  

  upload_profile_picture(formData,headers){

    const httpOptions = {
      headers: new HttpHeaders({
        //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+headers,
        'requestFrom':this.siteRequestFrom,
        'device' :  this.deviceType
      })
    };
      return this.http.post<any>(this.apiDomain+this.uploadProfilePictureApi,formData,httpOptions).pipe(map(res=>{
        return res;
      }),
      catchError(err => {
        if(err.status == 401)
        {
          localStorage.removeItem('authUser')
          localStorage.removeItem('login_token')
          localStorage.removeItem('profile_img_url')
          window.location.href = '/login'
        }
        return err.error;
      }));
    }


    register_couple_step_5(formData,headers){

      const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+headers,
			'requestFrom':this.siteRequestFrom,
            'device' :  this.deviceType
          })
        };
        return this.http.post<any>(this.apiDomain+this.registerCoupleApiStep5,formData,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          if(err.status == 401)
          {
            localStorage.removeItem('authUser')
            localStorage.removeItem('login_token')
            localStorage.removeItem('profile_img_url')
            window.location.href = '/login'
          }
          return err.error;
        }));
    }

    register_couple_charity_step_6(formData,headers){

      const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            //'Content-Type': 'application/x-www-form-urlencoded',
           'Content-Type': 'application/json',
            'Authorization':'Bearer '+headers,
			'requestFrom':this.siteRequestFrom,
            'device' :  this.deviceType
          })
        };
        return this.http.post<any>(this.apiDomain+this.registerCoupleCharityApiStep6,formData,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          if(err.status == 401)
          {
            localStorage.removeItem('authUser')
            localStorage.removeItem('login_token')
            localStorage.removeItem('profile_img_url')
            window.location.href = '/login'
          }
          return err.error;
        }));
    }
     
    register_couple_charity_step_7(formData,headers){

      const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+headers,
			'requestFrom':this.siteRequestFrom,
            'device' :  this.deviceType
          })
        };
        return this.http.post<any>(this.apiDomain+this.registerCoupleCharityApiStep7,formData,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          if(err.status == 401)
          {
            localStorage.removeItem('authUser')
            localStorage.removeItem('login_token')
            localStorage.removeItem('profile_img_url')
            window.location.href = '/login'
          }
          return err.error;
        }));
    }


    register_individual_user(formData){

      const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            'Content-Type': 'application/json',
            'requestFrom':this.siteRequestFrom,
            'device' :  this.deviceType
          })
        };
        return this.http.post<any>(this.apiDomain+this.registerIndividualUserApi,formData,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          return err.error;
        }));
    }   
        

    register_experience_user(formData){

      const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            'Content-Type': 'application/json',
            'requestFrom':this.siteRequestFrom,
            'device' :  this.deviceType
          })
        };
        return this.http.post<any>(this.apiDomain+this.registerExperienceUserApi,formData,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          return err.error;
        }));
    } 
    
    
    experience_user_save_payment_info_step_2(formData,headers){

      const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+headers,
			'requestFrom':this.siteRequestFrom,
            'device' :  this.deviceType
          })
        };
        return this.http.post<any>(this.apiDomain+this.experienceUserSavePaymentInfoStep2Api,formData,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          if(err.status == 401)
          {
            localStorage.removeItem('authUser')
            localStorage.removeItem('login_token')
            localStorage.removeItem('profile_img_url')
            window.location.href = '/login'
          }
          return err.error;
        }));
    }
    
    upload_company_logo(formData,headers){

      const httpOptions = {
        headers: new HttpHeaders({
          //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
          'Content-Type': 'application/json',
          'Authorization':'Bearer '+headers,
          'requestFrom':this.siteRequestFrom,
          'device' :  this.deviceType
        })
      };

        return this.http.post<any>(this.apiDomain+this.uploadCompanyLogoApi,formData,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          if(err.status == 401)
          {
            localStorage.removeItem('authUser')
            localStorage.removeItem('login_token')
            localStorage.removeItem('profile_img_url')
            window.location.href = '/login'
          }
          return err.error;
        }));
    }

    experience_user_save_redeem_info_step_4(formData,headers){

      const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+headers,
			'requestFrom':this.siteRequestFrom,
            'device' :  this.deviceType
          })
        };
        return this.http.post<any>(this.apiDomain+this.experienceUserSaveRedeemInfoStep4Api,formData,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          if(err.status == 401)
          {
            localStorage.removeItem('authUser')
            localStorage.removeItem('login_token')
            localStorage.removeItem('profile_img_url')
            window.location.href = '/login'
          }
          return err.error;
        }));
    }
    

    
    register_charity_user(formData){

      const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            'Content-Type': 'application/json',
            'requestFrom':this.siteRequestFrom,
            'device' :  this.deviceType
          })
        };
        return this.http.post<any>(this.apiDomain+this.registerCharityUserApi,formData,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          return err.error;
        }));
    } 

    save_charity_step_2(formData,headers){

      const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+headers,
			'requestFrom':this.siteRequestFrom,
            'device' :  this.deviceType
          })
        };
        return this.http.post<any>(this.apiDomain+this.saveCharityStep2Api,formData,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          if(err.status == 401)
          {
            localStorage.removeItem('authUser')
            localStorage.removeItem('login_token')
            localStorage.removeItem('profile_img_url')
            window.location.href = '/login'
          }
          return err.error;
        }));
    }

    save_charity_information_step_3(formData,headers){

      const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+headers,
			'requestFrom':this.siteRequestFrom,
            'device' :  this.deviceType
          })
        };
        return this.http.post<any>(this.apiDomain+this.saveCharityInformationStep3Api,formData,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          if(err.status == 401)
          {
            localStorage.removeItem('authUser')
            localStorage.removeItem('login_token')
            localStorage.removeItem('profile_img_url')
            window.location.href = '/login'
          }
          return err.error;
        }));
    }
 
    save_charity_information_step_4(formData,headers){

      const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+headers,
			'requestFrom':this.siteRequestFrom,
            'device' :  this.deviceType
          })
        };
        return this.http.post<any>(this.apiDomain+this.saveCharityInformationStep4Api,formData,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          if(err.status == 401)
          {
            localStorage.removeItem('authUser')
            localStorage.removeItem('login_token')
            localStorage.removeItem('profile_img_url')
            window.location.href = '/login'
          }
          return err.error;
        }));
    }
    
    save_charity_mailing_address_step_6(formData,headers){

      const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+headers,
            'requestFrom':this.siteRequestFrom,
            'device' :  this.deviceType
          })
        };
        return this.http.post<any>(this.apiDomain+this.saveCharityMailingAddressStep6Api,formData,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          if(err.status == 401)
          {
            localStorage.removeItem('authUser')
            localStorage.removeItem('login_token')
            localStorage.removeItem('profile_img_url')
            window.location.href = '/login'
          }
          return err.error;
        }));
  }
   
  save_charity_gallery(formData,headers){

    const httpOptions = {
      headers: new HttpHeaders({
        //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+headers,
        'requestFrom':this.siteRequestFrom,
        'device' :  this.deviceType
      })
    };

      return this.http.post<any>(this.apiDomain+this.saveCharityGalleryApi,formData,httpOptions).pipe(map(res=>{
        return res;
      }),
      catchError(err => {
        if(err.status == 401)
        {
          localStorage.removeItem('authUser')
          localStorage.removeItem('login_token')
          localStorage.removeItem('profile_img_url')
          window.location.href = '/login'
        }
        return err.error;
      }));
	}
	
	
	
    upload_cover_image(formData,headers){

		const httpOptions = {
			headers: new HttpHeaders({
			  'Content-Type': 'application/json',
			  'Authorization':'Bearer '+headers,
			  'requestFrom':this.siteRequestFrom,
			})
		};

        return this.http.post<any>(this.apiDomain+this.UploadCoverPictureApi,formData,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          if(err.status == 401)
          {
            localStorage.removeItem('authUser')
            localStorage.removeItem('login_token')
            localStorage.removeItem('profile_img_url')
            window.location.href = '/login'
          }
          return err.error;
        }));
    }
    voucherData(formData){

		const httpOptions = {
			headers: new HttpHeaders({
			  'Content-Type': 'application/json',
			  //'Authorization':'Bearer '+headers,
			  'requestFrom':this.siteRequestFrom,
			})
		};

        return this.http.post<any>(this.apiDomain+this.voucerDataApi,formData,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          if(err.status == 401)
          {
            localStorage.removeItem('authUser')
            localStorage.removeItem('login_token')
            localStorage.removeItem('profile_img_url')
            window.location.href = '/login'
          }
          return err.error;
        }));
    }
	register_bbb_user(formData){

		const httpOptions = {
			headers: new HttpHeaders({
			  'Content-Type': 'application/json',
			  //'Authorization':'Bearer '+headers,
			  'requestFrom':this.siteRequestFrom,
			})
		};

        return this.http.post<any>(this.apiDomain+this.registerBbbUserApi,formData,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          if(err.status == 401)
          {
            localStorage.removeItem('authUser')
            localStorage.removeItem('login_token')
            localStorage.removeItem('profile_img_url')
            window.location.href = '/login'
          }
          return err.error;
        }));
    }
	register_bbb_user_address_info(formData,headers){

		const httpOptions = {
			headers: new HttpHeaders({
			  'Content-Type': 'application/json',
			  'Authorization':'Bearer '+headers,
			  'requestFrom':this.siteRequestFrom,
			})
		};

        return this.http.post<any>(this.apiDomain+this.registerBbbUserAddressInfoApi,formData,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          if(err.status == 401)
          {
            localStorage.removeItem('authUser')
            localStorage.removeItem('login_token')
            localStorage.removeItem('profile_img_url')
            window.location.href = '/login'
          }
          return err.error;
        }));
    }
    expHomeRegionRegistration(headers){
      //console.log("aaaa");
      //console.log(headers)

		const httpOptions = {
			headers: new HttpHeaders({
			  'Content-Type': 'application/json',
			  'Authorization':'Bearer '+headers,
			  'requestFrom':this.siteRequestFrom,
        
			})
		};

        return this.http.post<any>(this.apiDomain+this.expHomeRegionRegistrationApi,{},httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          if(err.status == 401)
          {
            localStorage.removeItem('authUser')
            localStorage.removeItem('login_token')
            localStorage.removeItem('profile_img_url')
            window.location.href = '/login'
          }
          return err.error;
        }));
    }

    register_bbb_baby(formData){

    const httpOptions = {
        headers: new HttpHeaders({
          //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
          'Content-Type': 'application/json',
          'requestFrom':this.siteRequestFrom,
          'device' :  this.deviceType
        })
      };
      return this.http.post<any>(this.apiDomain+this.registerBbbBabyApi,formData,httpOptions).pipe(map(res=>{
        return res;
      }),
      catchError(err => {
        return err.error;
      }));
    }
  
}





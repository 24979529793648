import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { catchError,map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import * as globalConstant from './../global_constant';

@Injectable({
  providedIn: 'root'
})
export class FindCouple {
	apiDomain			=	globalConstant.DOMAIN;
	siteRequestFrom		=	globalConstant.SITE_REQUEST_FROM;
	findCoupleApi		=	globalConstant.FIND_COUPLE_API;
	userDataApi			=	globalConstant.USER_DATA_API;
	
	constructor(public router: Router,private http: HttpClient) {    

	} 
	find_couples(pageNumber,keyword, headers){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Authorization':headers == "" ? "":'Bearer '+headers,
				'requestFrom':this.siteRequestFrom
			})
		};
		return this.http.post<any>(this.apiDomain+this.findCoupleApi, {page:pageNumber,search:keyword},httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			return err.error;
		}));
	}
	user_data(id,headers){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Authorization':'Bearer '+headers,
				'requestFrom':this.siteRequestFrom
			})
		};
		return this.http.post<any>(this.apiDomain+this.userDataApi,{user_id:id},httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {
			return err.error;
		}));
	}
}





import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { environment } from 'src/environments/environment';

import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
//import { CountdownTimerModule } from 'ngx-countdown-timer';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CalendarModule} from 'primeng/calendar';
import { CroppieModule } from 'angular-croppie-module';
import { ImageCropperModule } from '../image-cropper/image-cropper.module';
import { NgSelect2Module } from 'ng-select2';

import { NgxCaptchaModule } from 'ngx-captcha';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { PlyrModule } from 'ngx-plyr';

import {OnlyNumberDirective} from '../only-number.directive';
import {PreventKeysDirective} from '../prevent-keys.directive';
import { CurrencyMaskDirective } from '../currency-mask.directive';
import { PhoneMaskDirective } from '../phone-mask.directive';
import { InputMaskDirective } from '../input-mask.directive';
import { InputMaxLengthDirective } from '../input-max-length-directive';


import { UrlDecode } from '../urldecode.pipe';
import { HtmlConverter } from '../html-converter.pipe';
import { Time24to12Format } from '../time24to12format.pipe';

import {CustomDatePipe} from '../custom.datepipe';
import {DateFormat} from '../dateFormat.datepipe';
import { CustomTimePipe } from '../custom.timepipe'; 
import { DayDateFormat } from '../dayDateFormat.datepipe';
import { WordLimit } from '../word-limit.pipe';
import { PriceFormat } from '../priceFormat.pipe';
import { LabelFormat } from '../labelFormat.pipe';
import { DateMonthFormat } from '../date-month-format.pipe';

@NgModule({
  declarations: [
	UrlDecode,
	CustomDatePipe,
	Time24to12Format,
	DateFormat,
	CustomTimePipe,
	DayDateFormat,
	OnlyNumberDirective,
	PreventKeysDirective,
	CurrencyMaskDirective,
	PhoneMaskDirective,
	InputMaskDirective,
	InputMaxLengthDirective,
	HtmlConverter,
	WordLimit,
	PriceFormat,
	LabelFormat,
	DateMonthFormat
  ],   
  providers: [    
    DatePipe,    
  ],
  imports: [  
    CommonModule,
	//CountdownTimerModule.forRoot(),
	ModalModule.forRoot(),
	BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
	CalendarModule,
	CroppieModule,
	NgxCaptchaModule,
	GooglePlaceModule,
	NgSelect2Module,
	PlyrModule,
	ImageCropperModule
  ],
  exports: [
    CommonModule,
    //CountdownTimerModule, 
    ModalModule, 
    BsDatepickerModule, 
    DatepickerModule,
    CalendarModule, 
    CroppieModule, 
    NgxCaptchaModule, 
    GooglePlaceModule, 
    NgSelect2Module, 
    ImageCropperModule,
	UrlDecode,
	CustomDatePipe,
	DateFormat,
	CustomTimePipe,
	OnlyNumberDirective,
	InputMaxLengthDirective,
	CurrencyMaskDirective,
	InputMaskDirective,
	PhoneMaskDirective,
	PlyrModule,
	PreventKeysDirective,
	HtmlConverter,
	Time24to12Format,
	WordLimit,
	DayDateFormat,
	PriceFormat,
	LabelFormat,
	DateMonthFormat
  ]
})
export class SharedModule {}

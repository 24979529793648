import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthdataMessageService {
    private subject = new Subject<any>();
    private accountStatus = new Subject<any>();
    private profilepic = new Subject<any>();

    sendAccoutStatus(auth_data: any) {
        this.accountStatus.next( auth_data );
    }

    clearAccoutStatus() {
        this.accountStatus.next();
    }

    getAccoutStatus(): Observable<any> {
        return this.accountStatus.asObservable();
    }
	
	sendAuthUserDataMessage(messageData: any) {
        this.subject.next( messageData );
    }

    clearAuthUserDataMessage() {
        this.subject.next();
    }

	getAuthUserDataMessage(): Observable<any> {
        return this.subject.asObservable();
    }
    
    sendProfilePicMessage(messageData: any) {
        this.profilepic.next( messageData );
    }

    clearProfilePicMessage() {
        this.profilepic.next();
    }

	getProfilePicMessage(): Observable<any> {
        return this.profilepic.asObservable();
    }
}

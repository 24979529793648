import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';

@Injectable({ providedIn: 'root' })
export class MessageService {
    private subject = new Subject<any>();
	private logoutsubject = new Subject<any>();
    private cartsubject = new Subject<any>();
    private paymentsubject = new Subject<any>();
    private restFootersubject = new Subject<any>();
    private vouchersubject = new Subject<any>();
    private whywechosesubject = new Subject<any>();
    private amountsubject = new Subject<any>();
	private autocompletesubject = new Subject<any>();
	private locationsubject = new Subject<any>();
	private authdatasubject = new Subject<any>();
	private addphotovideosubject = new Subject<any>();
    private addRegistryPopupToComponentsubject = new Subject<any>();
    private totalsubject = new Subject<any>();
    private requestsubject = new Subject<any>();
	private addgiftcertificate = new Subject<any>();
	private video_url = new Subject<any>();
	private notification_count = new Subject<any>();
	private photoUpdateSubject = new Subject<any>();
	private facebookDataSubject = new Subject<any>();
	private coupleStartTimerSubject = new Subject<any>();
    private createExpStepSubject: BehaviorSubject<Array<any>> = new BehaviorSubject([]);

    sendMessage(message: string) {
        this.subject.next( message );
    }
    clearMessage() {
        this.subject.next();
    }
    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
    sendConfirmRequestMessage(message: string) {
        this.requestsubject.next( message );
    }
    clearConfirmRequestMessage() {
        this.requestsubject.next();
    }
    getConfirmRequestMessage(): Observable<any> {
        return this.requestsubject.asObservable();
    }
    sendTotalMessage(message: any) {
        this.totalsubject.next( message );
    }
    clearTotalMessage() {
        this.totalsubject.next();
    }
    getTotalMessage(): Observable<any> {
        return this.totalsubject.asObservable();
    }
	
    sendLogoutMessage(message: string) {
        this.logoutsubject.next( message );
    }

    clearLogoutMessage() {
        this.logoutsubject.next();
    }

    getLogoutMessage(): Observable<any> {
        return this.logoutsubject.asObservable();
    }
    sendAmountMessage(message: string) {
        this.amountsubject.next( message );
    }

    clearAmountMessage() {
        this.amountsubject.next();
    }

    getAmountMessage(): Observable<any> {
        return this.amountsubject.asObservable();
    }

    sendWhyWeChoseMessage(message: string) {
        this.whywechosesubject.next( message );
    }

    clearWhyWeChoseMessage() {
        this.whywechosesubject.next();
    }

    getWhyWeChoseMessage(): Observable<any> {
        return this.whywechosesubject.asObservable();
    }

    sendVoucherMessage(message: string) {
        this.vouchersubject.next( message );
    }

    clearVoucherMessage() {
        this.vouchersubject.next();
    }

    getVoucherMessage(): Observable<any> {
        return this.vouchersubject.asObservable();
    }

    sendPaymentMessage(message: string) {
        this.paymentsubject.next( message );
    }

    clearPaymentMessage() {
        this.paymentsubject.next();
    }

    getPaymentMessage(): Observable<any> {
        return this.paymentsubject.asObservable();
    }

    sendCartMessage(message: string) {
        this.cartsubject.next( message );
    }

    clearCartMessage() {
        this.cartsubject.next();
    }

    getCartMessage(): Observable<any> {
        return this.cartsubject.asObservable();
    }

	sendlocationMessage(locationlist: any) {
        this.locationsubject.next( locationlist );
    }


    clearlocationMessage() {
        this.locationsubject.next();
    }

    getlocationMessage(): Observable<any> { 
        
        return this.locationsubject.asObservable();
    }
	
	sendAutocompleteSearchParameterMessage(messageData: any) {
       // console.log('message service');
       // console.log(messageData);
        this.autocompletesubject.next( messageData );
    }

    clearAutocompleteSearchParameterMessage() {
        this.autocompletesubject.next();
    }

	getAutocompleteSearchParameterMessage(): Observable<any> {
        //console.log('message servicessss');
        return this.autocompletesubject.asObservable();
    }
	
	sendAuthUserDataMessage(messageData: any) {
        this.authdatasubject.next( messageData );
    }

    clearAuthUserDataMessage() {
        this.authdatasubject.next();
    }

	getAuthUserDataMessage(): Observable<any> {
        return this.authdatasubject.asObservable();
    }
	
	sendPhotoVideo(messageData: any) {
        this.addphotovideosubject.next( messageData );
    }

    clearPhotoVideo() {
        this.addphotovideosubject.next();
    }

	getPhotoVideo(): Observable<any> {
        return this.addphotovideosubject.asObservable();
    }
	
	
	
	sendExpStatusChangedToMainComponent(messageData: any) {
		//console.log(messageData);
        this.addRegistryPopupToComponentsubject.next( messageData );
    }

    clearExpStatusChangedToMainComponent() {
        this.addRegistryPopupToComponentsubject.next();
    }

	getExpStatusChangedToMainComponent(): Observable<any> {
        return this.addRegistryPopupToComponentsubject.asObservable();
    }
	
	sendGiftCertificate(messageData: any) {
		//console.log(messageData);
        this.addgiftcertificate.next( messageData );
    }

    clearGiftCertificate() {
        this.addgiftcertificate.next();
    }

	getGiftCertificate(): Observable<any> {
        return this.addgiftcertificate.asObservable();
    }
	
	sendvideo_url(messageData: any) {
        this.video_url.next( messageData );
    }

    clearvideo_url() {
        this.video_url.next();
    }

	getvideo_url(): Observable<any> {
        return this.video_url.asObservable();
    }
	sendNotificationCount(messageData: any) {
        this.notification_count.next( messageData );
    }

    clearNotificationCount() {
        this.notification_count.next();
    }

	getNotificationCount(): Observable<any> {
        return this.notification_count.asObservable();
    }
	
	sendPhotoUpdateProcess(messageData: any) {
        this.photoUpdateSubject.next( messageData ); 
    }

    clearPhotoUpdateProcess() {
        this.photoUpdateSubject.next();
    }

	getPhotoUpdateProcess(): Observable<any> {
        return this.photoUpdateSubject.asObservable();
    }
	
	sendFacebookData(messageData: any) {
        this.facebookDataSubject.next( messageData );
    }
 
    clearFacebookData() {
        this.facebookDataSubject.next();
    }

	getFacebookData(): Observable<any> {
        return this.facebookDataSubject.asObservable();
    }
	
	sendCoupleStartTimerMessage(messageData: any) {
        this.coupleStartTimerSubject.next( messageData );
    }

    clearCoupleStartTimerMessage() {
        this.coupleStartTimerSubject.next();
    }

	getCoupleStartTimerMessage(): Observable<any> {
        return this.coupleStartTimerSubject.asObservable();
    }

    sendExpStepData(messageData: any) {
        this.createExpStepSubject.next(messageData);
    } 

    clearExpStepData() {
        //this.createExpStepSubject.next();
    }

    getExpStepData(): Observable<any> {
        return this.createExpStepSubject.asObservable();
    }

  
}

import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { Router ,NavigationEnd , ActivatedRoute, NavigationStart} from '@angular/router';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { app_web_view_list } from './const-variables';
import { ReferralCodeService } from 'src/app/services/ReferralCode.service';
import * as globalConstant from './global_constant';
declare var $: any;
declare var pintrk: any;
declare var document: any;
declare const ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class AppComponent implements OnInit {
  title = 'vebolife';
  isBrowser: boolean;
  isServer: boolean;
  segment1: string = '';
  app_web_view_list = app_web_view_list;
  currenturl = '';
  fbclid : string = '';
  utm_source : any = '';
  utm_medium : any = '';
  utm_campaign : any = '';
  utm_parameters : any = {};
  utm_term : any = '';
  utm_content : any = '';
  isLive : any = globalConstant.IS_LIVE;
  constructor(
    private router: Router,
	private activatedRoute: ActivatedRoute,
	private referralCodeService: ReferralCodeService,
	private metaService: Meta,
	@Inject(PLATFORM_ID) platformId: Object
  ) { 
    
	this.isBrowser 	= isPlatformBrowser(platformId);
	this.isServer 	= isPlatformServer(platformId);
	
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd) {
		var urlObj 		=	this.router.url.split('/');
		if(urlObj[1]){
			this.segment1	=	this.router.url.split('/')[1];
		}
		//console.log(this.segment1);
        if(urlObj[1] !== "experience-provider-add")
        {
          localStorage.removeItem('exp-provider-add')
        }
		if(this.router.url.split('/')[1]){
			if(this.router.url.split('/')[1].split('?')[0]){
				this.currenturl =  this.router.url.split('/')[1].split('?')[0];
			}else{
				this.currenturl = this.router.url.split('/')[1];
			}
		}

		ga('config', 'UA-46205410-1', { 'page_path': event.urlAfterRedirects });
		ga('config', 'AW-10805683766', { 'page_path': event.urlAfterRedirects }); 
      }
    }); 
	this.activatedRoute.queryParams.subscribe(params => {
       	//console.log("app component");
		console.log('document.referrer', document.referrer);
	   	this.utm_parameters	=	params;
	   	if(params['utm_source'] || params['fbclid']){
			
			if(params['utm_source']){
				this.utm_source = params['utm_source'];
			}else if(params['fbclid']){
				this.fbclid 	= params['fbclid'];
			}else if(document.referrer == 'https://l.facebook.com/'){
				this.fbclid 	= 'https://l.facebook.com/';
			}
			
			if(params['utm_medium']){
				this.utm_medium = params['utm_medium'];
			}else{
				this.utm_medium = '';
			}if(params['utm_campaign']){
				this.utm_campaign = params['utm_campaign'];
			}else{
				this.utm_campaign = '';
			}if(params['utm_term']){
				this.utm_term = params['utm_term']
			}else{
				this.utm_term = '';
			}if(params['utm_content']){
				this.utm_content = params['utm_content'];
			}else{
				this.utm_content = '';
			}
			
			let requestBody = {'utm_source':this.utm_source, 'fbclid':this.fbclid, 'utm_medium':this.utm_medium, 'utm_campaign':this.utm_campaign, 'utm_term':this.utm_term, 'utm_content':this.utm_content};
			localStorage.setItem('referal_code_parameters', JSON.stringify(requestBody));
			this.referralCodeService.saveReferralSource(requestBody).subscribe();
			
			this.addMetaTags();
	   	}
		
    });
	this.router.events.subscribe((event) => {
	  if (event instanceof NavigationStart) {
		localStorage.setItem('previousUrl', this.router.url);
	  }
	});
  }
  
	ngOnInit() {
		if(this.isLive){
			this.load_pinterest_script();
		}
		this.getContentHeight();
	}
  
	addMetaTags(){
		let iosAffliatedData 	=	JSON.stringify(this.utm_parameters);
		//alert(iosAffliatedData);
		let appArguments 		=	iosAffliatedData;
		
		let iosMetaContent 		=	 'app-id=1123434344,affiliate-data='+iosAffliatedData+',app-argument=https://www.spurexperiences.com?utm_source=google';
		this.metaService.updateTag({ name: 'apple-itunes-app', content: iosMetaContent });
	}
	
	/* addSmartBannerForAndroid(){
		//alert(123);
		 new SmartBanner({
			daysHidden: 15, // days to hide banner after close button is clicked (defaults to 15)
			daysReminder: 10, // days to hide banner after "VIEW" button is clicked (defaults to 90)
			appStoreLanguage: 'us', // language code for the App Store (defaults to user's browser language)
			title: "Spur Experiences",
			author: "vebolife",
			icon: 'https://spurexperiences.com/assets/images/spur_app_logo.jpeg', // The URL of the icon (defaults to <meta name="apple-touch-icon">)
			url: null, // The URL for the button. Keep null if you want the button to link to the app store.
			button: "VIEW",
			//hideOnInstall: false,
			store: {
				//ios: "On the Apple Store",
				android: "On the Google Play"
			},
			price: {
				//ios: "GET",
				android: "GET"
			},
			onInstall: function() {
				alert('Click install');
			},
			force: 'android' // Uncomment for platform emulation
		});
	} */

  onResize(event) {
    this.getContentHeight();
  }

  load_pinterest_script(){
	pintrk('track', 'pagevisit');
 }

  getContentHeight(){
	  if(this.isBrowser && document.documentElement && document.getElementById("header") &&  document.getElementById("footer") && document.getElementById("body-content-wrapper")){
		 var wndowHeight = document.documentElement.clientHeight,
       	 	mainHeader = document.getElementById("header").clientHeight,
        	mainFooter = document.getElementById("footer").clientHeight,
        	veboWrapper = document.getElementById("body-content-wrapper")
      
		veboWrapper.style.minHeight   = wndowHeight - (mainHeader+mainFooter)+"px"; 
	  }
    
  }

}

import { Component, OnInit, HostListener , OnDestroy, Inject , PLATFORM_ID } from '@angular/core';
import { NavigationStart, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MessageService } from '../../../message.service';
import { isPlatformBrowser } from '@angular/common';
declare var $: any;
declare var window: any;

@Component({
  selector: 'app-registerfinder-subheader',
  templateUrl: './registerfinder-subheader.component.html',
  styleUrls: ['./registerfinder-subheader.component.css']
})
export class RegisterfinderSubheaderComponent implements OnInit , OnDestroy {

  authUser : any;
  currentURL : any ='';
  currentURL1 : any ='';
  
  authDataMessageSubscription: Subscription;
  isBrowser : boolean;

  constructor(
    private router: Router,
	private messageService: MessageService,
    		@Inject(PLATFORM_ID) platformId: Object,
	) 
	{
		this.isBrowser = isPlatformBrowser(platformId);
		if(this.isBrowser){
			window.scrollTo(0,0);
		}
		
		this.currentURL = this.router.url.split("/")[1]; 
		this.currentURL1 = this.currentURL.split("?")[0]; 
        this.router.events.subscribe(event => {

        if(localStorage.getItem('authUser')){
          this.authUser 		= 	JSON.parse(localStorage.getItem('authUser'));  
        }
		
		this.authDataMessageSubscription = this.messageService.getAuthUserDataMessage().subscribe(messageData => {
			//console.log(messageData);
            if(messageData) {
                this.authUser 		= 	JSON.parse(messageData);  
            }
        });


        if (event instanceof NavigationStart) {
			if(this.isBrowser)
			this. onWindowScroll();
        }

        if(event instanceof NavigationEnd) {
          this.currentURL = this.router.url.split("/")[1]; 
		  this.currentURL1 = this.currentURL.split("?")[0]; 
        
        }

      });
    }
	
	ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
		if(this.authDataMessageSubscription!=undefined && this.authDataMessageSubscription!=null)
		{
			this.authDataMessageSubscription.unsubscribe();
		}
    }

  ngOnInit() {
    if(localStorage.getItem('authUser')){
      this.authUser 		= 	JSON.parse(localStorage.getItem('authUser'));  
    }
  }

  @HostListener("window:scroll", []) 
  onWindowScroll() {
    // do some stuff here when the window is scrolled
	if(this.isBrowser){
		const verticalOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

		var navbar = document.getElementById("epdash-subheader");
		if(navbar !== null && navbar !==  undefined)
		{
			if (verticalOffset < 145) {
			navbar.classList.remove('is-sticky');
			}
			if (verticalOffset > 145) {
			  if (!navbar.classList.contains('is-sticky')) {
				navbar.classList.add('is-sticky');
			  }
			}
			if (verticalOffset > 145 * 2) {
			  if (!navbar.classList.contains('awake')) {
				navbar.classList.add('awake');
			  }
			}
			if (verticalOffset < 145 * 2) {
			  if (navbar.classList.contains('awake')) {
				navbar.classList.remove('awake');
			  }
			}
		}
	}
   
  }

  ngAfterViewInit() {
	  if(this.isBrowser){
			var subMenuBtn = document.getElementById("epdash-menu-toggle");
			var weddingHead = document.getElementById("epdash-subheader");
			var weddingHeadMenu = document.getElementById("epdash-menu-toggleNav");
			if(subMenuBtn!=null || subMenuBtn!=undefined)
			{
			subMenuBtn.addEventListener("click", (e) => {
			  //weddingHead.classList.toggle("collapsed");
			  subMenuBtn.classList.toggle("clicked");

			  if(subMenuBtn.classList.contains("clicked")) {
				weddingHeadMenu.hidden = false;
			  }
			  else {
				weddingHeadMenu.hidden = true;
			  }

			});
		}
	}
  }

}

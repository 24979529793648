import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive( {
    selector : '[prevent-keys]',
    host : {
        '(keydown)' : 'onKeyUp($event)'
    }
} )
export class PreventKeysDirective {
    @Input( 'prevent-keys' ) preventKeys;
    onKeyUp ( $event ) {
        if ( this.preventKeys && this.preventKeys.includes( $event. keyCode ) ) {
            $event.preventDefault();
        }
    }
}

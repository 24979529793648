import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'dateMonthFormat'})
export class DateMonthFormat implements PipeTransform {
  transform(date: string): string {
    if (date == "") {
      return '';
    }
    let myArray = date.split("-");
    let month = Number(myArray[0]);
    let day = myArray[1];
    if(Number(day) < 10){
      let oneDigitDay = day.split("");
      day = oneDigitDay[1];
    }
    let dayNumber = Number(day);
    let months= ["","January","February","March","April","May","June","July",
           "August","September","October","November","December"];
    return `${months[month]} ${dayNumber}${this.nth(dayNumber)}`;
  }

  nth(d) {
    if (d > 3 && d < 21) return 'th'; 
    switch (d % 10) {
      case 1:  return "st";
      case 2:  return "nd";
      case 3:  return "rd";
      default: return "th";
    }
  }

}
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { catchError,map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import * as globalConstant from './../global_constant';

@Injectable({
  providedIn: 'root'
})
export class ReferralCodeService {
  apiDomain	   	    		=	globalConstant.DOMAIN;
  siteRequestFrom			=	globalConstant.SITE_REQUEST_FROM;
  saveReferralSourceApi		=	globalConstant.SAVE_REFERRAL_SOURCE_API;

  
  constructor(public router: Router,private http: HttpClient) {  

  } 

  saveReferralSource(formData){
      const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            'Content-Type': 'application/json',
            //'Authorization':'Bearer '+headers,
			'requestFrom':this.siteRequestFrom
          })
        };
        return this.http.post<any>(this.apiDomain+this.saveReferralSourceApi,formData,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
         if(err.status == 401)
          {
            localStorage.removeItem('authUser')
            localStorage.removeItem('login_token')
            localStorage.removeItem('profile_img_url')
            window.location.href = '/login'
          } 
          return err.error;
        }));
      }
	 
}





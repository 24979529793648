import { Pipe, PipeTransform } from '@angular/core';
 
@Pipe({
 name: 'priceFormat'
})
export class PriceFormat implements PipeTransform {
 transform(value: any): any {
	if(Number.isInteger(value)){
		return value;
	}else{
		value = Number(value).toFixed(2);
		return value;
	}
 }
}
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { catchError,map } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as globalConstant from './../global_constant';

@Injectable({
  providedIn: 'root'
})

export class ExperienceProviderPromotionalService {
  apiDomain					=	globalConstant.DOMAIN;
	siteRequestFrom			=	globalConstant.SITE_REQUEST_FROM;
	getPromotionApi		=	globalConstant.GET_PROMOTION_API;
	updatePromotionApi	=	globalConstant.UPDATE_PROMOTION_API;
	getCalendarApi			      =	  globalConstant.GET_CALENDAR_DATES_API;

  constructor(public router: Router,private http: HttpClient) {
    
  }

  getPromotionalData(formData){
   /*  const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'requestFrom':this.siteRequestFrom,
        'Authorization':'Bearer '+headers,
      })
    }; */
    return this.http.post<any>(this.apiDomain+this.getPromotionApi,formData).pipe(map(res=>{
      return res;
    }));
  }

  updatePromotionalData(formData,headers){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'requestFrom':this.siteRequestFrom,
        'Authorization':'Bearer '+headers,
      })
    };
    return this.http.post<any>(this.apiDomain+this.updatePromotionApi,formData,httpOptions).pipe(map(res=>{
      return res;
    }));
  }
  getCalendarData(formData, headers){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'requestFrom':this.siteRequestFrom,
        'Authorization':'Bearer '+headers,
      })
    };
    return this.http.post<any>(this.apiDomain+this.getCalendarApi,formData,httpOptions).pipe(map(res=>{
      return res;
    }));
  }
}
 